<div class="o_dash">
  <app-ggl-event-card-manager></app-ggl-event-card-manager>
  <ng-container *ngIf="!isLoadingActiveEvent; else loading">
    <app-ggl-event-detail-manager
      [eventDetails]="activeEvent"
    ></app-ggl-event-detail-manager>
  </ng-container>
  <ng-template #loading>
    <div
      class="p-1 bg-info w-100 h-100 d-flex align-items-center justify-content-center"
    >
      <app-dual-ring-spinner></app-dual-ring-spinner>
    </div>
  </ng-template>
</div>
