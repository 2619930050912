import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from "@angular/core";

import {
  EliminationEventCardInfo,
  EventCardTeam,
  NullEventCardTeam
} from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-elimination-event-card-content",
  templateUrl: "./elimination-event-card-content.component.html",
  styleUrls: ["./elimination-event-card-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EliminationEventCardContentComponent implements IGGLEventCardContentComponent, OnInit {
  @Input() public eventCardData!: EliminationEventCardInfo;

  public teamOne: EventCardTeam = NullEventCardTeam;
  public teamTwo: EventCardTeam = NullEventCardTeam;
  public roundText = "";

  public ngOnInit(): void {
    [this.teamOne, this.teamTwo] = this.eventCardData.teams;

    this.roundText = `--Round ${this.eventCardData.tournamentRound}`;
  }
}
