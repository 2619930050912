<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Games to Track</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills">
      <li [ngbNavItem]="eventImportTypes.BATTLE_ROYALE">
        <a ngbNavLink>Battle Royale</a>
        <ng-template ngbNavContent>
          <app-battle-royale-import-form> </app-battle-royale-import-form>
        </ng-template>
      </li>
      <li [ngbNavItem]="eventImportTypes.ELIMINATION_BRACKET">
        <a ngbNavLink>Elimination</a>
        <ng-template ngbNavContent>
          <app-tournament-round-import-form [isRoundRobin]="false">
          </app-tournament-round-import-form>
        </ng-template>
      </li>
      <li [ngbNavItem]="eventImportTypes.ROUND_ROBIN">
        <a ngbNavLink>Round Robin</a>
        <ng-template ngbNavContent>
          <app-tournament-round-import-form [isRoundRobin]="true">
          </app-tournament-round-import-form>
        </ng-template>
      </li>
      <li [ngbNavItem]="eventImportTypes.QUICKPLAY_LEAGUE">
        <a ngbNavLink>QuickPlay</a>
        <ng-template ngbNavContent>
          <app-quickplay-league-import-form> </app-quickplay-league-import-form>
        </ng-template>
      </li>
      <li [ngbNavItem]="eventImportTypes.QUEUE">
        <a ngbNavLink>Queue</a>
        <ng-template ngbNavContent>
          <app-queue-import-form></app-queue-import-form>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</ng-template>

<button class="btn btn-info" (click)="open(content)">Add Games</button>
