<div *ngIf="chatLoadError" class="text-danger font-weight-bold">
  ERROR LOADING CHAT. CHAT MAY NOT BE AVAILABLE FOR THIS GAME YET
</div>
<div class="c_splitAdminChatBlock" *ngIf="!isChatLoading; else chatLoad">
  <div class="c_chatBlock">
    <h4>Match Chat</h4>
    <app-chat-stream
      [messages]="userMessages"
      [chatroomData]="chatroomData"
    ></app-chat-stream>
  </div>
  <div class="c_chatBlock">
    <h4>Admin Notifications</h4>
    <app-chat-stream
      [messages]="adminMessages"
      [chatroomData]="chatroomData"
    ></app-chat-stream>
    <app-new-message-box
      [chatroomData]="chatroomData"
      [isAdminChatBox]="true"
    ></app-new-message-box>
  </div>
</div>
<ng-template #chatLoad>
  <div class="lds-dual-ring"></div>
</ng-template>
