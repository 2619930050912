import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { basicAuthHeader } from "src/app/utils/auth-header.util";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class MassChatService {

  constructor(private _http: HttpClient) { }

  public sendMassChatMessage(messageBody: string, gameIdList: string[]) {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/notify_group`;
    const headers = {
      headers: basicAuthHeader()
    };
    const payload = {
      series_matchup: {
        body: messageBody,
        ids: gameIdList,
      }
    };
    return this._http.post(
      url,
      payload,
      headers
    );
  }
}
