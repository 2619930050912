export enum FirestoreCollections {
  ANNOUNCEMENTS = "announcements",
  NOTIFICATIONS = "notifications",
  SERIES_MATCHUP_STREAMDATA = "series-matchup-streams",
  USER_CHATS = "user-chats",
  TOURNAMENT_QUEUE = "tournament-queue-session-streams",
  TOURNAMENT_QUEUE_CHECKINS = "tournament-queue-session-checkins",
  QUICK_PLAY_EVENT_STREAMS = "quick-play-event-streams",
  QUICK_PLAY_EVENT_CHECKINS = "quick-play-event-checkins",
}

export enum FirestoreChatroomCollections {
  CHATROOMS = "chatrooms",
  TOURNAMENT_CHATROOMS = "tournament-chatrooms",
  SCRIMMAGE_CHATROOMS = "scrimmage-chatrooms",
  QUICK_PLAY_CHATROOMS = "quick-play-event-chatrooms",
}
