import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LeagueTeam } from "@apptypes/leagues.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";

import { APILeagueTeam } from "./leagues.api.types";

@Injectable({
  providedIn: "root"
})
export class LeaguesService {
  constructor(private _http: HttpClient) { }

  public getLeagueTeams(leagueID: string): Observable<LeagueTeam[]> {
    const url = `${environment.apiBase}/api/v2/leagues/${leagueID}/teams`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: APILeagueTeam[] }>(url, {
      headers,
    }).pipe(
      map((res) => res.data.map(this._mapAPILeagueTeam))
    );
  }

  private _mapAPILeagueTeam = (
    { id, type, attributes: { description, teamType, title, userIds, logoUrl } }: APILeagueTeam
  ): LeagueTeam => ({
    id,
    type,
    description,
    teamType,
    title,
    logoUrl,
    userIds: userIds.map(userId => `${userId}`),
  });
}
