import { Pipe, PipeTransform } from "@angular/core";

import { ChatroomBlockData } from "@apptypes/chat.types";
import { MatchDetail } from "@apptypes/games.types";

@Pipe({
  name: "matchDetailChatroomAdapter"
})
export class MatchDetailChatroomAdapterPipe implements PipeTransform {
  public static NoChatroomsMessage = "No chatroom for match detail";
  public transform({ id, chatroomReferenceId, tournament: { schedulingType }, teams }: MatchDetail): ChatroomBlockData | null {
    try {

      if(!chatroomReferenceId){
        throw new Error(MatchDetailChatroomAdapterPipe.NoChatroomsMessage);
      }

      return {
        id,
        chatroomReferenceId,
        schedulingType,
        teams: teams.map(({ id: teamId, users, title }) => ({
          id: teamId,
          title,
          userIds: users.map(({ id: userId }) => userId),
        }))
      };
    }
    catch(e) {
      console.error(e);
      return null;
    }
  }
}
