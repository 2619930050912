import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";

import { MatchDetailLeague } from "../types/games.types";

/**
 * Reduces a current list of match data into a single list of unique leagues contained across
 * the dataset
 *
 * @param  matchArray array of matches
 * @returns LeagueInfo
 */
export const getUniqueLeaguesList = (matchArray: GGLEventCardInfo[]): MatchDetailLeague[] => {
  // Convert to string so new Set can remove dupes
  const leaguesDataArray = matchArray.map<string>((match) =>
    JSON.stringify({
      id: match.leagueID,
      title: match.leagueName,
    })
  );

  // Use set invocation to easily remove dupes
  const uniqueLeaguesStringList = [...new Set(leaguesDataArray)];

  // Expand strings back into objectsd
  return uniqueLeaguesStringList.map((leagueString) => JSON.parse(leagueString));
};
