<nav class="navbar navbar-expand-lg navbar-light bg-primary">
  <a
    class="navbar-brand"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    >GGLeagues Manager Tools{{ isProduction ? "" : "--TESTING-BUILD" }}</a
  >
  <div class="ml-auto" *ngIf="isLoggedIn()">
    <app-mass-message-modal></app-mass-message-modal>
    <button class="ml-4 btn btn-light" (click)="logout()">Log Out</button>
  </div>
</nav>
