import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { APISuccessMessage } from "@apptypes/api.types";
import { ChatModerateActions } from "@apptypes/chat.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";

import { ModerationService } from "../moderation.abstract.service";
import { QuickPlayEventModerateMessageAction } from "../moderation.api.types";

/**
 * A class to handle moderation actions for quickplay event chatrooms
 *
 * @author Christian Tweed
 */
@Injectable()
export class QuickPlayEventModerationService extends ModerationService {
  public banUser(quickPlayEventID: string, userID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/quick_play_events/${quickPlayEventID}/chatroom_bans`;

    const body = this._formatChatroomBanAction(userID);
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public hideChatMessage(resourceID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${
      environment.apiBase
    }/api/v2/league_admin/quick_play_events/${resourceID}/quick_play_event_chatrooms/moderate_chat_message`;
    const options = {
      headers: basicAuthHeader(),
    };
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.HIDE);
    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public unhideChatMessage(resourceID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${
      environment.apiBase
    }/api/v2/league_admin/quick_play_events/${resourceID}/quick_play_event_chatrooms/moderate_chat_message`;
    const options = {
      headers: basicAuthHeader(),
    };
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.UNHIDE);

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  protected _formatModerateMessageAction(chat_message_id: string, action: ChatModerateActions): QuickPlayEventModerateMessageAction {
    return {
      quick_play_event: {
        chat_message_id,
        action,
      }
    };
  }
}
