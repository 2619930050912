import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

interface LoginFormValues {
  email: string;
  password: string;
}
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginPageComponent implements OnInit {
  public hasError = false;
  public loginForm: FormGroup;
  public formIsLoading = false;

  constructor(private _route: ActivatedRoute, private _router: Router, private _authService: AuthService) {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  public ngOnInit(): void {
    this.hasError = this._route.snapshot.url.find((urlSegment) => urlSegment.path === "error") ? true : false;
  }

  public loginUser(loginForm: FormGroup): void {
    this.formIsLoading = true;
    this.loginForm.disable();
    this.hasError = false;

    const formValue = loginForm.value as LoginFormValues;
    this._authService.logon(formValue).subscribe(
      // SUCCESS
      (res) => {
        if (res) {
          this._router.navigate(["/"]);
        } else {
          this.formIsLoading = false;
          this.hasError = true;
          this.loginForm.enable();
        }
      },
      // ERR
      (err) => {
        console.error(err);
        this.formIsLoading = false;
        this.hasError = true;
        this.loginForm.enable();
      }
    );
  }
}
