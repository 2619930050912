import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";

import { ChatroomBlockData } from "@apptypes/chat.types";
import { CacheService } from "@services/cache/cache.service";
import { CommentService } from "@services/chat/comment";

import { COMMENT_SERVICE } from "src/app/providers/comment-service/comment-service.token";

enum CommentErrorTypes {
  NULL_USER_ERROR = "NULL_USER_ERROR",
  INVALID_FORM_ERROR = "INVALID_FORM_ERROR",
  INVALID_MESSAGE_ERROR = "INVALID_MESSAGE_ERROR",
  NO_COMMENT_SERVICE = "NULL_COMMENT_SERVICE_ERROR",
}

@Component({
  selector: "app-new-message-box",
  templateUrl: "./new-message-box.component.html",
  styleUrls: ["./new-message-box.component.scss"]
})
export class NewMessageBoxComponent implements OnInit {
  @Input() public chatroomData!: ChatroomBlockData;
  @Input() public isAdminChatBox = false;

  public hasError = false;
  public hasSuccess = false;
  public isPostingMessage = false;
  public messageForm: FormGroup = new FormGroup({
    message: new FormControl("", [Validators.required]),
  });

  constructor(
    private _cacheService: CacheService,
    @Inject(COMMENT_SERVICE) @SkipSelf() @Optional() private _commentService: CommentService | null,
  ) {}

  public ngOnInit(): void {

    if(!this._commentService){
      console.error("You must provide a CommentService in the parent component");
      alert("Could Not Load the Chatroom, Please Referesh the Page");
    }
  }

  public postMessage(form: FormGroup): void {
    this.isPostingMessage = true;
    this.hasError = false;
    this.hasSuccess = false;

    if(form.invalid){
      const errorMessage = {
        type: CommentErrorTypes.INVALID_FORM_ERROR,
        form,
        stat: form.valid,
      };
      this._failToSendMessage(errorMessage, true);

      return;
    }

    const user = this._cacheService.getCachedUser();
    const chatMessage: string | null = form.get("message")?.value ?? null;

    if(!user){
      const errorMessage = {
        type: CommentErrorTypes.NULL_USER_ERROR,
        chatMessage,
        user,
      };
      this._failToSendMessage(errorMessage, true);
      return;
    }

    if(!chatMessage){
      const errorMessage = {
        type: CommentErrorTypes.INVALID_MESSAGE_ERROR,
        chatMessage,
        user,
      };
      this._failToSendMessage(errorMessage, true);
      return;
    }

    if(this._commentService === null){
      const errorMessage = {
        type: CommentErrorTypes.NO_COMMENT_SERVICE,
        chatMessage,
        user,
      };
      this._failToSendMessage(errorMessage, true);
      return;
    }

    form.disable();

    this._commentService.postComment(this.chatroomData, user.id.toString(), chatMessage.trim()).subscribe(
      () => {
        this.isPostingMessage = false;
        this.hasSuccess = true;
        form.reset();
        form.enable();
      },
      (err) => {
        this._failToSendMessage(err, false);
        form.enable();
      }
    );
  }

  private _failToSendMessage(errorMessage: any, useWarn: boolean): void {
    this.hasError = true;
    this.isPostingMessage = false;
    return useWarn ? console.warn(errorMessage) : console.error(errorMessage);
  }
}
