import { Injectable } from "@angular/core";
import {
  EMPTY,
  Observable,
  zip
} from "rxjs";
import {
  defaultIfEmpty,
  first,
  map,
  switchMap,
  tap
} from "rxjs/operators";

import { QueueMatchEventCardInfo } from "@apptypes/ggl-event-card.types";
import {
  QueueSession,
  QueueDetail,
  QueueSessionMatchupMap
} from "@apptypes/queue.types";
import { GGLEventConfig } from "@services/cache/cache.service";
import { EventImportService } from "@services/event-import/event-import.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";

import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";
import { QueueMatchRoundInfo } from "./queue-import-match.types";

/**
 * A service to load matches from a queue.
 */
@Injectable({
  providedIn: "root"
})
export class QueueImportMatchService {
  constructor(
    private _matchAssignmentService: EventImportService,
    private _eventCardStoreService: EventCardStoreService,
    private _eventConfigStoreService: EventConfigStoreService
  ) { }

  /**
   * Take a QueueSession from firestore, and a Queue and gets the missing matches
   * from the finalized matchup mapping. It will not send a network request to find the
   * new matches if all are already loaded. It also sets the color of the new cards to the
   * same color as the queue.
   *
   * @param queueSession
   * @param queueDetail
   * @returns the new queue match cards
   */
  public loadQueueMatches(queueSession: QueueSession, queueDetail: QueueDetail): Observable<QueueMatchEventCardInfo[]> {
    const seriesMatchupIDRoundMap = this._getMatchupMappingRoundMap(queueSession.finalizedMatchupMapping);
    const eventCardsInQueueTournament$: Observable<QueueMatchEventCardInfo[]> = this._eventCardStoreService.eventCards$.pipe(
      map((eventCards) => eventCards
        .filter(
          (eventCard) => eventCard.type === GGLEventCardTypes.QUEUE_MATCH
        )
        .map((queueMatchCard) => queueMatchCard as QueueMatchEventCardInfo)
        .filter(
          (queueMatchCardInTournament) => queueMatchCardInTournament.tournamentID === queueDetail.tournament.id
        )
      ),
      first(),
    );

    const queueMatchConfig$: Observable<GGLEventConfig | undefined> = this._eventConfigStoreService.getConfigFromEventDetails(
      queueDetail
    ).pipe(
      first()
    );

    const newTournamentCards$: Observable<QueueMatchEventCardInfo[]> = eventCardsInQueueTournament$.pipe(
      map((eventCards) => this._findMissingRounds(eventCards, queueSession.finalizedMatchupMapping)),
      switchMap((rounds) => {
        if(rounds.length === 0){
          return EMPTY;
        }

        return this._matchAssignmentService.getQueueMatches(queueDetail.tournament.id, rounds, seriesMatchupIDRoundMap);
      }),
      defaultIfEmpty([] as QueueMatchEventCardInfo[])
    );

    return zip(newTournamentCards$, queueMatchConfig$).pipe(
      tap(([tournamentCards, matchConfig]) => {
        if(!matchConfig){
          return;
        }

        this._eventConfigStoreService.setConfigForMultipleEventCards(tournamentCards, matchConfig);
      }),
      map(([tournamentCards]) => tournamentCards)
    );
  }

  /**
   * Compares the eventCards to the matchupMapping to find the set of rounds that
   * need to be fetched
   *
   * @param eventCards
   * @param matchupMapping
   * @returns the array of missing rounds
   */
  private _findMissingRounds(eventCards: QueueMatchEventCardInfo[], matchupMapping: QueueSessionMatchupMap[]): string[] {
    const missingRounds: string[] = [];
    const roundIDMap = this._getRoundMapToIDs(eventCards);
    matchupMapping.forEach((matchupMap) => {
      const round = roundIDMap.get(matchupMap.round);
      if(round === undefined || !round.includes(matchupMap.seriesMatchupID)){
        missingRounds.push(matchupMap.round);
        return;
      }
    });
    return Array.from(new Set([...missingRounds]));
  }

  /**
   * Create a Map<seriesMatchupID, round> from the queueSession array.
   *
   * @param matchupMapping
   * @returns the map of seriesMatchupID to round.
   */
  private _getMatchupMappingRoundMap(matchupMapping: QueueSessionMatchupMap[]): Map<string, QueueMatchRoundInfo> {
    return new Map<string, QueueMatchRoundInfo>(
      matchupMapping.map((matchupMap) => [
        matchupMap.seriesMatchupID,
        {
          round: matchupMap.round,
          queueRound: matchupMap.matchRoundCount
        }
      ])
    );
  }

  /**
   * Create a Map<round, seriesMatchupID[]> from an array of QueueMatchCards.
   *
   * @param eventCards
   * @returns the map of round to seriesMatchupIDs
   */
  private _getRoundMapToIDs(eventCards: QueueMatchEventCardInfo[]): Map<string, string[]> {
    const roundIDMap: Map<string, string[]> = new Map<string, string[]>();
    eventCards.forEach((eventCard) => {
      const round = roundIDMap.get(eventCard.tournamentRound);
      if(round === undefined){
        roundIDMap.set(eventCard.tournamentRound, [eventCard.id]);
        return;
      }

      const newIDs = Array.from(new Set([...round, eventCard.id]));
      roundIDMap.set(eventCard.tournamentRound, newIDs);
    });
    return roundIDMap;
  }
}
