import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { APISuccessMessage } from "@apptypes/api.types";
import { ChatModerateActions } from "@apptypes/chat.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";

import { ModerationService } from "../moderation.abstract.service";
import { TournamentModerateMessageAction } from "../moderation.api.types";

/**
 * A class to handle moderation actions for tournament chatrooms
 *
 * @author Christian Tweed
 */
@Injectable()
export class TournamentModerationService extends ModerationService {
  public banUser(tournamentID: string, userID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournaments/${tournamentID}/chatroom_bans`;
    const body = this._formatChatroomBanAction(userID);

    const options = {
      headers: basicAuthHeader()
    };

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public hideChatMessage(tournamentID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournaments/${tournamentID}/tournament_chatrooms/moderate_chat_message`;
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.HIDE);
    const options = {
      headers: basicAuthHeader(),
    };
    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public unhideChatMessage(tournamentID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournaments/${tournamentID}/tournament_chatrooms/moderate_chat_message`;
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.UNHIDE);
    const options = {
      headers: basicAuthHeader(),
    };
    return this._http.post<APISuccessMessage>(url, body, options);
  }

  protected _formatModerateMessageAction(chat_message_id: string, action: ChatModerateActions): TournamentModerateMessageAction {
    return {
      tournament: {
        chat_message_id,
        action,
      }
    };
  }
}
