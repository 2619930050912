<form
  class="c_sendMessage"
  [formGroup]="messageForm"
  (submit)="postMessage(messageForm)"
>
  <textarea
    formControlName="message"
    (keyup.enter)="postMessage(messageForm)"
  ></textarea>
  <div
    class="text-danger bg-light font-weight-bold m-0 rounded"
    *ngIf="hasError"
  >
    There was an an error sending your message, please try again
  </div>
  <div
    *ngIf="hasSuccess"
    class="text-light bg-primary font-weight-bold m-0 rounded"
  >
    Message posted successfully
  </div>
  <button type="submit" class="btn btn-success">
    <ng-container *ngIf="!isAdminChatBox; else adminNotif">
      <span *ngIf="!isPostingMessage; else spinner"> Submit </span>
    </ng-container>
    <ng-template #adminNotif>
      <span *ngIf="!isPostingMessage; else spinner">
        SEND ADMIN NOTIFICATION
      </span>
    </ng-template>
    <ng-template #spinner>
      <app-lds-spinner></app-lds-spinner>
    </ng-template>
  </button>
</form>
