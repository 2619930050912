import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";
import { ChatroomBlockData } from "@apptypes/chat.types";

import { RailsSeriesMatchupComment } from "../comment.api.types";
import { CommentService } from "../comment.abstract.service";

@Injectable({
  providedIn: "root"
})
export class RailsSeriesMatchupCommentService extends CommentService {

  public postComment({ id: seriesMatchupId }: ChatroomBlockData, authorId: string, message: string): Observable<any> {
    const url = `${environment.apiBase}/api/v2/series_matchups/${seriesMatchupId}/match_chatrooms/message`;
    const payload = this._formatChatMessage(message);
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post(url, payload, options);
  }

  protected _formatChatMessage(body: string): RailsSeriesMatchupComment {
    return {
      message: {
        body,
      },
    };
  }

}
