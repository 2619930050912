<div ngbDropdown placement="bottom-right" [(open)]="isOpen">
  <button
    ngbDropdownToggle
    class="btn btn-primary c_chatMessageActionsDropdown position-absolute"
    [ngClass]="{ c_chatMessageActionsDropdown__rotate: isOpen }"
  ></button>
  <div ngbDropdownMenu>
    <app-hide-chat-message-dropdown-item
      *ngIf="!chatMessage.isHidden"
      [chatMessage]="chatMessage"
      [resourceID]="resourceID"
    >
    </app-hide-chat-message-dropdown-item>
    <app-unhide-chat-message-dropdown-item
      *ngIf="chatMessage.isHidden"
      [chatMessage]="chatMessage"
      [resourceID]="resourceID"
    >
    </app-unhide-chat-message-dropdown-item>
    <app-ban-user-dropdown-item
      [chatMessage]="chatMessage"
      [resourceID]="resourceID"
    >
    </app-ban-user-dropdown-item>
  </div>
</div>
