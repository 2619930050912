export enum EsportName {
  OTHER = "other",
  ROCKET_LEAGUE = "rocket_league",
  OVERWATCH = "overwatch",
  VALORANT = "valorant",
  FORTNITE = "fortnite",
  EA_MADDEN = "ea_madden",
  EA_FIFA = "ea_fifa",
  EA_NHL = "ea_nhl",
  LEAGUE_OF_LEGENDS = "league_of_legends",
  SSBB = "super_smash_bros",
  NBA_2K = "nba_2k",
  CALL_OF_DUTY = "call_of_duty",
  DOTA_2 = "dota",
}

export enum DataType {
  League = "league",
  Teams = "teams",
}
