<p class="text-danger">Are you sure you want to remove this quickplay event?</p>
<dl>
  <dt>League:</dt>
  <dd>
    {{ eventCardData.leagueName }}
  </dd>
  <dt>Esport:</dt>
  <dd>
    {{ eventCardData.esport }}
  </dd>
</dl>
