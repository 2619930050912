import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";
import {
  first,
  map,
  switchMap
} from "rxjs/operators";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";

import { MassChatService } from "src/app/services/mass-chat/mass-chat.service";
import { MatchDetailLeague } from "src/app/types/games.types";
import { getUniqueLeaguesList } from "src/app/utils/match-array.utils";
import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";

interface MessageForm {
  selectedLeague: MatchDetailLeague;
  message: string;
  confirm: boolean;
}
@Component({
  selector: "app-mass-message-modal",
  templateUrl: "./mass-message-modal.component.html",
  styleUrls: ["./mass-message-modal.component.scss"],
})
export class MassMessageModalComponent implements OnInit {
  public isLoading = false;
  public hasSelectedLeague = false;
  public isConfirm = false;
  public hasReviewError = false;
  public confirmError = false;
  public formError = false;
  public messageForm = new FormGroup({
    selectedLeague: new FormControl(null, Validators.required),
    message: new FormControl("", [Validators.required, Validators.minLength(20)]),
    confirm: new FormControl(false, [Validators.requiredTrue]),
  });

  public eventCards$: Observable<GGLEventCardInfo[]> = of([]);
  public leagueList$: Observable<MatchDetailLeague[]> = of([]);

  constructor(
    private _modalService: NgbModal,
    private _massChatService: MassChatService,
    private _eventCardStoreService: EventCardStoreService
  ) {}

  public ngOnInit(): void {
    this.eventCards$ = this._eventCardStoreService.eventCards$;
    this.leagueList$ = this.eventCards$.pipe(
      map(getUniqueLeaguesList)
    );
  }

  public checkForSelection(): void {
    const selectedLeague = (this.messageForm.get("selectedLeague")?.value ?? {
    }) as MatchDetailLeague;
    const { title, id } = selectedLeague;

    if ((title ?? false) && (id ?? false)) {
      this.hasSelectedLeague = true;
    } else {
      this.hasSelectedLeague = false;
    }
  }

  public get currentLeagueName(): string {
    const selectedLeague = (this.messageForm.get("selectedLeague")?.value ?? {
    }) as MatchDetailLeague;
    return selectedLeague.title ?? "ERROR";
  }

  public get currentMessage(): string {
    const message = (this.messageForm.get("message")?.value ?? "") as string;
    return message ?? "ERROR";
  }

  public get messageLength(): number {
    const message = (this.messageForm.get("message")?.value ?? "") as string;
    return message.length;
  }

  public get impactedGamesLength$(): Observable<number> {
    const selectedLeague = (this.messageForm.get("selectedLeague")?.value ?? {
    }) as MatchDetailLeague;
    return this._getIDList$(selectedLeague.id ?? "").pipe(
      map((idList) => idList.length)
    );
  }

  public open(content: any) {
    this._modalService.open(content, {
      beforeDismiss: () => this._dismissHandler(this._resetForm),
      size: "lg"
    });
  }

  public proceedToReview(form: FormGroup): void {
    const messageIsValid = form.get("message")?.valid ?? false;

    if (messageIsValid) {
      this.isConfirm = true;
    } else {
      this.hasReviewError = true;
    }
  }

  public sendMassMessages(form: FormGroup): void {
    this.confirmError = false;
    this.formError = false;
    this.isLoading = true;
    if (form.valid) {
      const { selectedLeague, message } = form.value as MessageForm;
      this._getIDList$(selectedLeague.id).pipe(
        switchMap((idList) => this._massChatService.sendMassChatMessage(message, idList))
      ).subscribe(
        () => {
          this.isLoading = false;
          this._modalService.dismissAll();
          window.alert(`Message sent to matches in ${selectedLeague.title}`);
        },
        (err) => {
          console.error(err);
          this.isLoading = false;
          this.formError = true;
        }
      );
    } else {
      this.confirmError = true;
      this.isLoading = false;
    }
  }

  private _getIDList$(leagueID: string): Observable<string[]> {
    const MASS_MESSAGE_TYPES = [
      GGLEventCardTypes.QUEUE_MATCH,
      GGLEventCardTypes.ROUND_ROBIN,
      GGLEventCardTypes.ELIMINATION,
      GGLEventCardTypes.BATTLE_ROYALE
    ];

    return this.eventCards$.pipe(
      map((eventCards) => eventCards
        .filter(eventCard => eventCard.leagueID === leagueID)
        .filter(leagueEventCard => MASS_MESSAGE_TYPES.includes(leagueEventCard.type))
        .map(massMessageEventCard => massMessageEventCard.id)
      ),
      first()
    );
  }

  private _resetForm = (): void => {
    this.messageForm.reset();
    this.isConfirm = false;
    this.hasReviewError = false;
    this.isLoading = false;
    this.checkForSelection();
  };

  private _dismissHandler = (resetFn: () => void): boolean => {
    if (this.isLoading) {
      return false;
    } else {
      resetFn();
      return true;
    }
  };
}
