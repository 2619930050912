import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";
import { ChatroomBlockData } from "@apptypes/chat.types";

import { RailsTournamentComment } from "../comment.api.types";
import { CommentService } from "../comment.abstract.service";

/**
 * A Comment service to post to Tournament Chatrooms
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export class RailsTournamentCommentService extends CommentService {

  public postComment({ id: tournamentId }: ChatroomBlockData, authorId: string, message: string): Observable<any> {
    const url = `${environment.apiBase}/api/v2/tournaments/${tournamentId}/tournament_chatrooms/message`;

    const options = {
      headers: basicAuthHeader()
    };

    const payload = this._formatChatMessage(tournamentId, authorId, message);

    return this._http.post(url, payload, options);
  }

  protected _formatChatMessage(tournamentId: string, authorId: string, message: string): RailsTournamentComment {
    return {
      message:  {
        body: message,
        tournament_id: tournamentId,
        user_id: authorId,
      }
    };
  }
}
