import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { CacheService } from "src/app/services/cache/cache.service";
import { UserProfile } from "src/app/services/user/user.service";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  public isProduction = environment.production;

  constructor(private _authService: AuthService) {}

  public isLoggedIn(): boolean {
    try {
      const userValue = JSON.parse(localStorage.getItem(CacheService.USER_KEY) ?? "null") as UserProfile;
      if (userValue) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  public logout(): void {
    this._authService.logout().subscribe(
      // Res
      () => {
        window.location.href = "/login";
      },
      // Catch
      () => {
        window.location.href = "/login";
      }
    );
  }
}
