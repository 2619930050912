<p class="text-danger">
  Are you sure you want to remove this elimination event?
</p>
<dl>
  <dt>League:</dt>
  <dd>
    {{ eventCardData.leagueName }}
  </dd>
  <dt>Esport:</dt>
  <dd>
    {{ eventCardData.esport }}
  </dd>
  <dt>Tournament Round:</dt>
  <dd>
    {{ eventCardData.tournamentRound }}
  </dd>
  <dt>Teams:</dt>
  <dd>
    {{ eventCardData.teams[0].title }} vs {{ eventCardData.teams[1].title }}
  </dd>
</dl>
