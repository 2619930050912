/* eslint-disable array-bracket-newline */
/* eslint-disable array-element-newline */
import { Injectable } from "@angular/core";

import { COLOR_HEXES } from "./colors.constants";

/**
 * A service to manage color functions
 *
 * @see https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export class ColorService {
  /**
   * Takes a color for a background and returns either the hex code for white or black font
   * for valid WCAG contrast calculations.
   *
   * @param backgroundColor is a hex color code (# is optional)
   * @returns the hex color code for the font for the background (either white or black)
   */
  public getFontColorFromBackgroundColor(backgroundColor: string): string {
    const LUMINANCE_CUTOFF = 0.179; //Roughly the WCAG cutoff for the brightness of the background for black font.
    const [backgroundRed, backgroundGreen, backgroundBlue] = this.convertHexStringToRGB(backgroundColor);
    const backgroundRGBLuminances: [number, number, number] = [
      this._getLuminanceColor(backgroundRed),
      this._getLuminanceColor(backgroundGreen),
      this._getLuminanceColor(backgroundBlue)
    ];
    const backgroundLuminance = this._getLuminance(backgroundRGBLuminances);
    return backgroundLuminance > LUMINANCE_CUTOFF ? COLOR_HEXES.BLACK : COLOR_HEXES.WHITE;
  }

  public convertHexStringToRGB(hexString: string): [number, number, number] {
    if(hexString.includes("#")){
      hexString = hexString.slice(1);
    }

    try {
      const BASE_16 = 16;
      const R = parseInt(hexString.slice(0, 2), BASE_16);
      const G = parseInt(hexString.slice(2, 4), BASE_16);
      const B = parseInt(hexString.slice(4, 6), BASE_16);

      return [ R, G, B ];
    }
    catch(e) {
      console.error(e);
      return [0, 0, 0];
    }
  }

  /**
   * Takes an RGB array of luminance adjusted sRGB values and calculates the relative luminance of the
   * color.
   *
   * @param RGBArray is an array of three numbers where each is an adjusted sRGB value
   * @returns the lumincance
   */
  private _getLuminance([red, green, blue]: [number, number, number]): number {
    const RED_LUMINANCE_SCALE = 0.2126;
    const GREEN_LUMINANCE_SCALE = 0.7152;
    const BLUE_LUMINCANCE_SCALE = 0.0722;

    return (red * RED_LUMINANCE_SCALE) + (green * GREEN_LUMINANCE_SCALE) + (blue * BLUE_LUMINCANCE_SCALE);
  }

  /**
   * Get the scaled color value for checking the luminance of a color
   *
   * @see https://www.w3.org/TR/WCAG20/#relativeluminancedef
   * @param color is an 8 bit rgb value
   * @returns the color value scaled for luminance
   */
  private _getLuminanceColor(color: number): number {
    const LOWER_STANDARD_BOUND = 0.03928;
    const standardRGBValue = color / 255;

    const getUpperLuminanceValue = (standardColor: number): number =>  {
      const STANDARD_COLOR_OFFSET = 0.055;
      const UPPER_LUMINANCE_RATIO = 1.055;
      const UPPER_LUMINANCE_EXPONENTIAL = 2.4;

      return Math.pow(
        (
          (standardColor + STANDARD_COLOR_OFFSET) / UPPER_LUMINANCE_RATIO
        ), UPPER_LUMINANCE_EXPONENTIAL
      );
    };

    const getLowerLuminanceValue = (standardColor: number): number => {
      const LOWER_LUMINANCE_RATIO = 12.92;
      return standardColor / LOWER_LUMINANCE_RATIO;
    };

    return standardRGBValue <= LOWER_STANDARD_BOUND ? getLowerLuminanceValue(standardRGBValue) : getUpperLuminanceValue(standardRGBValue);
  }
}
