import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf
} from "@angular/core";
import { Observable, of } from "rxjs";
import {
  map,
  tap,
  catchError,
  takeUntil
} from "rxjs/operators";

import { ChatMessage, ChatroomBlockData } from "@apptypes/chat.types";
import { Unsubscriber } from "@utils/unsubscriber";
import { ChatService } from "@services/chat/chat.service";
import { CHATROOM_COLLECTION } from "@providers/chatroom-collection";

import { FirestoreChatroomCollections } from "src/app/enums/FirestoreCollections.enum";

@Component({
  selector: "app-chat-block",
  templateUrl: "./chat-block.component.html",
  styleUrls: ["./chat-block.component.scss"]
})
export class ChatBlockComponent implements OnInit {
  @Input() public chatroomData!: ChatroomBlockData;

  public chatMessages$: Observable<ChatMessage[]> = of([]);
  public chatLoadError = false;
  public isBannerShowing = false;

  private _unsubHandler = new Unsubscriber();

  constructor(
    private _chatService: ChatService,
    @Inject(CHATROOM_COLLECTION) @SkipSelf() @Optional() private _firestoreCollection: FirestoreChatroomCollections | null,
  ) { }

  public ngOnInit(): void {
    if(this._firestoreCollection === null){
      alert("Could not load chatroom");
      console.error("No firestore collection provided");
      return;
    }

    this.chatMessages$ = this._chatService.getChatroomByDocID(this.chatroomData.chatroomReferenceId, this._firestoreCollection).pipe(
      map((fireChat) => {
        if (fireChat !== null) {
          return this._chatService.mapChatroomMessages(
            fireChat.chatroom,
            fireChat.chatMembers.chatMembers,
            this.chatroomData.teams,
          );
        }

        return [];
      }),
      tap(() => {
        this.chatLoadError = false;
      }),
      catchError((err) => {
        this.chatLoadError = true;
        console.error(err);
        return [];
      }),
      takeUntil(this._unsubHandler.unsubEvent)
    );
  }

}
