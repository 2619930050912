import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  BattleRoyaleEventCardInfo,
  EliminationEventCardInfo,
  EventCardTeam,
  NullEventCardTeam,
  QueueMatchEventCardInfo,
  QueueSessionEventCardInfo,
  QuickPlayEventCardInfo,
  QuickPlayEventChatroom,
  RoundRobinEventCardInfo,
  TournamentEventCardInfo
} from "@apptypes/ggl-event-card.types";
import { Nullish } from "@apptypes/nullish.types";

import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";
import { basicAuthHeader, basicAuthHeaderWJSON } from "src/app/utils/auth-header.util";
import { environment } from "src/environments/environment";
import {
  APILeagueAdminSeriesMatchup,
  APIQuickplayEvent,
  APIQuickplayEventChatroom,
  APITournamentQueueSession,
  AssignmentListAPIResponse,
  AssignmentListAPITeam,
  TournamentAPIMatch
} from "./event-import.types";
import { isoNow } from "@utils/date.utils";
import { QueueMatchRoundInfo } from "@services/queue-import-match/queue-import-match.types";


@Injectable({
  providedIn: "root"
})
export class EventImportService {

  constructor(private _http: HttpClient) { }

  public getQueueInfo(queueSessionId: string): Observable<QueueSessionEventCardInfo> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournament_queue_sessions/${queueSessionId}`;
    const options = {
      headers: basicAuthHeader()
    };

    return this._http.get<{ data: APITournamentQueueSession }>(url, options).pipe(
      map(this._mapAPIQueueSessionToQueueMatchCard)
    );
  }

  public getBattleRoyale(seriesMatchupId: string): Observable<BattleRoyaleEventCardInfo> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/${seriesMatchupId}`;
    const options = {
      headers: basicAuthHeader()
    };

    return this._http.get<{ data: APILeagueAdminSeriesMatchup }>(url, options).pipe(
      map(this._mapAPISeriesMatchupToBattleRoyaleMatchCard)
    );
  }

  public getRoundRobinRound(tournamentID: string, roundNumber: string): Observable<RoundRobinEventCardInfo[]> {
    return this._getTournamentRound(tournamentID, [roundNumber]).pipe(
      map((tournamentCards) => tournamentCards.map(
        (tournamentCard) => ({
          ...tournamentCard,
          type: GGLEventCardTypes.ROUND_ROBIN,
        }))
      )
    );
  }

  public getEliminationRound(tournamentID: string, roundNumber: string): Observable<EliminationEventCardInfo[]> {
    return this._getTournamentRound(tournamentID, [roundNumber]).pipe(
      map((tournamentCards) => tournamentCards.map(
        (tournamentCard) => ({
          ...tournamentCard,
          type: GGLEventCardTypes.ELIMINATION,
        }))
      )
    );
  }

  public getQueueMatches(
    tournamentId: string,
    rounds: string[],
    seriesMatchupIdRoundMap: Map<string, QueueMatchRoundInfo>
  ): Observable<QueueMatchEventCardInfo[]> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/load_observables`;
    const options = {
      headers: basicAuthHeaderWJSON()
    };
    const payload = {
      series_matchup: {
        tournament_id: tournamentId,
        round_numbers: rounds
      }
    };

    return this._http.post<AssignmentListAPIResponse>(url, payload, options).pipe(
      map((apiRes) =>
        apiRes.data.map(
          apiMatch => this._mapAPIQueueMatchToQueueMatchCard(apiMatch, tournamentId, seriesMatchupIdRoundMap.get(apiMatch.id) ?? {
            queueRound: "-1",
            round: "-1"
          })
        )
      ),
    );
  }

  public getQuickplayEvent(quickplayEventId: string): Observable<QuickPlayEventCardInfo> {
    const url = `${environment.apiBase}/api/v2/league_admin/quick_play_events/${quickplayEventId}`;
    const options = {
      headers: basicAuthHeaderWJSON()
    };

    return this._http.get<{ data: APIQuickplayEvent }>(url, options).pipe(
      map(this._mapAPIQuickplayEventToQuickplayEventCard)
    );
  }

  private _getTournamentRound(tournamentId: string, roundNumbers: string[]): Observable<TournamentEventCardInfo[]> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/load_observables`;
    const options = {
      headers: basicAuthHeaderWJSON()
    };
    const payload = {
      series_matchup: {
        tournament_id: tournamentId,
        round_numbers: roundNumbers
      }
    };

    return this._http.post<AssignmentListAPIResponse>(url, payload, options).pipe(
      map((apiRes) => this._mapAPIMatchesToTournamentCard(apiRes, tournamentId.toString(), roundNumbers[0]))
    );
  }

  private _mapAPIQueueMatchToQueueMatchCard = (
    apiMatch: TournamentAPIMatch,
    tournamentID: string,
    { round: tournamentRound, queueRound }: QueueMatchRoundInfo
  ): QueueMatchEventCardInfo => ({
    ...this._mapAPIMatchToTournamentCard(apiMatch, tournamentID, tournamentRound),
    type: GGLEventCardTypes.QUEUE_MATCH,
    queueRound,
  });


  private _mapAPIMatchesToTournamentCard = (
    { data: apiMatchList }: AssignmentListAPIResponse,
    tournamentID: string,
    tournamentRound: string
  ): TournamentEventCardInfo[] => apiMatchList.map(
    (apiMatch) => this._mapAPIMatchToTournamentCard(apiMatch, tournamentID, tournamentRound));

  private _mapAPIMatchToTournamentCard = (
    apiMatch: TournamentAPIMatch,
    tournamentID: string,
    tournamentRound: string
  ): TournamentEventCardInfo => {
    const [apiTeamOne, apiTeamTwo] = apiMatch.attributes.teams.data;
    return {
      id: apiMatch.id,
      type: GGLEventCardTypes.ROUND_ROBIN,//Gets Overridden
      matchChatroomID: apiMatch.attributes.matchChatroomId,
      matchupStartTime: apiMatch.attributes.matchupStartTime,
      leagueID: apiMatch.attributes.league.data.id,
      leagueName: apiMatch.attributes.league.data.attributes.title,
      esport: apiMatch.attributes.esportName,
      teams: [this._mapAPIMatchTeam(apiTeamOne), this._mapAPIMatchTeam(apiTeamTwo)],
      winnerID: apiMatch.attributes.winnerId,
      tournamentID,
      tournamentRound,
      importedAt: isoNow(),
    };
  };

  private _mapAPIMatchTeam = (apiMatchTeam: Nullish<AssignmentListAPITeam>): EventCardTeam => !!apiMatchTeam ? {
    id: apiMatchTeam.id,
    title: apiMatchTeam.attributes.title,
  } : NullEventCardTeam;

  private _mapAPISeriesMatchupToBattleRoyaleMatchCard = ({ data }: { data: APILeagueAdminSeriesMatchup }): BattleRoyaleEventCardInfo => ({
    type: GGLEventCardTypes.BATTLE_ROYALE,
    id: data.id,
    matchChatroomId: data.attributes.matchupChatroomId,
    matchupStartTime: data.attributes.matchupStartTime,
    leagueID: data.attributes.league.data.id,
    leagueName: data.attributes.league.data.attributes.title,
    winnerID: data.attributes.winnerId,
    winnerTitle: data.attributes.winnerTitle,
    esport: data.attributes.esportName,
    teams: data.attributes.teams.data.map((apiTeam) => ({
      id: apiTeam.id,
      title: apiTeam.attributes.title,
    })),
    importedAt: isoNow(),
  });

  private _mapAPIQueueSessionToQueueMatchCard = ({ data }: { data: APITournamentQueueSession }): QueueSessionEventCardInfo => ({
    id: data.id,
    type: GGLEventCardTypes.QUEUE,
    leagueID: data.attributes.league.data.id,
    leagueName: data.attributes.league.data.attributes.title,
    status: data.attributes.status,
    esport: data.attributes.esport,
    teams: data.attributes.tournament.data.attributes.activeTeams.data.map((apiTeam) => ({
      id: apiTeam.id,
      title: apiTeam.attributes.title,
    })),
    queueStartTime: data.attributes.startTime,
    tournamentID: data.attributes.tournament.data.id,
    tournamentTitle: data.attributes.tournament.data.attributes.title,
    autoImportMatches: false,
    importedAt: isoNow(),
  });

  private _mapAPIQuickplayEventToQuickplayEventCard = (
    { data: { id, attributes } }: { data: APIQuickplayEvent }
  ): QuickPlayEventCardInfo => ({
    id,
    type: GGLEventCardTypes.QUICKPLAY,
    startTime: attributes.startTime,
    status: attributes.status,
    timeZone: attributes.timeZone,
    esport: attributes.esport.data.attributes.name,
    leagueID: attributes.league.data.id,
    leagueName: attributes.league.data.attributes.title,
    chatroom: this._mapAPIQuickPlayChatroom(attributes.quickPlayEventChatroom),
    importedAt: isoNow(),
  });

  private _mapAPIQuickPlayChatroom = (
    { data: { id, type, attributes: { status, firestoreDocumentId } } }: { data: APIQuickplayEventChatroom }
  ): QuickPlayEventChatroom => ({
    id,
    type,
    status,
    firestoreDocumentId
  });
}
