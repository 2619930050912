<aside>
  <header class="modal-header">
    <h4 class="modal-title">Are you sure you want to close the queue?</h4>
  </header>

  <div class="modal-body">
    <ng-container [ngSwitch]="componentState">
      <ng-container
        *ngSwitchCase="closeQueueComponentStateOptions.LOADING"
        [ngTemplateOutlet]="loading"
      >
      </ng-container>
      <ng-container
        *ngSwitchCase="closeQueueComponentStateOptions.SUCCESS"
        [ngTemplateOutlet]="success"
      >
      </ng-container>
      <ng-container
        *ngSwitchCase="closeQueueComponentStateOptions.ERROR"
        [ngTemplateOutlet]="error"
      >
      </ng-container>
      <ng-container
        *ngSwitchCase="closeQueueComponentStateOptions.DEFAULT"
        [ngTemplateOutlet]="default"
      >
      </ng-container>
      <ng-container
        *ngSwitchCase="closeQueueComponentStateOptions.ALREADY_COMPLETE"
        [ngTemplateOutlet]="alreadyComplete"
      ></ng-container>
      <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="default"
      ></ng-container>
    </ng-container>
  </div>
  <ng-template #alreadyComplete>
    <p>This queue is already complete and does not need to be closed.</p>
  </ng-template>
  <ng-template #loading>
    <app-lds-spinner [isOnDarkBackground]="false"></app-lds-spinner>
  </ng-template>
  <ng-template #success> The queue was closed successfully. </ng-template>
  <ng-template #error>
    <p>
      <ng-container *ngIf="!!queue">
        <span class="text-danger"
          >There was an error attempting to close this queue.</span
        >
        <div
          class="col-md-auto"
          *ngIf="componentState === closeQueueComponentStateOptions.DEFAULT"
        >
          <button type="button" class="btn btn-success" (click)="closeQueue()">
            Close Queue
          </button>
        </div>
        Please ask the tech team to investigate this. The queue ID is
        <span class="text-danger font-weight-bolder">{{ queue.id }}</span> and
        the league ID is
        <span class="text-danger font-weight-bolder">{{
          queue.league.id
        }}</span>
      </ng-container>
      <ng-container *ngIf="!queue">
        The queue could not be loaded properly for this modal.
      </ng-container>
    </p>
  </ng-template>
  <ng-template #default>
    The close time for this queue is
    {{ queueStartTime | date: "M/d/yy @ h:mm a" }}. Please ensure that you are
    closing the queue at the proper time.
  </ng-template>
  <footer class="modal-footer">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="close()"
          >
            Cancel
          </button>
        </div>
        <div
          class="col-md-auto"
          *ngIf="componentState === closeQueueComponentStateOptions.DEFAULT"
        >
          <button type="button" class="btn btn-success" (click)="closeQueue()">
            Close Queue
          </button>
        </div>
      </div>
    </div>
  </footer>
</aside>
