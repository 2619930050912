import {
  Component,
  forwardRef,
  OnDestroy,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from "@angular/forms";
import { Nullish } from "@apptypes/nullish.types";
import { BOOTSTRAP_HEXES } from "@services/color/colors.constants";
import { Subscription } from "rxjs";

import { EventColors } from "src/app/enums/EventColors.enum";

@Component({
  selector: "app-event-color-form-select",
  templateUrl: "./event-color-form-select.component.html",
  styleUrls: ["./event-color-form-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventColorFormSelectComponent),
      multi: true
    }
  ]
})
export class EventColorFormSelectComponent implements ControlValueAccessor, OnDestroy {
  public eventColor = new FormControl(EventColors.WHITE);
  public colors = EventColors;
  public bootstrapHexes = BOOTSTRAP_HEXES;

  private _subscriptionBucket: Subscription[] = [];

  public ngOnDestroy(): void {
    this._subscriptionBucket.forEach((sub) => sub.unsubscribe());
  }

  public writeValue(color: Nullish<EventColors>): void {
    if(!color){
      this.eventColor.reset(EventColors.WHITE);
      return;
    }
    this.eventColor.setValue(color);
  }

  public registerOnChange(fn: () => any): void {
    this._subscriptionBucket.push(this.eventColor.valueChanges.subscribe(fn));
  }

  public registerOnTouched(onTouchFn: () => any): void {
    this.onTouch = onTouchFn;
  }

  public onTouch = () => {};
}
