<div class="form-group">
  <label for="eventCardColor">Choose a Color: </label>
  <input
    id="eventCardColor"
    type="color"
    class="form-control input-sm"
    [formControl]="eventColor"
    aria-label="Select Event Card Color"
  />
</div>
