import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

@Component({
  selector: "app-lds-spinner",
  templateUrl: "./lds-spinner.component.html",
  styleUrls: ["./lds-spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LdsSpinnerComponent {
  @Input() public isOnDarkBackground = true;
}
