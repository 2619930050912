import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { BattleRoyaleEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-battle-royale-event-card-content",
  templateUrl: "./battle-royale-event-card-content.component.html",
  styleUrls: ["./battle-royale-event-card-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BattleRoyaleEventCardContentComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: BattleRoyaleEventCardInfo;
}
