import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
  SkipSelf
} from "@angular/core";

import { ChatMessage } from "@apptypes/chat.types";
import { MODERATION_SERVICE } from "@providers/moderation-service/moderation-service.token";
import { ModerationService } from "@services/chat/moderation/moderation.abstract.service";

@Component({
  selector: "app-hide-chat-message-dropdown-item",
  templateUrl: "./hide-chat-message-dropdown-item.component.html",
  styleUrls: ["./hide-chat-message-dropdown-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HideChatMessageDropdownItemComponent {
  @Input() public chatMessage!: ChatMessage;
  @Input() public resourceID!: string;

  constructor(
    @Inject(MODERATION_SERVICE) @SkipSelf() @Optional() private _moderationService: ModerationService | null,
  ) {}

  public hideMessage(): void {
    if(!this._moderationService){
      console.error("No moderation service provided");
      alert("Could not hide message");
      return;
    }

    if(this.chatMessage.documentId){
      this._moderationService.hideChatMessage(this.resourceID, this.chatMessage.documentId).subscribe();
    }
  }
}
