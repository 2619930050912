import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { EliminationEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-remove-elimination-event",
  templateUrl: "./remove-elimination-event.component.html",
  styleUrls: ["./remove-elimination-event.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveEliminationEventComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: EliminationEventCardInfo;
}
