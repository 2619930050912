import { HttpHeaders } from "@angular/common/http";

import { UserLoginToken } from "../services/auth/auth.service";
import { CacheService } from "../services/cache/cache.service";

export const basicAuthHeader = () => {
  const jwt = getJWT();
  return new HttpHeaders({
    Authorization: `Bearer ${jwt}`,
  });
};

export const basicAuthHeaderWJSON = () => {
  const jwt = getJWT();
  return new HttpHeaders({
    Authorization: `Bearer ${jwt}`,
    "Content-Type": "application/json",
  });
};

const getJWT = (): string => {
  try {
    const authObj = JSON.parse(localStorage.getItem(CacheService.AUTH_KEY) ?? "{}") as UserLoginToken;
    return authObj.jwt;
  } catch (e) {
    return "";
  }
};
