import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";

import { environment } from "@environments/environment";
import { CloudSeriesMatchupCommentService, RailsSeriesMatchupCommentService } from "@services/chat/comment";

import { COMMENT_SERVICE } from "./comment-service.token";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function SeriesMatchupCommentFactory(
  http: HttpClient,
): CloudSeriesMatchupCommentService | RailsSeriesMatchupCommentService {
  return environment.useCloudChats ?
    new CloudSeriesMatchupCommentService(http) :
    new RailsSeriesMatchupCommentService(http);
}

/**
 * Provides a CommentService for SeriesMatchup Chatrooms, will automatically
 * inject the cloud service if available
 */
export const SeriesMatchupCommentProvider: Provider = {
  provide: COMMENT_SERVICE,
  useFactory: SeriesMatchupCommentFactory,
  deps: [HttpClient],
};

