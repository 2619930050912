import { Component, Input } from "@angular/core";

import { SchedulingType } from "@apiEnums/SchedulingType.enum";
import { SeriesMatchupChatroomProvider } from "@providers/chatroom-collection";
import { SeriesMatchupCommentProvider } from "@providers/comment-service";
import { SeriesMatchupModerationProvider } from "@providers/moderation-service";
import { environment } from "@environments/environment";

import { GGLEventDetailsComponent } from "src/app/interfaces/components/ggl-event-details.component.interface";
import {
  MatchDetail,
  MatchDetailLeague,
  MatchDetailTeam
} from "src/app/types/games.types";

@Component({
  selector: "app-match-detail",
  templateUrl: "./match-detail.component.html",
  styleUrls: ["./match-detail.component.scss"],
  providers: [
    SeriesMatchupCommentProvider,
    SeriesMatchupChatroomProvider,
    SeriesMatchupModerationProvider
  ],
})
export class MatchDetailComponent implements GGLEventDetailsComponent {
  @Input() public GGLEventDetailsData!: MatchDetail;
  public schedulingType = SchedulingType;

  public get match(): MatchDetail {
    return this.GGLEventDetailsData;
  }

  public showWinner(): string {
    if (this.match.winnerId) {
      return this.match.teams.find(team => team.id === this.match.winnerId)?.title ?? "TBD";
    }

    return "TBD";
  }

  public getGameAdminLink(match: MatchDetail): string {
    return `${environment.apiBase}/admin/series_matchups/${match.id}`;
  }

  public getGameAppLink(match: MatchDetail): string {
    return `${environment.appBase}/series_matchup/${match.id}`;
  }

  public getLeagueLink(league: MatchDetailLeague): string {
    return `${environment.appBase}/leagues/${league.id}`;
  }

  public getTeamLink(team: MatchDetailTeam): string {
    return `${environment.appBase}/teams/${team.id}`;
  }
}
