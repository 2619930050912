<article
  class="card"
  [ngClass]="{
    card_selectedForRemoval: isSelectedForRemoval,
    card_active: isActive$ | async
  }"
  [ngStyle]="{
    color: fontColor,
    'background-color': backgroundColor
  }"
  (click)="removeEventByMassRemoval(gglEventCardInfo)"
>
  <section class="card-body">
    <header>
      <div class="card-cancel-button" *ngIf="!isInRemovalMode">
        <app-remove-event-modal [gglEventCardInfo]="gglEventCardInfo">
        </app-remove-event-modal>
      </div>
    </header>
    <ng-template appComponentPortal></ng-template>
    <ng-container *ngIf="hasErrorResolvingComponent">
      <p>
        Could not resolve the event card. Please check with the tech team about
        this.
      </p>
      <dl>
        <dt>Event Type</dt>
        <dd>{{ gglEventCardInfo.type | enumTitle }}</dd>
        <dt>ID</dt>
        <dd>
          <small>{{ gglEventCardInfo.id }}</small>
        </dd>
      </dl>
    </ng-container>
    <button class="btn btn-primary" (click)="setActiveEvent(gglEventCardInfo)">
      View Event
    </button>
  </section>
</article>
