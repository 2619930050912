import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {
  interval,
  Observable,
  of,
  timer
} from "rxjs";
import {
  finalize,
  map,
  startWith,
  takeUntil
} from "rxjs/operators";
import { differenceInMinutes, differenceInSeconds } from "date-fns";

import { ISOString } from "@apptypes/aliases.types";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"]
})
export class TimerComponent implements OnInit {
  @Input() public countDownTime: ISOString | null = null;
  @Output() public countDownEvent: EventEmitter<void> = new EventEmitter<void>();
  public timerText$: Observable<string> = of("");

  public ngOnInit(): void {
    if(this.countDownTime){
      const countDownDate = new Date(this.countDownTime);

      this.timerText$ = interval(1000).pipe(
        startWith(() => {
          const currentTime = new Date();
          return this._getMinuteSecondDifference(countDownDate, currentTime);
        }),
        map(() => {
          const currentTime = new Date();
          return this._getMinuteSecondDifference(countDownDate, currentTime);
        }),
        takeUntil(timer(countDownDate)),
        finalize(() => this.countDownEvent.emit())
      );
    }
  }

  private _getMinuteSecondDifference(dateA: Date, dateB: Date): string {
    try {
      const minuteDiff = differenceInMinutes(dateA, dateB);
      const secondDiff = (differenceInSeconds(dateA, dateB) % 60);
      const minuteText = minuteDiff <= 0 ? "00" : minuteDiff.toString().padStart(2, "0");
      const secondText = secondDiff <= 0 ? "00" : secondDiff.toString().padStart(2, "0");
      if(minuteText === "NaN" || secondText === "NaN"){
        throw new Error();
      }
      return `${minuteText}:${secondText}`;
    }
    catch(e){
      console.error(e);
      return "ERROR PARSING DATE";
    }
  }
}
