import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { GGLEventTypes } from "@apptypes/card-details.types";
import { QuickPlayEventChatroom } from "@apptypes/ggl-event-card.types";
import {
  QuickPlayEvent,
  QuickPlayEventEsport,
  QuickPlayEventLeague,
  QuickPlayEventLeagueTeam,
  QuickPlayEventLeagueTeamUser,
  QuickPlayEventStreamCheckin
} from "@apptypes/quickplay.types";

import { environment } from "@environments/environment";
import {
  APIEsport,
  APIQuickplayEvent,
  APIQuickplayEventChatroom,
  APIQuickPlayEventLeague,
  APIQuickPlayEventLeagueTeam,
  APIQuickPlayEventLeagueTeamUser
} from "@services/event-import/event-import.types";
import { basicAuthHeaderWJSON } from "@utils/auth-header.util";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FirestoreCollections } from "src/app/enums/FirestoreCollections.enum";
import { APIQuickPlayEventStreamCheckin } from "./quick-play-events.api.types";

@Injectable({
  providedIn: "root"
})
export class QuickPlayEventsService {
  constructor(
    private _http: HttpClient,
    private _angularFire: AngularFirestore
  ) { }

  public getQuickPlayEvent(quickPlayEventID: string): Observable<QuickPlayEvent> {
    const url = `${environment.apiBase}/api/v2/league_admin/quick_play_events/${quickPlayEventID}`;
    const options = {
      headers: basicAuthHeaderWJSON()
    };

    return this._http.get<{ data: APIQuickplayEvent }>(url, options).pipe(
      map(this._mapAPIQuickPlayEvent)
    );
  }

  public getQuickPlayEventCheckins(quickPlayEventStreamReferenceID: string): Observable<QuickPlayEventStreamCheckin[]> {
    return this._angularFire.collection(
      FirestoreCollections.QUICK_PLAY_EVENT_STREAMS
    ).doc(quickPlayEventStreamReferenceID)
      .collection<APIQuickPlayEventStreamCheckin>(FirestoreCollections.QUICK_PLAY_EVENT_CHECKINS).valueChanges().pipe(
      map((apiCheckins) => apiCheckins.map(this._mapQuickPlayEventCheckin))
    );
  }

  private _mapAPIQuickPlayEvent= (
    {
      data: {
        id, attributes: {
          startTime,
          timeZone,
          status,
          esport,
          quickPlayEventChatroom,
          league,
          quickPlayEventStream
        }
      }
    }: { data: APIQuickplayEvent }
  ): QuickPlayEvent => ({
    id,
    type: GGLEventTypes.QUICKPLAY_EVENT,
    startTime,
    timeZone,
    status,
    streamReferenceID: quickPlayEventStream.data.attributes.streamReferenceId,
    esport: this._mapQuickPlayEventEsport(esport),
    league: this._mapQuickPlayLeague(league),
    chatroom: this._mapAPIQuickPlayChatroom(quickPlayEventChatroom),
  });

  private _mapAPIQuickPlayChatroom = (
    { data: { id, type, attributes: { status, firestoreDocumentId } } }: { data: APIQuickplayEventChatroom }
  ): QuickPlayEventChatroom => ({
    id,
    type,
    status,
    firestoreDocumentId
  });

  private _mapQuickPlayEventEsport = (
    { data: { id, type, attributes: { name, title } } }: { data: APIEsport }
  ): QuickPlayEventEsport => ({
    id,
    type,
    name,
    title
  });

  private _mapQuickPlayLeague = (
    { data: { id, type, attributes: { title, teams } } }: { data: APIQuickPlayEventLeague }
  ): QuickPlayEventLeague => ({
    id,
    type,
    title,
    teams: teams.data.map(this._mapQuickPlayLeagueTeam),
  });

  private _mapQuickPlayLeagueTeam = (
    { id, type, attributes: { title, users } }: APIQuickPlayEventLeagueTeam
  ): QuickPlayEventLeagueTeam => ({
    id,
    type,
    title,
    users: users.data.map(this._mapQuickPlayLeagueTeamUser),
  });

  private _mapQuickPlayLeagueTeamUser = (
    { id, type, attributes: { username, email } }: APIQuickPlayEventLeagueTeamUser
  ): QuickPlayEventLeagueTeamUser => ({
    id,
    type,
    username,
    email
  });

  private _mapQuickPlayEventCheckin = (
    { esport_credential_snapshot, user_id, team_id, quick_play_event_checkin_id }: APIQuickPlayEventStreamCheckin
  ): QuickPlayEventStreamCheckin => ({
    esportCredentialSnapshot: esport_credential_snapshot,
    userID: `${user_id}`,
    teamID: `${team_id}`,
    quickPlayEventCheckinID: `${quick_play_event_checkin_id}`,
  });
}
