import { SchedulingType } from "@apiEnums/SchedulingType.enum";

export interface Chatroom {
  chatroom: FirestoreChatroom;
  chatMembers: ChatMembers;
  type: "chatroom";
}

export interface FirestoreChatroom {
  gameId: number | null;
  seriesMatchupId: string | null;
  status: string;
  type: "chatroom";
  messages: FirestoreChatMessage[];
  chatroomId: string;
}

export interface FirestoreChatMessage {
  documentId?: string;
  hiddenById?: string;
  unhiddenById?: string;
  hideChatMessage?: boolean;
  date: number;
  message: string;
  isAdmin: boolean;
  userId: number;
}

export interface ChatMembers {
  chatMembers: MatchCommenter[];
  type: "chatMembers";
}

export interface MatchCommenter {
  id: number;
  inGameName: string;
}

export interface ChatMessage {
  isAdmin: boolean;
  message: string;
  date: number;
  userId: number;
  username: string;
  team: ChatroomBlockTeam | null;
  documentId?: string;
  isHidden?: boolean;
  hiddenById?: string;
  unhiddenById?: string;
}

export enum ChatTeamCode {
  TEAM_ONE = 0,
  TEAM_TWO = 1,
}

export enum ChatModerateActions {
  HIDE = "hide",
  UNHIDE = "unhide",
};

export interface ChatroomBlockData {
  id: string;
  chatroomReferenceId: string;
  schedulingType: SchedulingType;
  teams: ChatroomBlockTeam[];
}

export interface ChatroomBlockTeam {
  id: string;
  title: string;
  userIds: string[];
}

