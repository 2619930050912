import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";

import { RemoveEventModalContentComponent } from "../remove-event-modal-content/remove-event-modal-content.component";

@Component({
  selector: "app-remove-event-modal",
  templateUrl: "./remove-event-modal.component.html",
  styleUrls: ["./remove-event-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveEventModalComponent {
  @Input() public gglEventCardInfo!: GGLEventCardInfo;

  constructor(
    private _modalService: NgbModal,
  ) {}

  public open() {
    const modalRef = this._modalService.open(RemoveEventModalContentComponent);
    modalRef.componentInstance.gglEventCardInfo = this.gglEventCardInfo;
  }
}
