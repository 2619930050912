import { Provider } from "@angular/core";

import { TournamentModerationService } from "@services/chat/moderation";

import { MODERATION_SERVICE } from "./moderation-service.token";

export const TournamentModerationProvider: Provider = {
  provide: MODERATION_SERVICE,
  useClass: TournamentModerationService,
};
