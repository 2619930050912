import { Injectable } from "@angular/core";

import {
  BattleRoyaleEventCardInfo,
  EventCardTeam,
  GGLEventCardInfo,
  QueueSessionEventCardInfo,
  QuickPlayEventCardInfo,
  TournamentEventCardInfo
} from "@apptypes/ggl-event-card.types";
import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";

@Injectable({
  providedIn: "root"
})
export class GglEventFuzzySearchService {
  public fuzzyFindEventCards(cards: GGLEventCardInfo[], filterTerm: string, leagueFilterId: string | null): GGLEventCardInfo[] {
    return cards.filter((card) => this._filterCard(card, filterTerm, leagueFilterId));
  }

  private _filterCard = (card: GGLEventCardInfo, filterTerm: string, leagueFilterId: string | null): boolean => {
    switch(card.type) {
      case GGLEventCardTypes.BATTLE_ROYALE:
        return this._battleRoyaleEventCardInfoFilter(card, filterTerm, leagueFilterId);
      case GGLEventCardTypes.ELIMINATION:
      case GGLEventCardTypes.ROUND_ROBIN:
      case GGLEventCardTypes.QUEUE_MATCH:
        return this._tournamentEventCardInfoFilter(card, filterTerm, leagueFilterId);
      case GGLEventCardTypes.QUEUE:
        return this._queueSessionEventCardInfoFilter(card, filterTerm, leagueFilterId);
      case GGLEventCardTypes.QUICKPLAY:
        return this._quickplayEventCardFilter(card, filterTerm, leagueFilterId);
      default:
        return false;
    }
  };

  private _battleRoyaleEventCardInfoFilter = (
    card: BattleRoyaleEventCardInfo,
    filterTerm: string,
    leagueFilterId: string | null
  ): boolean => {
    const { id, leagueID, leagueName, esport, teams } = card;

    const fuzzyTerms = [
      id,
      leagueName,
      esport,
      ...this._reduceEventCardTeamsToTerms(teams),
    ];

    if (leagueFilterId) {
      if (leagueID !== leagueFilterId) {
        return false;
      }
    }

    return this._findPartialMatchInList(fuzzyTerms, filterTerm);
  };

  private _tournamentEventCardInfoFilter = (card: TournamentEventCardInfo, filterTerm: string, leagueFilterId: string | null): boolean  => {
    const { id, leagueID, leagueName, esport, teams } = card;

    const fuzzyTerms = [
      id,
      leagueName,
      esport,
      ...this._reduceEventCardTeamsToTerms(teams),
    ];

    if (leagueFilterId) {
      if (leagueID !== leagueFilterId) {
        return false;
      }
    }

    return this._findPartialMatchInList(fuzzyTerms, filterTerm);
  };

  private _queueSessionEventCardInfoFilter = (
    card: QueueSessionEventCardInfo, filterTerm: string, leagueFilterId: string | null
  ): boolean => {
    const { id, leagueID, leagueName, esport, tournamentTitle, teams } = card;

    const fuzzyTerms = [
      id,
      leagueName,
      esport,
      tournamentTitle,
      ...this._reduceEventCardTeamsToTerms(teams),
    ];

    if (leagueFilterId) {
      if (leagueID !== leagueFilterId) {
        return false;
      }
    }

    return this._findPartialMatchInList(fuzzyTerms, filterTerm);
  };

  private _quickplayEventCardFilter = (card: QuickPlayEventCardInfo, filterTerm: string, leagueFilterId: string | null): boolean => {
    const { esport, id, leagueID, leagueName } = card;
    const fuzzyTerms = [
      esport,
      id,
      leagueName
    ];

    if (leagueFilterId) {
      if (leagueID !== leagueFilterId) {
        return false;
      }
    }

    return this._findPartialMatchInList(fuzzyTerms, filterTerm);
  };



  private _findPartialMatchInList = (
    listOfTerms: string[], filterTerm: string
  ): boolean => listOfTerms.find(term => this._doesPartialMatchExist(term, filterTerm)) !== undefined;

  private _doesPartialMatchExist = (base: string, term: string): boolean =>
    base.toLowerCase().indexOf(term.trim().toLowerCase()) !== -1;

  private _reduceEventCardTeamsToTerms = (
    eventCardTeams: EventCardTeam[] = []
  ): string[] => {
    const teamTerms: string[] = [];
    eventCardTeams.forEach((team) => teamTerms.push(team.id, team.title));
    return teamTerms;
  };
}
