import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { QuickPlayEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-remove-quickplay-event",
  templateUrl: "./remove-quickplay-event.component.html",
  styleUrls: ["./remove-quickplay-event.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveQuickplayEventComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: QuickPlayEventCardInfo;
}
