import { Injectable } from "@angular/core";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { compareISODateDesc } from "@utils/date.utils";

/**
 * A service to handle deduplication of event cards.
 */
@Injectable({
  providedIn: "root"
})
export class DedupeService {

  /**
   * Filters the array of event cards to unique event cards. If there is a conflict, the most
   * recently imported event card is added
   *
   * @param eventCards
   * @returns the unique set of event cards
   */
  public deduplicateEventCards(eventCards: GGLEventCardInfo[]): GGLEventCardInfo[] {
    // eslint-disable-next-line object-curly-newline
    const eventCardHashMap: { [key: string]: GGLEventCardInfo } = {};

    eventCards.forEach((newEventCard) => {
      const eventCardKey = `${newEventCard.type}-${newEventCard.id}`;
      const initialCard: GGLEventCardInfo | undefined = eventCardHashMap[eventCardKey];

      if(initialCard === undefined) {
        eventCardHashMap[eventCardKey] = newEventCard;
        return;
      }

      const isNewDateSooner = compareISODateDesc(newEventCard.importedAt, initialCard.importedAt) <= 0;

      if(isNewDateSooner) {
        eventCardHashMap[eventCardKey] = newEventCard;
        return;
      }
    });

    const uniqueCards = Object.values(eventCardHashMap);

    return uniqueCards;
  }
}
