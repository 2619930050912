import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { map, tap } from "rxjs/operators";
import { basicAuthHeader } from "src/app/utils/auth-header.util";
import { CacheService } from "../cache/cache.service";

interface UserProfileDataAPIResponse {
  data: {
    type: string;
    id: string;
    attributes: {
      id: number;
      type: string;
      addressStreet1: string;
      addressStreet2: string;
      birthday: string;
      city: string;
      description: string;
      discordId: string;
      email: string;
      firstName: string;
      inGameName: string;
      lastName: string;
      phone: string;
      state: string;
      zipCode: string;
      isAdmin: boolean;
    };
  };
}

export interface UserProfile {
  id: number;
  type: "profile";
  description?: string;
  discordId?: string;
  email?: string;
  firstName: string;
  inGameName: string;
  lastName: string;
  profilePicture?: string;
  isAdmin?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _http: HttpClient, private _cacheService: CacheService) {}

  public getProfile(): Observable<UserProfile> {
    const url = `${environment.apiBase}/api/v1/users/profile.json`;
    const headers = basicAuthHeader();
    return this._http
      .get<UserProfileDataAPIResponse>(url, {
      headers
    })
      .pipe(
        map((apiResponse) => this._mapUserProfile(apiResponse)),
        tap((mappedUser) => this._cacheService.cacheUser(mappedUser))
      );
  }

  private _mapUserProfile(apiResponse: UserProfileDataAPIResponse): UserProfile {
    return {
      id: apiResponse.data.attributes.id,
      type: "profile",
      description: apiResponse.data.attributes.description,
      discordId: apiResponse.data.attributes.discordId,
      email: apiResponse.data.attributes.email,
      firstName: apiResponse.data.attributes.firstName,
      lastName: apiResponse.data.attributes.lastName,
      inGameName: apiResponse.data.attributes.inGameName,
      isAdmin: apiResponse.data.attributes.isAdmin,
    };
  }
}
