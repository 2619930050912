import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ChatroomBlockData } from "@apptypes/chat.types";

import { Comment } from "./comment.api.types";

/**
 * A class that posts messages to different chats across the site.
 *
 * @see https://indepth.dev/posts/1414/angular-and-solid-principles
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export abstract class CommentService {
  constructor(
    protected _http: HttpClient
  ) {}

  public abstract postComment(chatroomBlockData: ChatroomBlockData, authorId: string, message: string): Observable<any>;

  protected abstract _formatChatMessage(resourceId: string, authorId: string, message: string): Comment;
}
