<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Remove Event From Tracking</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-template appComponentPortal></ng-template>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="modal.dismiss()"
    class="btn btn-outline-danger"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-danger"
    (click)="removeEvent(gglEventCardInfo)"
  >
    <span> Yes, remove this event from tracking </span>
  </button>
</div>
