import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { APISuccessMessage } from "@apptypes/api.types";
import { ChatModerateActions } from "@apptypes/chat.types";

import { ChatroomBanAction, ModerateMessageAction } from "./moderation.api.types";

/**
 * An abstract class to handle moderation actions for chatrooms. Since chatrooms
 * are polymorphic we need to inject the proper service to handle individual operations
 * for each chatroom.
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export abstract class ModerationService {
  constructor(
    protected _http: HttpClient
  ) {}

  /**
   * Ban a user from a given chatroom
   *
   * @param resourceID
   * @param userID
   */
  public abstract banUser(resourceID: string, userID: string): Observable<APISuccessMessage>;

  /**
   * Hide a chat message from the chat
   *
   * @param resourceID
   * @param chatMessageID
   */
  public abstract hideChatMessage(resourceID: string, chatMessageID: string): Observable<APISuccessMessage>;

  /**
   * Unhide a chat message from the chat
   *
   * @param resourceID
   * @param chatMessageID
   */
  public abstract unhideChatMessage(resourceID: string, chatMessageID: string): Observable<APISuccessMessage>;

  /**
   * Create the moderate message action payload
   *
   * @param chat_message_id
   * @param action
   */
  protected abstract _formatModerateMessageAction(chat_message_id: string, action: ChatModerateActions): ModerateMessageAction;

  /**
   * Create the ChatroomBanAction from the userID for the banUser method.
   *
   * @param userID
   * @returns the formatted ChatroomBanAction
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected _formatChatroomBanAction(userID: string): ChatroomBanAction {
    return {
      chatroom_ban: {
        user_id: userID
      }
    };
  }
}
