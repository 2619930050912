<div class="alert {{ messageBackgroundColor }}">
  <app-chat-message-actions-dropdown
    [chatMessage]="message"
    [resourceID]="chatroomData.id"
  >
  </app-chat-message-actions-dropdown>
  <div class="c_chatContainer__user">
    {{ message.username }}
    <{{ teamTitle }}> ({{ postedAt }})
  </div>
  <div class="c_chatContainer__message" *ngIf="!message.isHidden">
    {{ message.message }}
  </div>
  <div
    class="c_chatContainer__message c_chatContainer__hiddenMessage"
    *ngIf="message.isHidden"
  >
    This message has been hidden.
  </div>
</div>
