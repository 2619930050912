<div ngbDropdown class="d-inline-block color_picker" [autoClose]="false">
  <button class="btn btn-warning" id="dropdownColorSelect" ngbDropdownToggle>
    Set Color
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownColorSelect">
    <div ngbDropdownItem>
      <app-event-color-form-select
        [formControl]="colorFormControl"
      ></app-event-color-form-select>
    </div>
    <button (click)="patchBootstrapColor(colors.WHITE)" ngbDropdownItem>
      White
    </button>
    <button
      (click)="patchBootstrapColor(colors.YELLOW)"
      class="btn btn-warning"
      ngbDropdownItem
    >
      Yellow
    </button>
    <button
      (click)="patchBootstrapColor(colors.RED)"
      class="btn btn-danger"
      ngbDropdownItem
    >
      Red
    </button>
    <button
      (click)="patchBootstrapColor(colors.GREEN)"
      class="btn btn-success"
      ngbDropdownItem
    >
      Green
    </button>
    <button
      (click)="patchBootstrapColor(colors.BLUE)"
      class="btn btn-info"
      ngbDropdownItem
    >
      Blue
    </button>
  </div>
</div>
