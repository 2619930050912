<form
  [formGroup]="gamesListForm"
  class="c_trackGameForm"
  *ngIf="showForm; else showPrompt"
>
  <div class="form-group">
    <label for="tournamentId">Tournament ID #</label>
    <div class="input-group">
      <input
        id="tournamentId"
        type="number"
        min="0"
        formControlName="tournamentId"
      />
    </div>
    <label for="tournamentRound">Tournament Round #</label>
    <div class="input-group">
      <input
        id="tournamentRound"
        type="number"
        min="0"
        formControlName="tournamentRound"
      />
    </div>
    <div class="form-group">
      <label class="mt-2" for="colorSelect">Choose the Card Color: </label>
      <app-event-color-custom-select-with-defaults
        formControlName="preloadedColor"
      ></app-event-color-custom-select-with-defaults>
    </div>
    <div class="text-danger" *ngIf="hasError">
      {{ errorMsg }}
    </div>
  </div>
</form>
<ng-template #showPrompt>
  <ng-container *ngIf="importInfo.numberOfGames > 0; else noMatches">
    <p class="text-success">You have successfully imported matches</p>
    <dl>
      <dt>League Name</dt>
      <dd>{{ importInfo.leagueName }}</dd>
      <dt>Round Number</dt>
      <dd>Round {{ importInfo.round }}</dd>
      <dt>Matches Imported</dt>
      <dd>{{ importInfo.numberOfGames }} Matches</dd>
    </dl>
  </ng-container>

  <ng-template #noMatches>
    <p class="text-danger">
      There were no matches to import from that tournament.
    </p>
    <p>
      Please generate matches for the tournament or add teams to the tournament.
    </p>
  </ng-template>
  <div class="confirmButtons">
    <button type="button" (click)="addMoreGames()" class="btn btn-primary">
      Add More Games
    </button>
    <button type="button" (click)="finishAddingGames()" class="btn btn-warning">
      Finish Adding Games
    </button>
  </div>
</ng-template>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="saveEvents(gamesListForm)"
    [disabled]="isLoading"
  >
    <span *ngIf="!isLoading; else spinner"> Save Match List </span>
    <ng-template #spinner>
      <app-lds-spinner></app-lds-spinner>
    </ng-template>
  </button>
</div>
