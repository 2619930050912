import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { CacheService } from "@services/cache/cache.service";
import { DedupeService } from "@services/dedupe/dedupe.service";

/**
 * A service to maintain the state of the event cards
 */
@Injectable({
  providedIn: "root"
})
export class EventCardStoreService {
  public activeEventCard$: Observable<GGLEventCardInfo | null>;
  public eventCards$: Observable<GGLEventCardInfo[]>;

  private _activeEventCard: BehaviorSubject<GGLEventCardInfo | null> = new BehaviorSubject<GGLEventCardInfo | null>(null);
  private _eventCards: BehaviorSubject<GGLEventCardInfo[]>;

  constructor(
    private _cacheService: CacheService,
    private _dedupeService: DedupeService
  ) {
    const eventCards = this._cacheService.initializeGamesList();
    this._eventCards = new BehaviorSubject(eventCards);

    this.activeEventCard$ = this._activeEventCard.asObservable();
    this.eventCards$ = this._eventCards.asObservable();
  }

  /**
   * Compares the ID and type of the event cards. We cannot compare just the IDs because not
   * all IDs are UUIDs
   *
   * @param eventCardA
   * @param eventCardB
   * @returns
   */
  public static areEventCardsLooselyEqual = (eventCardA: GGLEventCardInfo, eventCardB: GGLEventCardInfo): boolean => (
    eventCardA.id === eventCardB.id && eventCardA.type === eventCardB.type
  );

  /**
   * Choose which event to load into the event detail manager
   *
   * @param eventCard
   */
  public setActiveEventCard(eventCard: GGLEventCardInfo): void {
    this._activeEventCard.next(eventCard);
  }

  /**
   * Unload the active event card in the event detail manager
   */
  public clearActiveEventCard(): void {
    this._activeEventCard.next(null);
  }

  /**
   * Add a new event card to the event card list.
   *
   * @param eventCard
   */
  public addEventCard(eventCard: GGLEventCardInfo): void {
    const newEventCards = this._dedupeService.deduplicateEventCards([eventCard, ...this._eventCards.getValue()]);
    this._eventCards.next(newEventCards);
    this._cacheService.cacheGamelist(newEventCards);
  }

  /**
   * Add a new set of event cards to the event card list
   *
   * @param eventCards
   */
  public addEventCards(eventCards: GGLEventCardInfo[] = []): void {
    const newEventCards = this._dedupeService.deduplicateEventCards([...eventCards, ...this._eventCards.getValue()]);
    this._eventCards.next(newEventCards);
    this._cacheService.cacheGamelist(newEventCards);
  }

  /**
   * Remove the event card from the event card list
   *
   * @param removedEventCard
   */
  public removeEventCard(removedEventCard: GGLEventCardInfo): void {
    const initialEventCards = [...this._eventCards.getValue()];
    const filteredEventCards = initialEventCards.filter(
      (eventCard) => !EventCardStoreService.areEventCardsLooselyEqual(eventCard, removedEventCard)
    );

    this._eventCards.next(filteredEventCards);
    this._cacheService.cacheGamelist(filteredEventCards);
  }

  /**
   * Remove the set of event cards from the event card list
   *
   * @param removedEventCard
   */
  public removeEventCards(eventCardsToRemove: GGLEventCardInfo[] = []): void {
    const isEventCardInArray = (eventCardToFind: GGLEventCardInfo, eventCards: GGLEventCardInfo[]): boolean => !!(
      eventCards.find((eventCard) => EventCardStoreService.areEventCardsLooselyEqual(eventCard, eventCardToFind))
    );

    const initialEventCards = [...this._eventCards.getValue()];
    const filteredEventCards = initialEventCards.filter((eventCard) => !isEventCardInArray(eventCard, eventCardsToRemove));

    this._eventCards.next(filteredEventCards);
    this._cacheService.cacheGamelist(filteredEventCards);
  }

  /**
   * Clear all the event cards from the store
   */
  public clearEventCards(): void {
    this._eventCards.next([]);
    this._cacheService.cacheGamelist([]);
  }
}
