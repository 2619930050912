import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { ISOString } from "@apptypes/aliases.types";

export interface QueueCheckedInTeam {
  id: string;
  checkedIn: boolean;
  checkedInAt: ISOString | null;
  title: string;
  userID: string | null;
}

@Component({
  selector: "app-queue-team-list",
  templateUrl: "./queue-team-list.component.html",
  styleUrls: ["./queue-team-list.component.scss"]
})
export class QueueTeamListComponent implements OnInit, OnChanges {
  @Input() public teams: QueueCheckedInTeam[] = [];
  public checkedInTeams: QueueCheckedInTeam[] = [];
  public notCheckedInTeams: QueueCheckedInTeam[] = [];

  public hasTeamListExpanded = true;

  public ngOnInit(): void {
    this._setAndSortTeams(this.teams);
  }

  public ngOnChanges({ teams: teamsChange }: SimpleChanges): void {
    if(teamsChange && !teamsChange.isFirstChange()) {
      this._setAndSortTeams(teamsChange.currentValue as QueueCheckedInTeam[]);
    }
  }

  private _setAndSortTeams(teams: QueueCheckedInTeam[]): void {
    this.checkedInTeams = teams.filter((team) => team.checkedIn).sort(this._sortTeamsFn);
    this.notCheckedInTeams = teams.filter((team) => !team.checkedIn).sort(this._sortTeamsFn);
  }

  private _sortTeamsFn = (
    { title: titleA }: QueueCheckedInTeam,
    { title: titleB }: QueueCheckedInTeam
  ): 1 | 0 | -1 => {
    const titleANorm = titleA.toLocaleLowerCase();
    const titleBNorm = titleB.toLocaleLowerCase();
    if(titleANorm < titleBNorm) {
      return -1;
    }

    if(titleANorm > titleBNorm){
      return 1;
    }

    return 0;
  };
}
