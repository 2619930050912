import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { APISuccessMessage } from "@apptypes/api.types";
import { basicAuthHeader } from "@utils/auth-header.util";
import { environment } from "@environments/environment";
import { ChatModerateActions } from "@apptypes/chat.types";

import { ModerationService } from "../moderation.abstract.service";
import { SeriesMatchupModerateMessageAction } from "../moderation.api.types";

@Injectable()
export class SeriesMatchupModerationService extends ModerationService {
  public banUser(seriesMatchupID: string, userID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/${seriesMatchupID}/chatroom_bans`;
    const body = this._formatChatroomBanAction(userID);
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public hideChatMessage(seriesMatchupID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/${seriesMatchupID}/moderate_chat_message`;
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.HIDE);
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  public unhideChatMessage(seriesMatchupID: string, chatMessageID: string): Observable<APISuccessMessage> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/${seriesMatchupID}/moderate_chat_message`;
    const body = this._formatModerateMessageAction(chatMessageID, ChatModerateActions.UNHIDE);
    const options = {
      headers: basicAuthHeader(),
    };

    return this._http.post<APISuccessMessage>(url, body, options);
  }

  protected _formatModerateMessageAction(chat_message_id: string, action: ChatModerateActions): SeriesMatchupModerateMessageAction {
    return {
      series_matchup: {
        chat_message_id,
        action,
      },
    };
  }
}
