<form [formGroup]="leagueForm" *ngIf="showForm; else showPrompt">
  <div class="form-group">
    <label for="leagueId"> QuickPlayEvent ID </label>
    <div class="input-group">
      <input id="leagueId" type="text" formControlName="leagueId" />
    </div>
    <p>
      <small
        >You cannot add a QuickPlayEvent until it has been created. You can no
        longer add by LeagueID.</small
      >
    </p>
    <div class="form-group">
      <label class="mt-2" for="colorSelect">Choose the Card Color: </label>
      <app-event-color-custom-select-with-defaults
        formControlName="preloadedColor"
      ></app-event-color-custom-select-with-defaults>
    </div>
    <p class="text-danger" *ngIf="hasError">
      {{ errorMsg }}
    </p>
  </div>
</form>

<ng-template #showPrompt>
  <p class="text-success">You have successfully the quickplay league.</p>
  <dl>
    <dt>League Name</dt>
    <dd>{{ importInfo.leagueName }}</dd>
  </dl>
  <div class="confirmButtons">
    <button type="button" (click)="addMoreGames()" class="btn btn-primary">
      Add More Games
    </button>
    <button type="button" (click)="finishAddingGames()" class="btn btn-warning">
      Finish Adding Games
    </button>
  </div>
</ng-template>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="saveEvents(leagueForm)"
    [disabled]="isLoading"
  >
    <span *ngIf="!isLoading; else spinner"> Save Quickplay League </span>
    <ng-template #spinner>
      <app-lds-spinner></app-lds-spinner>
    </ng-template>
  </button>
</div>
