<div *ngIf="chatLoadError" class="text-danger font-weight-bold">
  ERROR LOADING CHAT. CHAT MAY NOT BE AVAILABLE FOR THIS GAME YET
</div>
<div
  class="c_chatBlock"
  *ngIf="
    !chatLoadError && (chatMessages$ | async) as chatMessages;
    else chatLoad
  "
>
  <h4>Game Chat</h4>
  <div class="c_chatStream">
    <app-chat-stream
      [messages]="chatMessages"
      [chatroomData]="chatroomData"
    ></app-chat-stream>
  </div>
  <app-new-message-box [chatroomData]="chatroomData"></app-new-message-box>
</div>
<ng-template #chatLoad>
  <div class="lds-dual-ring"></div>
</ng-template>
