import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ComponentFactoryResolver,
  ComponentFactory,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { ColorService } from "@services/color/color.service";
import { GGLEventConfig } from "@services/cache/cache.service";
import { BOOTSTRAP_HEXES, COLOR_HEXES } from "@services/color/colors.constants";

import { ComponentPortalDirective } from "src/app/directives/view-directives/component-portal.directive";
import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";
import {
  BattleRoyaleEventCardContentComponent,
  EliminationEventCardContentComponent,
  GameCardContentComponents,
  QueueEventCardContentComponent,
  QueueMatchEventCardContentComponent,
  QuickplayEventCardContentComponent,
  RoundRobinEventCardContentComponent
} from "../ggl-event-card-content";
import { EventColors } from "src/app/enums/EventColors.enum";

@Component({
  selector: "app-ggl-event-card",
  templateUrl: "./ggl-event-card.component.html",
  styleUrls: ["./ggl-event-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GGLEventCardComponent implements OnChanges, OnInit {
  @Input() public gglEventCardInfo!: GGLEventCardInfo;
  @Input() public eventConfig: GGLEventConfig | false = false;
  @Input() public isInRemovalMode = false;
  @Output() public addEventToMassRemoval: EventEmitter<GGLEventCardInfo> = new EventEmitter<GGLEventCardInfo>();
  // eslint-disable-next-line object-curly-newline
  @ViewChild(ComponentPortalDirective, { static: true }) private _cardContentPortal!: ComponentPortalDirective;

  public isActive$: Observable<boolean> = of(false);
  public isMarkedForRemoval = false;
  public MatchCardTypes = GGLEventCardTypes;

  public hasErrorResolvingComponent = false;

  public fontColor: string = COLOR_HEXES.BLACK;
  public backgroundColor: string = COLOR_HEXES.WHITE;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _eventCardService: EventCardStoreService,
    private _colorService: ColorService
  ) {}

  public ngOnInit(): void {
    this._updateBackgroundAndFont();

    this.isActive$ = this._eventCardService.activeEventCard$.pipe(
      map((activeEvent) => {
        if(!activeEvent){
          return false;
        }
        return EventCardStoreService.areEventCardsLooselyEqual(activeEvent, this.gglEventCardInfo);
      })
    );

    this._cardContentPortal.viewContainerRef.clear();

    const contentFactory = this._getComponentFactory(this.gglEventCardInfo.type);

    if(contentFactory) {
      const componentRef = this._cardContentPortal.viewContainerRef.createComponent(contentFactory);
      componentRef.instance.eventCardData = this.gglEventCardInfo;
      return;
    }

    this.hasErrorResolvingComponent = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isInRemovalMode?.currentValue === false) {
      this.isMarkedForRemoval = false;
    }

    if(changes?.eventConfig){
      this._updateBackgroundAndFont();
    }
  }

  public setActiveEvent(eventCard: GGLEventCardInfo): void {
    this._eventCardService.setActiveEventCard(eventCard);
  }

  public removeEventByMassRemoval(gglEventCardInfo: GGLEventCardInfo): void {
    this.isMarkedForRemoval = !this.isMarkedForRemoval && this.isInRemovalMode;
    if (this.isInRemovalMode) {
      this.addEventToMassRemoval.emit(gglEventCardInfo);
    }
  }

  public get isSelectedForRemoval(): boolean {
    return this.isMarkedForRemoval && this.isInRemovalMode;
  }

  private _updateBackgroundAndFont(): void {
    if(this.eventConfig){
      //Supporting legacy event color system
      const eventColor: EventColors = this.eventConfig?.color as EventColors ?? EventColors.WHITE;
      const backgroundHexColor = !!BOOTSTRAP_HEXES[eventColor] ? BOOTSTRAP_HEXES[eventColor] : eventColor;
      this.backgroundColor = backgroundHexColor;
      this.fontColor = this._colorService.getFontColorFromBackgroundColor(backgroundHexColor);
    }
  }

  private _getComponentFactory(matchCardType: GGLEventCardTypes): ComponentFactory<GameCardContentComponents> | undefined {
    switch(matchCardType) {
      case GGLEventCardTypes.ELIMINATION:
        return this._componentFactoryResolver.resolveComponentFactory(EliminationEventCardContentComponent);
      case GGLEventCardTypes.BATTLE_ROYALE:
        return this._componentFactoryResolver.resolveComponentFactory(BattleRoyaleEventCardContentComponent);
      case GGLEventCardTypes.QUICKPLAY:
        return this._componentFactoryResolver.resolveComponentFactory(QuickplayEventCardContentComponent);
      case GGLEventCardTypes.ROUND_ROBIN:
        return this._componentFactoryResolver.resolveComponentFactory(RoundRobinEventCardContentComponent);
      case GGLEventCardTypes.QUEUE:
        return this._componentFactoryResolver.resolveComponentFactory(QueueEventCardContentComponent);
      case GGLEventCardTypes.QUEUE_MATCH:
        return this._componentFactoryResolver.resolveComponentFactory(QueueMatchEventCardContentComponent);
      default:
        return undefined;
    }
  }
}
