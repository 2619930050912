import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from "@angular/core";

import { RoundRobinEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-remove-round-robin-event",
  templateUrl: "./remove-round-robin-event.component.html",
  styleUrls: ["./remove-round-robin-event.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveRoundRobinEventComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: RoundRobinEventCardInfo;
}
