import {
  Component,
  forwardRef,
  OnDestroy
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from "@angular/forms";
import { Subscription } from "rxjs";

import { UnknownFn } from "@apptypes/form.types";
import { BOOTSTRAP_HEXES } from "@services/color/colors.constants";
import { Nullish } from "@apptypes/nullish.types";

import { EventColors } from "src/app/enums/EventColors.enum";

@Component({
  selector: "app-event-color-custom-select-with-defaults",
  templateUrl: "./event-color-custom-select-with-defaults.component.html",
  styleUrls: ["./event-color-custom-select-with-defaults.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EventColorCustomSelectWithDefaultsComponent)
    }
  ]
})
export class EventColorCustomSelectWithDefaultsComponent implements ControlValueAccessor, OnDestroy {
  public usingCustomSelect = false;
  public defaultColorsFormControl = new FormControl(BOOTSTRAP_HEXES.white);
  public customColorsFormControl = new FormControl(BOOTSTRAP_HEXES.white);

  public bootstrapHexes = BOOTSTRAP_HEXES;
  public eventColors = EventColors;

  private _onChangeSub: Subscription | null = null;

  public ngOnDestroy(): void {
    this._clearSubscription();
  }

  public changeCustomSelect(): void {
    this._switchControls();
    this.customColorsFormControl.reset(BOOTSTRAP_HEXES.white);
    this.defaultColorsFormControl.reset(BOOTSTRAP_HEXES.white);
  }

  public writeValue(value: Nullish<string>): void {
    value = !!value ? value : BOOTSTRAP_HEXES.white;
    this.usingCustomSelect = !Object.values(BOOTSTRAP_HEXES).includes(value);

    this._switchControls();

    if(!this.usingCustomSelect){
      this.defaultColorsFormControl.patchValue(value);
      this.customColorsFormControl.reset(BOOTSTRAP_HEXES.white);
      return;
    }

    this.defaultColorsFormControl.reset(BOOTSTRAP_HEXES.white);
    this.customColorsFormControl.patchValue(value);
  }

  public registerOnChange(fn: UnknownFn): void {
    this._onChangeFn = fn;
    return this._switchControls();
  }

  public registerOnTouched(fn: UnknownFn): void {
    this._onTouchFn = fn;
  }

  private _switchControls(): void {
    return this.usingCustomSelect ?
      this._transferOnChangeFn(this.customColorsFormControl) :
      this._transferOnChangeFn(this.defaultColorsFormControl);
  }

  private _onTouchFn: UnknownFn = () => {};
  private _onChangeFn: UnknownFn = () => {};

  private _transferOnChangeFn(formControl: FormControl): void {
    this._clearSubscription();
    this._onChangeSub = formControl.valueChanges.subscribe(this._onChangeFn);
  }

  private _clearSubscription(): void {
    this._onChangeSub?.unsubscribe();
  }
}
