import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";

import { ComponentPortalDirective } from "src/app/directives/view-directives/component-portal.directive";
import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";
import { GGLRemoveEventCardContentComponents } from "../ggl-remove-event-card-content-components.type";
import { RemoveBattleRoyaleEventComponent } from "../remove-battle-royale-event/remove-battle-royale-event.component";
import { RemoveEliminationEventComponent } from "../remove-elimination-event/remove-elimination-event.component";
import { RemoveQueueEventComponent } from "../remove-queue-event/remove-queue-event.component";
import { RemoveQueueMatchEventComponent } from "../remove-queue-match-event/remove-queue-match-event.component";
import { RemoveQuickplayEventComponent } from "../remove-quickplay-event/remove-quickplay-event.component";
import { RemoveRoundRobinEventComponent } from "../remove-round-robin-event/remove-round-robin-event.component";

@Component({
  selector: "app-remove-event-modal-content",
  templateUrl: "./remove-event-modal-content.component.html",
  styleUrls: ["./remove-event-modal-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveEventModalContentComponent implements OnInit {
  @Input() public gglEventCardInfo!: GGLEventCardInfo;
  // eslint-disable-next-line object-curly-newline
  @ViewChild(ComponentPortalDirective, { static: true }) private _portal: ComponentPortalDirective | undefined = undefined;

  constructor(
    public modal: NgbActiveModal,
    private _eventCardService: EventCardStoreService,
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  public ngOnInit(): void {
    if(this._portal){
      this._portal.viewContainerRef.clear();

      const contentFactory = this._getComponentFactory(this.gglEventCardInfo.type);

      if(contentFactory) {
        const componentRef = this._portal.viewContainerRef.createComponent(contentFactory);
        componentRef.instance.eventCardData = this.gglEventCardInfo;
        return;
      }
    }
  }

  public removeEvent(event: GGLEventCardInfo): void {
    this._eventCardService.removeEventCard(event);
    this.modal.close();
  }

  private _getComponentFactory(eventType: GGLEventCardTypes): ComponentFactory<GGLRemoveEventCardContentComponents> | undefined {
    switch(eventType) {
      case GGLEventCardTypes.ELIMINATION:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveEliminationEventComponent);
      case GGLEventCardTypes.BATTLE_ROYALE:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveBattleRoyaleEventComponent);
      case GGLEventCardTypes.QUICKPLAY:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveQuickplayEventComponent);
      case GGLEventCardTypes.ROUND_ROBIN:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveRoundRobinEventComponent);
      case GGLEventCardTypes.QUEUE:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveQueueEventComponent);
      case GGLEventCardTypes.QUEUE_MATCH:
        return this._componentFactoryResolver.resolveComponentFactory(RemoveQueueMatchEventComponent);
      default:
        return undefined;
    }
  }
}
