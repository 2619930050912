<ng-container *ngIf="filteredEventCards$ | async as eventCards">
  <div class="c_eventList bg-light">
    <form [formGroup]="filterForm" class="c_eventListInputs">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default">
            Search/Filter
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          formControlName="teamTitleOrIdFilter"
        />
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default">
            League
          </span>
        </div>
        <select class="form-control" formControlName="leagueFilterTerm">
          <option [ngValue]="null" selected>All Leagues</option>
          <option
            *ngFor="let league of leagueList$ | async"
            [ngValue]="league.id"
          >
            {{ league.title }}
          </option>
        </select>
      </div>
      <div>
        <app-event-import-modal></app-event-import-modal>
      </div>
      <ng-container *ngIf="eventCards.length > 0">
        <p
          class="c_eventList__massRemovalButton btn btn-danger"
          (click)="startMassRemoval()"
          *ngIf="!isInMassRemoval; else removalOptions"
        >
          🗑
        </p>
        <ng-template #removalOptions>
          <p
            class="c_eventList__massRemovalButton btn btn-warning"
            (click)="cancelRemoval()"
          >
            ✖️
          </p>
          <p
            class="c_eventList__massRemovalButton btn btn-info"
            (click)="submitRemoval()"
          >
            ✅
          </p>
        </ng-template>
      </ng-container>
    </form>
    <div class="c_eventlistGrid__container">
      <ng-container
        *ngIf="eventCards && eventCards.length > 0; else noGameList"
      >
        <div class="c_eventListGrid">
          <ng-container *ngFor="let eventCard of eventCards">
            <app-ggl-event-card
              [gglEventCardInfo]="eventCard"
              [eventConfig]="getMatchConfig(eventCard, eventConfigMap$ | async)"
              [isInRemovalMode]="isInMassRemoval"
              (addEventToMassRemoval)="removeMatch($event)"
            >
            </app-ggl-event-card>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noGameList>
        <em>Click "Add Games" to start adding games to track</em>
      </ng-template>
    </div>
  </div>
</ng-container>
