import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";

import {
  GGLEventDetails,
  GGLEventDetailsComponents,
  GGLEventTypes,
} from "@apptypes/card-details.types";
import { Nullish } from "@apptypes/nullish.types";

import { ComponentPortalDirective } from "src/app/directives/view-directives/component-portal.directive";
import { MatchDetailComponent, QueueDetailComponent } from "../event-detail-content";
import { QuickPlayEventDetailComponent } from "../event-detail-content/quick-play-event-detail/quick-play-event-detail.component";

@Component({
  selector: "app-ggl-event-detail-manager",
  templateUrl: "./ggl-event-detail-manager.component.html",
  styleUrls: ["./ggl-event-detail-manager.component.scss"]
})
export class GGLEventDetailManagerComponent implements OnInit {
  @Input() public eventDetails: Nullish<GGLEventDetails> = null;
  // eslint-disable-next-line object-curly-newline
  @ViewChild(ComponentPortalDirective, { static: true }) private _componentPortal: ComponentPortalDirective | undefined;
  public hasErrorLoadingComponent = false;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  public ngOnInit(): void {
    if(this.eventDetails) {
      this._loadComponentIntoPortal(this.eventDetails);
    }
  }

  private _loadComponentIntoPortal(eventDetails: GGLEventDetails): void {
    const componentFactory = this._getComponentFactory(eventDetails.type);

    if(componentFactory && this._componentPortal) {
      const componentRef = this._componentPortal.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.GGLEventDetailsData = eventDetails;
      return;
    }

    this.hasErrorLoadingComponent = true;
  }

  private _getComponentFactory(eventDetailsType: GGLEventTypes): ComponentFactory<GGLEventDetailsComponents> | undefined {
    switch(eventDetailsType) {
      case GGLEventTypes.QUICKPLAY_EVENT:
        return this._componentFactoryResolver.resolveComponentFactory(QuickPlayEventDetailComponent);
      case GGLEventTypes.SERIES_MATCHUP:
        return this._componentFactoryResolver.resolveComponentFactory(MatchDetailComponent);
      case GGLEventTypes.QUEUE:
        return this._componentFactoryResolver.resolveComponentFactory(QueueDetailComponent);
      default:
        return undefined;
    }
  }
}
