<div class="o_loginPage d-flex justify-content-center align-items-center h-100">
  <div class="c_login card p-5 w-25">
    <h2 class="card-title">Login</h2>
    <form [formGroup]="loginForm" (submit)="loginUser(loginForm)">
      <p *ngIf="hasError" class="text-danger">
        <strong>There was an issue logging in, please try again</strong>
      </p>
      <div class="form-group">
        <label for="email">Email address</label>
        <input
          type="email"
          class="form-control"
          formControlName="email"
          id="email"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          formControlName="password"
          id="password"
        />
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="formIsLoading">
        <span *ngIf="!formIsLoading; else spinner"> Submit </span>
        <ng-template #spinner>
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-template>
      </button>
    </form>
  </div>
</div>
