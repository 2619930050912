<div class="d-flex flex-column justify-content-between">
  <div>
    <h5 class="card-title">{{ eventCardData.tournamentTitle }} Queue</h5>
    <h6 class="card-subtitle mb-2">
      {{ eventCardData.leagueName }}
    </h6>
  </div>
  <p class="mb-1">{{ eventCardData.esport | enumTitle }}</p>
  <div class="card-footer pl-0 pr-0">
    <small>{{ eventCardData.queueStartTime | date: "short" }}</small>
  </div>
</div>
