import { Provider } from "@angular/core";

import { QuickPlayEventModerationService } from "@services/chat/moderation";

import { MODERATION_SERVICE } from "./moderation-service.token";

export const QuickPlayEventModerationProvider: Provider = {
  provide: MODERATION_SERVICE,
  useClass: QuickPlayEventModerationService,
};
