import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  combineLatest,
  Observable,
  of,
  Subscription
} from "rxjs";
import {
  filter,
  map,
  publishReplay,
  refCount,
  startWith,
} from "rxjs/operators";
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { addMinutes, } from "date-fns";

import {
  FireQueueCheckedInTeam,
  QueueDetail,
  QueueSession
} from "@apptypes/queue.types";
import { QueueService } from "@services/queue/queue.service";
import { EventCardTeam, QueueMatchEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EnumTitlePipe } from "@pipes/enum-title/enum-title.pipe";
import { ISOString } from "@apptypes/aliases.types";
import { TournamentQueueStatus } from "@apiEnums/tournaments/tournament_queue_status.enum";
import { environment } from "@environments/environment";
import { QueueImportMatchService } from "@services/queue-import-match/queue-import-match.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { Nullish } from "@apptypes/nullish.types";
import { TournamentChatroomProvider } from "@providers/chatroom-collection";
import { TournamentCommentServiceProvider } from "@providers/comment-service";
import { TournamentModerationProvider } from "@providers/moderation-service";

import { GGLEventDetailsComponent } from "src/app/interfaces/components/ggl-event-details.component.interface";
import { QueueCheckedInTeam } from "../../queue-team-list/queue-team-list.component";
import { CloseQueueConfirmationModalComponent } from "../../close-queue-confirmation-modal/close-queue-confirmation-modal.component";
import { QUEUE_START_TIME } from "src/app/enums/application-globals/queue-start-time.const";

@Component({
  selector: "app-queue-detail",
  templateUrl: "./queue-detail.component.html",
  styleUrls: ["./queue-detail.component.scss"],
  //When the component is injected into the portal it loses the module context for some reason
  providers: [
    EnumTitlePipe,
    NgbDropdown,
    TournamentCommentServiceProvider,
    TournamentChatroomProvider,
    TournamentModerationProvider,
  ]
})
export class QueueDetailComponent implements GGLEventDetailsComponent, OnInit, OnDestroy {
  public GGLEventDetailsData!: QueueDetail;
  public queueTeams$: Observable<QueueCheckedInTeam[]> = of([]);
  public queueSession$: Observable<QueueSession | undefined> = of(undefined);
  public queueAdminRoute = "";
  public queueAppRoute = "";
  public leagueRoute = "";
  public isAutoImportingMatches = new FormControl(false);

  public hasTeamListExpanded = true;
  public closeTime: ISOString = "" as ISOString;
  public hasTimeExpired = false;
  public queueStatuses = TournamentQueueStatus;

  private _importSubscription: Subscription | null = null;

  constructor(
    private _queueService: QueueService,
    private _modal: NgbModal,
    private _queueImportService: QueueImportMatchService,
    private _eventCardStoreService: EventCardStoreService
  ) {}

  public ngOnInit(): void {
    this.isAutoImportingMatches.setValue(this.queue.autoImportMatches);

    this.closeTime = addMinutes(new Date(this.queue.startTime), QUEUE_START_TIME).toISOString() as ISOString;

    this.queueAdminRoute = `${environment.apiBase}/admin/tournament_queue_sessions/${this.queue.id}`;
    this.queueAppRoute = `${environment.appBase}/leagues/${this.queue.league.id}/checkin/${this.queue.id}`;
    this.leagueRoute = `${environment.appBase}/leagues/${this.queue.league.id}`;

    //SEE: https://medium.com/@mikesnare/angular-async-pipes-beware-the-share-bcc9c1cd849d
    //share as an operator is not playing nice with the async pipes
    this.queueSession$ = this._queueService.getQueueSessionStream(this.queue.tournamentQueueSessionStream.streamReferenceID).pipe(
      filter(queue => !!queue),
      publishReplay(1),
      refCount(),
    );

    this.queueTeams$ = this.queueSession$.pipe(
      map((queue) => queue?.checkedInTeams ?? []),
      map((teams) => this._combineTeams(this._tournamentTeams, teams)),
    );

    const isAutoImportingChanges$ = this.isAutoImportingMatches.valueChanges.pipe(
      startWith(this.isAutoImportingMatches.value)
    );

    this._importSubscription = combineLatest([isAutoImportingChanges$, this.queueSession$]).subscribe(
      ([isAutoImportingMatches, queueSession]) => {
        if(isAutoImportingMatches === true && queueSession !== undefined){
          this.loadMatchesFromQueue(queueSession, this.queue);
        }
      });
  }

  public ngOnDestroy(): void {
    if(this._importSubscription){
      this._importSubscription.unsubscribe();
    }
  }

  public closeTimeHandler(): void {
    this.hasTimeExpired = true;
  }

  public openCloseQueueConfirmationModal(): void {
    const modalRef: CloseQueueConfirmationModalComponent = this._modal.open(CloseQueueConfirmationModalComponent).componentInstance;
    modalRef.queue = this.queue;
  }

  public loadMatchesFromQueue(queueSession: Nullish<QueueSession>, queueDetail: QueueDetail): void {
    if(!queueSession){
      return;
    }

    const successFn = (queueMatchCards: QueueMatchEventCardInfo[]): void => {
      this._eventCardStoreService.addEventCards(queueMatchCards);
    };

    const errorFn = (err: any): void => {
      console.error(err);
    };

    this._queueImportService.loadQueueMatches(queueSession, queueDetail).subscribe(successFn, errorFn);
  }

  public get queue(): QueueDetail {
    return this.GGLEventDetailsData;
  }

  private get _tournamentTeams(): EventCardTeam[] {
    return this.queue?.tournament?.activeTeams ?? [];
  }

  private _combineTeams(activeTeams: EventCardTeam[] = [], checkedInTeams: FireQueueCheckedInTeam[] = []): QueueCheckedInTeam[] {
    return activeTeams.map((activeTeam) => {
      const matchedCheckedInTeam = checkedInTeams.find((checkedInTeam) => checkedInTeam.teamID === activeTeam.id);
      const checkedInAt = !!matchedCheckedInTeam ? matchedCheckedInTeam.createdAt : null;
      return {
        id: activeTeam.id,
        title: activeTeam.title,
        checkedIn: !!matchedCheckedInTeam,
        checkedInAt,
        userID: matchedCheckedInTeam?.userID ?? null,
      };
    });
  }
}
