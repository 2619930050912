<aside>
  <header class="modal-header">
    <h4 class="modal-title">Are you sure you want to ban this user?</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>
  <div class="modal-body">
    <ng-container
      *ngIf="banUserStatus === statusTypes.DEFAULT"
      [ngTemplateOutlet]="messageDetails"
    ></ng-container>
    <ng-container
      *ngIf="banUserStatus === statusTypes.ERROR"
      [ngTemplateOutlet]="error"
    ></ng-container>
    <ng-container
      *ngIf="banUserStatus === statusTypes.LOADING"
      [ngTemplateOutlet]="loading"
    ></ng-container>
    <ng-container
      *ngIf="banUserStatus === statusTypes.SUCCESS"
      [ngTemplateOutlet]="success"
    ></ng-container>
  </div>
  <ng-template #messageDetails>
    <dl>
      <dt>User</dt>
      <dd>{{ chatMessage.username }}</dd>
      <dt>Message Contents</dt>
      <dd>{{ chatMessage.message }}</dd>
    </dl>
  </ng-template>
  <ng-template #loading>
    <app-lds-spinner [isOnDarkBackground]="false"></app-lds-spinner>
  </ng-template>
  <ng-template #success> The user was successfully banned. </ng-template>
  <ng-template #error>
    There was an error attempting to ban this user. Please ask the tech team to
    investigate this. This message's id is
    <span class="c_banUserConfirmationModal__id">{{
      chatMessage.documentId || "no ID"
    }}</span
    >, the series matchup ID is
    <span class="c_banUserConfirmationModal__id">{{ resourceID }}</span
    >, and the user is
    <span class="c_banUserConfirmationModal__id"
      >{{ chatMessage.username }} (ID: {{ chatMessage.userId }})
    </span>
  </ng-template>
  <footer class="modal-footer">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="close()"
          >
            Close
          </button>
        </div>
        <div class="col-md-auto" *ngIf="showBanUserButton">
          <button type="button" class="btn btn-success" (click)="banUser()">
            Ban User
          </button>
        </div>
      </div>
    </div>
  </footer>
</aside>
