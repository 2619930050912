import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";

import { environment } from "@environments/environment";
import { CloudTournamentCommentService, RailsTournamentCommentService } from "@services/chat/comment";

import { COMMENT_SERVICE } from "./comment-service.token";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function TournamentCommentFactory(
  http: HttpClient,
): CloudTournamentCommentService | RailsTournamentCommentService {
  return environment.useCloudChats ?
    new CloudTournamentCommentService(http) :
    new RailsTournamentCommentService(http);
};

/**
 * Provides a CommentService for Tournament Chatrooms, will automatically
 * inject the cloud service if available
 */
export const TournamentCommentServiceProvider: Provider = {
  provide: COMMENT_SERVICE,
  useFactory: TournamentCommentFactory,
  deps: [HttpClient],
};
