import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from "@angular/core";
import { EsportName } from "@apptypes/assignment.types";

import {
  EventCardTeam,
  NullEventCardTeam,
  QueueMatchEventCardInfo
} from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-queue-match-event-card-content",
  templateUrl: "./queue-match-event-card-content.component.html",
  styleUrls: ["./queue-match-event-card-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueueMatchEventCardContentComponent implements IGGLEventCardContentComponent, OnInit {
  @Input() public eventCardData!: QueueMatchEventCardInfo;
  public teamOne!: EventCardTeam;
  public teamTwo!: EventCardTeam;
  public roundText = "";

  public ngOnInit(): void {
    this.teamOne = this.eventCardData.teams[0] ?? NullEventCardTeam;
    this.teamTwo = this.eventCardData.teams[1] ?? NullEventCardTeam;
    this.roundText = this.eventCardData.esport === EsportName.SSBB ? this.eventCardData.queueRound : "";
  }
}
