import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { basicAuthHeader } from "src/app/utils/auth-header.util";
import { CacheService } from "../cache/cache.service";

export interface UserLogin {
  email: string;
  password: string;
}

export interface UserLoginToken {
  jwt: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private _http: HttpClient, private _cacheService: CacheService) {}

  public logon(payload: UserLogin): Observable<UserLoginToken> {
    const url = `${environment.apiBase}/api/login.json`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this._http.post<UserLoginToken>(url, {
      api_user: payload
    }, {
      headers
    }).pipe(
      tap((token) => this._cacheService.cacheAuth(token)),
      map((token) => token)
    );
  }

  public logout(): Observable<void> {
    const url = `${environment.apiBase}/api/logout.json`;
    const headers = basicAuthHeader();

    return this._http
      .delete<void>(url, {
      headers
    })
      .pipe(
        tap(() => {
          localStorage.clear();
        })
      );
  }
}
