/* eslint-disable max-len */
//Angular Imports
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

//Imports
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

//Core
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

//Pages
import { LoginPageComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";

//Components
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ImportEventModalComponent } from "./components/event-import-modal/event-import-modal.component";
import { MassMessageModalComponent } from "./components/mass-message-modal/mass-message-modal.component";
import { environment } from "src/environments/environment";
import { RemoveEventModalComponent } from "./components/remove-event-modal/remove-event-modal/remove-event-modal.component";
import { ChatScrollDirective } from "./directives/chat-scroll.directive";
import { NewMessagesBannerComponent } from "./components/new-messages-banner/new-messages-banner.component";
import { GGLEventCardComponent } from "./components/ggl-event-card/ggl-event-card.component";
import { ChatBlockComponent } from "./components/chat-block/chat-block.component";
import { NewMessageBoxComponent } from "./components/new-message-box/new-message-box.component";
import { ChatMessageComponent } from "./components/chat-message/chat-message.component";
import { ChatStreamComponent } from "./components/chat-stream/chat-stream.component";
import { SplitAdminChatBlockComponent } from "./components/split-admin-chat-block/split-admin-chat-block.component";
import { LdsSpinnerComponent } from "./components/lds-spinner/lds-spinner.component";
import { HideChatMessageDropdownItemComponent } from "./components/hide-chat-message-dropdown-item/hide-chat-message-dropdown-item.component";
import { UnhideChatMessageDropdownItemComponent } from "./components/unhide-chat-message-dropdown-item/unhide-chat-message-dropdown-item.component";
import { ChatMessageActionsDropdownComponent } from "./components/chat-message-actions-dropdown/chat-message-actions-dropdown.component";
import { BanUserDropdownItemComponent } from "./components/ban-user-dropdown-item/ban-user-dropdown-item.component";
import { BanUserConfirmationModalComponent } from "./components/ban-user-confirmation-modal/ban-user-confirmation-modal.component";
import {
  BattleRoyaleEventCardContentComponent,
  EliminationEventCardContentComponent,
  RoundRobinEventCardContentComponent,
  QuickplayEventCardContentComponent,
  QueueEventCardContentComponent,
  QueueMatchEventCardContentComponent
} from "./components/ggl-event-card-content";
import {
  QueueImportFormComponent,
  BattleRoyaleImportFormComponent,
  TournamentRoundImportFormComponent,
  QuickplayLeagueImportFormComponent
} from "./components/ggl-event-import-forms";
import { GGLEventCardManagerComponent } from "./components/ggl-event-card-manager/ggl-event-card-manager.component";
import { GGLEventDetailManagerComponent } from "./components/ggl-event-detail-manager/ggl-event-detail-manager.component";
import { DualRingSpinnerComponent } from "./components/dual-ring-spinner/dual-ring-spinner.component";
import { EventColorSelectorComponent } from "./components/event-color-selector/event-color-selector.component";
import { QueueTeamListComponent } from "./components/queue-team-list/queue-team-list.component";
import { TimerComponent } from "./components/timer/timer.component";
import { CloseQueueConfirmationModalComponent } from "./components/close-queue-confirmation-modal/close-queue-confirmation-modal.component";
import { EventColorFormSelectComponent } from "./components/event-color-form-select/event-color-form-select.component";
import {
  MatchDetailComponent,
  QueueDetailComponent,
  QuickPlayEventDetailComponent
} from "./components/event-detail-content";
import { EventColorCustomSelectWithDefaultsComponent } from "./components/event-color-custom-select-with-defaults/event-color-custom-select-with-defaults.component";
import { RemoveQueueEventComponent } from "./components/remove-event-modal/remove-queue-event/remove-queue-event.component";
import { RemoveQuickplayEventComponent } from "./components/remove-event-modal/remove-quickplay-event/remove-quickplay-event.component";
import { RemoveBattleRoyaleEventComponent } from "./components/remove-event-modal/remove-battle-royale-event/remove-battle-royale-event.component";
import { RemoveQueueMatchEventComponent } from "./components/remove-event-modal/remove-queue-match-event/remove-queue-match-event.component";
import { RemoveEliminationEventComponent } from "./components/remove-event-modal/remove-elimination-event/remove-elimination-event.component";
import { RemoveRoundRobinEventComponent } from "./components/remove-event-modal/remove-round-robin-event/remove-round-robin-event.component";
import { RemoveEventModalContentComponent } from "./components/remove-event-modal/remove-event-modal-content/remove-event-modal-content.component";

//Pipes
import { EnumTitlePipe } from "@pipes/enum-title/enum-title.pipe";
import { TypeGuardPipe } from "@pipes/util/type-guard/type-guard.pipe";
import {
  MatchDetailChatroomAdapterPipe,
  QueueDetailChatroomAdapterPipe,
  QuickPlayEventDetailChatroomAdapterPipe
} from "@pipes/data-mapping/chatroom-adapters";

//Directives
import { ComponentPortalDirective } from "./directives/view-directives/component-portal.directive";
import { QuickPlayEventCheckinListComponent } from "./components/quick-play-event-checkin-list/quick-play-event-checkin-list.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ImportEventModalComponent,
    MassMessageModalComponent,
    MatchDetailComponent,
    RemoveEventModalComponent,
    ChatScrollDirective,
    NewMessagesBannerComponent,
    GGLEventCardComponent,
    ChatBlockComponent,
    NewMessageBoxComponent,
    ChatMessageComponent,
    ChatStreamComponent,
    SplitAdminChatBlockComponent,
    TournamentRoundImportFormComponent,
    BattleRoyaleImportFormComponent,
    LdsSpinnerComponent,
    EnumTitlePipe,
    ChatMessageActionsDropdownComponent,
    HideChatMessageDropdownItemComponent,
    UnhideChatMessageDropdownItemComponent,
    MatchDetailChatroomAdapterPipe,
    TypeGuardPipe,
    QuickplayLeagueImportFormComponent,
    BanUserDropdownItemComponent,
    BanUserConfirmationModalComponent,
    BattleRoyaleEventCardContentComponent,
    EliminationEventCardContentComponent,
    RoundRobinEventCardContentComponent,
    QuickplayEventCardContentComponent,
    ComponentPortalDirective,
    QueueImportFormComponent,
    QueueEventCardContentComponent,
    GGLEventCardManagerComponent,
    GGLEventDetailManagerComponent,
    DualRingSpinnerComponent,
    QueueDetailComponent,
    EventColorSelectorComponent,
    QueueTeamListComponent,
    QueueDetailChatroomAdapterPipe,
    TimerComponent,
    CloseQueueConfirmationModalComponent,
    EventColorFormSelectComponent,
    QueueMatchEventCardContentComponent,
    EventColorCustomSelectWithDefaultsComponent,
    RemoveQueueEventComponent,
    RemoveQuickplayEventComponent,
    RemoveBattleRoyaleEventComponent,
    RemoveQueueMatchEventComponent,
    RemoveEliminationEventComponent,
    RemoveRoundRobinEventComponent,
    RemoveEventModalContentComponent,
    QuickPlayEventDetailChatroomAdapterPipe,
    QuickPlayEventDetailComponent,
    QuickPlayEventCheckinListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    // Firebase Import
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
