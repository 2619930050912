import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export const DEFAULT_ERROR = "An error occured, please try again";

export enum EventImportTypes {
  BATTLE_ROYALE = 1,
  ELIMINATION_BRACKET = 2,
  ROUND_ROBIN = 3,
  QUICKPLAY_LEAGUE = 4,
  QUEUE = 5
};

@Component({
  selector: "app-event-import-modal",
  templateUrl: "./event-import-modal.component.html",
  styleUrls: ["./event-import-modal.component.scss"],
})
export class ImportEventModalComponent {
  public readonly eventImportTypes = EventImportTypes;
  public activeTab: EventImportTypes = EventImportTypes.ELIMINATION_BRACKET;
  public closeResult = "";
  public isLoading = false;

  private _disableClose = false;

  constructor(private _modalService: NgbModal) {}

  public open(content: any): void {
    this._modalService.open(
      content,
      {
        beforeDismiss: this._dismissHandler,
        size: "lg"
      }
    );
  }

  private _dismissHandler(): boolean {
    if (this._disableClose) {
      return false;
    }
    return !this.isLoading;
  }
}

// https://staging.ggleagues.com/series_matchup/bd588187-fd89-4f06-9a57-d884e0635e91
// https://staging.ggleagues.com/series_matchup/a524717d-48fd-4991-a0b9-44befede3d96
