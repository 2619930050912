import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  tap,
  map,
  catchError
} from "rxjs/operators";

import { MatchCommenter } from "@apptypes/chat.types";
import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-header.util";

import { UsernameSearchAPIResponse } from "./username.api.types";

const SYSTEM_ID = -999;

@Injectable({
  providedIn: "root"
})
export class UsernameService {
  private _matchCommenterMap: Map<string, string> = new Map<string, string>(); //user-id, username

  constructor(private _http: HttpClient) {
    this._matchCommenterMap.set(SYSTEM_ID.toString(), "GGLeagues Bot");
  }

  public getUsernames(userIds: string[]): Observable<{ users: MatchCommenter[] }> {
    const url = `${environment.apiBase}/api/v1/search/users`;
    let headers = basicAuthHeader();

    headers = headers.append("Page", "1");
    headers = headers.append("Limit", userIds.length.toString());
    let params = new HttpParams();
    userIds.forEach(id => {
      params = params.append("user_ids[]", id);
    });

    return this._http.get<UsernameSearchAPIResponse>(url, {
      headers,
      params,
    }).pipe(
      map(apiResponse => this._mapMatchCommenterFromSearch(apiResponse))
    );
  }

  public getAllMatchCommenters(userIDs: string[]): Observable<MatchCommenter[]> {
    const unknownIds = this._getUnknownUserIds(userIDs);
    const knownMatchCommenters = this._readMatchCommenters(userIDs);

    //Don't send a network request if there's no need.
    if(unknownIds.length === 0) {
      return of(knownMatchCommenters);
    }

    return this.getUsernames(unknownIds).pipe(
      tap(({ users }) => this._setNewMatchCommenters(users)),
      map(({ users }) => [...users, ...knownMatchCommenters]),
      catchError((err) => {
        console.error(err);
        return of([]);
      })
    );
  }

  private _mapMatchCommenterFromSearch(apiResponse: UsernameSearchAPIResponse = {
    data: [],
  }): { users: MatchCommenter[] } {
    return {
      users: apiResponse?.data?.map(apiUser => ({
        id: +apiUser.id,
        inGameName: apiUser.attributes.username,
      })) ?? [],
    };
  }

  private _setNewMatchCommenters(matchCommenters: MatchCommenter[]): void {
    matchCommenters.forEach(({ id, inGameName }) => {
      this._matchCommenterMap.set(id.toString(), inGameName);
    });
  }

  private _readMatchCommenters(userIds: string[]): MatchCommenter[] {
    return userIds.filter(id => this._matchCommenterMap.has(id)).map((id) => ({
      id: +id,
      inGameName: this._matchCommenterMap.get(id) as string, //This will only be included
    }));
  }

  private _getUnknownUserIds(userIds: string[]): string[] {
    return Array.from(new Set(userIds.filter((id) => !this._matchCommenterMap.has(id))));
  }
}
