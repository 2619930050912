import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { basicAuthHeader } from "@utils/auth-header.util";
import { ChatroomBlockData } from "@apptypes/chat.types";
import { environment } from "@environments/environment";

import { CommentService } from "../comment.abstract.service";
import { CloudComment } from "../comment.api.types";

@Injectable({
  providedIn: "root"
})
export class CloudQuickPlayEventCommentService extends CommentService {
  public postComment({ chatroomReferenceId: chatroomId }: ChatroomBlockData, authorId: string, message: string): Observable<any> {
    const url = `${environment.cloudFunctionAPIBase}/manageQuickPlayEventChat`;

    const options = {
      headers: basicAuthHeader()
    };

    const payload = this._formatChatMessage(chatroomId, authorId, message);

    return this._http.post(url, payload, options);
  }

  protected _formatChatMessage(chatroomId: string, userId: string, message: string): CloudComment {
    return {
      message,
      userId,
      chatroomId,
    };
  }
}
