import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener
} from "@angular/core";

@Directive({
  selector: "[appChatScroll]",
})
export class ChatScrollDirective implements AfterViewInit {
  //See: https://pumpingco.de/blog/automatic-scrolling-only-if-a-user-already-scrolled-the-bottom-of-a-page-in-angular/
  public isUserInAutoScrollRange = true;

  constructor(public element: ElementRef) {}

  @HostListener("scroll", ["$event"])
  private _checkScroll(): void {
    this.isUserInAutoScrollRange = this._checkIfUserIsInAutoScrollRange();
  }

  public ngAfterViewInit() {
    this._scrollElementToBottom();
  }

  public scrollToBottom(hardScroll: boolean = false) {
    if (hardScroll) {
      this._scrollElementToBottom();
      return;
    }

    if (this.isUserInAutoScrollRange) {
      this._scrollElementToBottom();
      return;
    }
  }

  private _checkIfUserIsInAutoScrollRange(): boolean {
    const THRESHOLD = 130;
    const position: number = this.element.nativeElement.scrollTop + this.element.nativeElement.offsetHeight;
    const height: number = this.element.nativeElement.scrollHeight;
    return position > height - THRESHOLD;
  }

  private _scrollElementToBottom() {
    //Let the browser update the scrollHeight
    setTimeout(() => {
      this.element.nativeElement.scroll({
        top: this.element.nativeElement.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    });
  }
}
