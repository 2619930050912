import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import {
  APILeagueAdminTeam,
  APILeagueAdminUser,
  APIEventLeague,
  APIQueueTournament,
  APIQueueTournamentChatroom,
  APITournamentQueueSession,
  APITournamentQueueStream,
} from "@services/event-import/event-import.types";
import { basicAuthHeader } from "@utils/auth-header.util";
import {
  QueueLeague,
  QueueDetail,
  QueueTournament,
  QueueTournamentChatroom,
  QueueTournamentStream,
  QueueSession,
  QueueSessionData,
  QueueSessionMatchupMap,
  FireQueueCheckedInTeam,
  QueueTeamUser,
  QueueTeam
} from "@apptypes/queue.types";

import { FirestoreCollections } from "src/app/enums/FirestoreCollections.enum";
import {
  FirestoreQueueCheckinTeamDoc,
  FirestoreQueueSessionDoc,
  FirestoreQueueSessionMatchupMap
} from "./queue.api.types";

@Injectable({
  providedIn: "root"
})
export class QueueService {
  constructor(
    private _http: HttpClient,
    private _fire: AngularFirestore,
  ) { }

  public getQueueDetail(queueSessionId: string): Observable<QueueDetail> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournament_queue_sessions/${queueSessionId}`;
    const options = {
      headers: basicAuthHeader()
    };

    return this._http.get<{ data: APITournamentQueueSession }>(url, options).pipe(
      map(this._mapAPIQueueSession)
    );
  }

  public getQueueSessionStream(queueStreamReferenceID: string): Observable<QueueSession | undefined> {
    const queueDoc = this._fire.collection(FirestoreCollections.TOURNAMENT_QUEUE).doc<FirestoreQueueSessionDoc>(queueStreamReferenceID);

    const queueSessionData: Observable<QueueSessionData> = queueDoc.valueChanges().pipe((
      map((queueSessionDoc: FirestoreQueueSessionDoc | undefined) => {
        if(!queueSessionDoc){
          throw new Error("No queue document found");
        }
        return this._mapFirestoreQueueSessionData(queueSessionDoc);
      })
    ));

    const queueCheckins: Observable<FireQueueCheckedInTeam[]> = queueDoc.collection<FirestoreQueueCheckinTeamDoc>(
      FirestoreCollections.TOURNAMENT_QUEUE_CHECKINS
    ).valueChanges().pipe(
      map((teams = []) => teams.map(this._mapFirestoreQueueCheckin))
    );

    return combineLatest([queueSessionData, queueCheckins]).pipe(
      map(([sessionData, checkins]) => ({
        ...sessionData,
        checkedInTeams: checkins
      }))
    );
  }

  public closeQueue(queueSessionID: string): Observable<any> {
    const url = `${environment.apiBase}/api/v2/league_admin/tournament_queue_sessions/${queueSessionID}/close_session`;

    const options = {
      headers: basicAuthHeader(),
    };

    const body = null;

    return this._http.post(url, body, options);
  }

  private _mapAPIQueueSession = ({ data: apiQueue }: { data: APITournamentQueueSession }): QueueDetail => {
    const mapQueueLeague = (apiLeague: APIEventLeague): QueueLeague => ({
      id: apiLeague.id,
      type: apiLeague.type,
      title: apiLeague.attributes.title,
    });

    const mapQueueTournamentChatroom = (apiTournamentChatroom: APIQueueTournamentChatroom): QueueTournamentChatroom => ({
      id: apiTournamentChatroom.id,
      type: apiTournamentChatroom.type,
      firestoreDocumentID: apiTournamentChatroom.attributes.firestoreDocumentId,
      status: apiTournamentChatroom.attributes.status,
    });

    const mapQueueTeam = (apiLeagueAdminTeam: APILeagueAdminTeam): QueueTeam => ({
      id: apiLeagueAdminTeam.id,
      type: apiLeagueAdminTeam.type,
      title: apiLeagueAdminTeam.attributes.title,
      users: apiLeagueAdminTeam.attributes.users.data.map(mapQueueUser),
    });

    const mapQueueUser = (apiLeagueAdminUser: APILeagueAdminUser): QueueTeamUser => ({
      id: apiLeagueAdminUser.id,
      type: apiLeagueAdminUser.type,
      email: apiLeagueAdminUser.attributes.email,
      username: apiLeagueAdminUser.attributes.username,
    });

    const mapQueueTournament = (apiTournament: APIQueueTournament): QueueTournament => ({
      id: apiTournament.id,
      type: apiTournament.type,
      title: apiTournament.attributes.title,
      tournamentChatroom: mapQueueTournamentChatroom(apiTournament.attributes.tournamentChatroom.data),
      activeTeams: apiTournament.attributes.activeTeams.data.map(mapQueueTeam),
    });

    const mapQueueTournamentStream = (apiTournamentStream: APITournamentQueueStream): QueueTournamentStream => ({
      id: apiTournamentStream.id,
      type: apiTournamentStream.type,
      streamReferenceID: apiTournamentStream.attributes.streamReferenceId
    });

    return {
      id: apiQueue.id,
      type: apiQueue.type,
      esport: apiQueue.attributes.esport,
      startTime: apiQueue.attributes.startTime,
      status: apiQueue.attributes.status,
      league: mapQueueLeague(apiQueue.attributes.league.data),
      tournament: mapQueueTournament(apiQueue.attributes.tournament.data),
      tournamentQueueSessionStream: mapQueueTournamentStream(apiQueue.attributes.tournamentQueueSessionStream.data),
      autoImportMatches: false,
    };
  };

  private _mapFirestoreQueueSessionData = (fireQueueSession: FirestoreQueueSessionDoc): QueueSessionData => {
    const queueMatchupMapper = (queueMatchup: FirestoreQueueSessionMatchupMap): QueueSessionMatchupMap => ({
      matchMapper: `${queueMatchup.match_mapper}`,
      round: `${queueMatchup.round}`,
      matchRoundCount: `${queueMatchup.match_round_count}`,
      seriesMatchupID: queueMatchup.series_matchup_id,
      teamOneID: !!queueMatchup.team_one ? `${queueMatchup.team_one}` : null,
      teamTwoID: !!queueMatchup.team_two ? `${queueMatchup.team_two}` : null,
      winnerID: !!queueMatchup.winner ? `${queueMatchup.winner}` : null,
    });

    return {
      startTime: fireQueueSession.start_time,
      status: fireQueueSession.status,
      tournamentQueueSessionID: `${fireQueueSession.tournament_queue_session_id}`,
      finalizedMatchupMapping: fireQueueSession.finalized_matchup_mapping.map(queueMatchupMapper),
    };
  };

  private _mapFirestoreQueueCheckin = (fireQueueCheckin: FirestoreQueueCheckinTeamDoc): FireQueueCheckedInTeam => ({
    createdAt: fireQueueCheckin.created_at,
    teamID: `${fireQueueCheckin.team_id}`,
    tournamentQueueSessionID: `${fireQueueCheckin.tournament_queue_session_id}`,
    userID: `${fireQueueCheckin.user_id}`,
  });
}
