import { Pipe, PipeTransform } from "@angular/core";

import { ChatroomBlockData } from "@apptypes/chat.types";
import { Nullish } from "@apptypes/nullish.types";
import { QueueDetail, QueueTeam } from "@apptypes/queue.types";
import { SchedulingType } from "@apiEnums/SchedulingType.enum";

@Pipe({
  name: "queueDetailChatroomAdapter"
})
export class QueueDetailChatroomAdapterPipe implements PipeTransform {
  public static NoQueueError = "No queue was provided";
  public transform(queue: Nullish<QueueDetail>): ChatroomBlockData | null {
    try {
      if(!queue) {
        throw new Error(QueueDetailChatroomAdapterPipe.NoQueueError);
      }

      return {
        id: queue.tournament.id,
        chatroomReferenceId: queue.tournament.tournamentChatroom.firestoreDocumentID,
        schedulingType: SchedulingType.ROUND_ROBIN,
        teams: queue.tournament.activeTeams.map(({ id, title, users }: QueueTeam) => ({
          id,
          title,
          userIds: users.map((user) => user.id),
        })),
      };
    } catch(e) {
      console.error(e);
      return null;
    }
  }
}
