import {
  Component,
  Input,
  OnInit
} from "@angular/core";

import { QuickPlayEvent } from "@apptypes/quickplay.types";
import { environment } from "@environments/environment";
import { QuickPlayEventChatroomProvider } from "@providers/chatroom-collection";
import { QuickPlayEventCommentProvider } from "@providers/comment-service";
import { QuickPlayEventModerationProvider } from "@providers/moderation-service";

import { GGLEventDetailsComponent } from "src/app/interfaces/components/ggl-event-details.component.interface";

@Component({
  selector: "app-quick-play-event-detail",
  templateUrl: "./quick-play-event-detail.component.html",
  styleUrls: ["./quick-play-event-detail.component.scss"],
  providers: [
    QuickPlayEventCommentProvider,
    QuickPlayEventChatroomProvider,
    QuickPlayEventModerationProvider
  ]
})
export class QuickPlayEventDetailComponent implements OnInit, GGLEventDetailsComponent {
  @Input() public GGLEventDetailsData!: QuickPlayEvent;

  public quickPlayAdminLink = "";
  public quickPlayAppLink = "";

  public ngOnInit(): void {
    // eslint-disable-next-line max-len
    this.quickPlayAdminLink = `${environment.apiBase}/admin/leagues/${this.GGLEventDetailsData.league.id}/quick_play_events/${this.GGLEventDetailsData.id}`;

    this.quickPlayAppLink = `${environment.appBase}/leagues/${this.GGLEventDetailsData.league.id}`;
  }
}
