import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import {
  Observable,
  of,
  Subscription
} from "rxjs";

import { GGLEventDetails } from "@apptypes/card-details.types";
import { Nullish } from "@apptypes/nullish.types";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";

import { EventColors } from "src/app/enums/EventColors.enum";
import { GGLEventConfig } from "@services/cache/cache.service";
import { first } from "rxjs/operators";
import { BOOTSTRAP_HEXES, COLOR_HEXES } from "@services/color/colors.constants";

@Component({
  selector: "app-event-color-selector",
  templateUrl: "./event-color-selector.component.html",
  styleUrls: ["./event-color-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventColorSelectorComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line object-curly-newline
  @ViewChild(NgbDropdown, { static: true }) public dropdown!: NgbDropdown;
  @Input() public event: Nullish<GGLEventDetails>;

  public eventConfig$: Observable<GGLEventConfig | undefined> = of(undefined);
  public colors = EventColors;
  public colorFormControl = new FormControl(COLOR_HEXES.WHITE);

  private _formChangesSubscription: Subscription | null = null;
  private _eventConfigSubscription: Subscription | null = null;

  constructor(private _eventConfigService: EventConfigStoreService) {}

  public ngOnInit(): void {
    if(this.event){
      this._eventConfigSubscription = this._eventConfigService.getConfigFromEventDetails(this.event).pipe(
        first()
      ).subscribe((config) => {
        if(config){
          this.colorFormControl.setValue(config?.color ?? COLOR_HEXES.WHITE);
        }
      });
    }

    this._formChangesSubscription = this.colorFormControl.valueChanges.subscribe((color) => {
      this.setEventColor(this.event, color);
      this.dropdown.close();
    });
  }

  public ngOnDestroy(): void {
    this._formChangesSubscription?.unsubscribe();
    this._eventConfigSubscription?.unsubscribe();
  }

  public setEventColor(event: Nullish<GGLEventDetails>, color: string): void {
    if(event){
      this._eventConfigService.setConfigFromEventDetails(event, {
        color
      });
    }
  }

  public patchBootstrapColor(bootstrapColor: EventColors): void {
    this.colorFormControl.patchValue(BOOTSTRAP_HEXES[bootstrapColor]);
  }
}
