import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import {
  catchError,
  map,
  take
} from "rxjs/operators";
import { UserService } from "../services/user/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _userService: UserService) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._userService.getProfile().pipe(
      take(1),
      map((user) => {
        if (user && user.isAdmin) {
          return true;
        }

        return false;
      }),
      catchError((err) => {
        console.error(err);
        this._router.navigate(["/login/error"]);
        return of(false);
      })
    );
  }
}
