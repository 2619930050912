<div>
  <h5
    class="card-title c_toolTipCutoff"
    placement="bottom"
    ngbTooltip="{{ teamOne.title }} vs {{ teamTwo.title }}"
  >
    {{ teamOne.title }} vs {{ teamTwo.title }}
  </h5>
  <h6 class="card-subtitle mb-2">
    {{ eventCardData.leagueName }} {{ roundText }}
  </h6>
  <p class="mb-1">{{ eventCardData.esport | enumTitle }}</p>
</div>
<div class="card-footer pl-0 pr-0">
  <small>{{ eventCardData.matchupStartTime | date: "short" }}</small>
</div>
