<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Send Mass Message</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="messageForm">
      <ng-container *ngIf="!isConfirm">
        <div class="form-group">
          <label for="selectedLeague">Choose League</label>
          <div class="input-group">
            <select
              (change)="checkForSelection()"
              formControlName="selectedLeague"
              id="selectedLeague"
            >
              <option [ngValue]="null">--Select a League--</option>
              <option
                *ngFor="let league of leagueList$ | async"
                [ngValue]="league"
              >
                {{ league.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" *ngIf="hasSelectedLeague">
          <label for="message">Message</label>
          <div class="input-group">
            <textarea
              id="message"
              formControlName="message"
              class="form-control"
              placeholder="Write your mass chat message here..."
              name="message"
            ></textarea>
          </div>
          <div
            class="text-right"
            [ngClass]="{
              'text-danger': messageLength < 20,
              'text-success': messageLength >= 20
            }"
          >
            {{ messageLength }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isConfirm">
        <div class="alert alert-warning">
          <strong>ATTENTION:</strong> This message will be sent to
          {{ impactedGamesLength$ | async }} games.
        </div>
        <dl>
          <dt>Selected League:</dt>
          <dd>{{ currentLeagueName }}</dd>
          <dt>Drafted Message:</dt>
          <dd>{{ currentMessage }}</dd>
        </dl>
        <hr />
        <label for="confirm">
          <input type="checkbox" formControlName="confirm" id="confirm" />
          I have reviewed the information above, and confirm that the league is
          correct, and the message I am sending is correct and meets the
          standards for GGLeagues customer communications
        </label>
      </ng-container>
    </form>
    <div class="text-danger" *ngIf="hasReviewError">
      Your message must be at least 20 Characters long before you can send it.
    </div>
    <div class="text-danger" *ngIf="formError">
      There was an error sending the messages, please try again
    </div>
    <div class="text-danger" *ngIf="confirmError">
      You must confirm that you have reviewed the message before sending it
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="!isConfirm"
      (click)="proceedToReview(messageForm)"
    >
      <span *ngIf="!false; else spinner">Review Message</span>
      <ng-template #spinner>
        <app-lds-spinner></app-lds-spinner>
      </ng-template>
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="isConfirm"
      (click)="sendMassMessages(messageForm)"
    >
      <span *ngIf="!isLoading; else spinner">Confirm and Submit</span>
      <ng-template #spinner>
        <app-lds-spinner></app-lds-spinner>
      </ng-template>
    </button>
  </div>
</ng-template>

<button class="btn btn-light" (click)="open(content)">
  Send Batch Chat Message
</button>
