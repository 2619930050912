import {
  Component,
  Input,
  OnInit
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { addMinutes } from "date-fns";

import { TournamentQueueStatus } from "@apiEnums/tournaments/tournament_queue_status.enum";
import { ISOString } from "@apptypes/aliases.types";
import { QueueDetail } from "@apptypes/queue.types";
import { QueueService } from "@services/queue/queue.service";

import { QUEUE_START_TIME } from "src/app/enums/application-globals/queue-start-time.const";

enum CloseQueueComponentState {
  DEFAULT = "default",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  ALREADY_COMPLETE = "already_complete",
};

@Component({
  selector: "app-close-queue-confirmation-modal",
  templateUrl: "./close-queue-confirmation-modal.component.html",
  styleUrls: ["./close-queue-confirmation-modal.component.scss"]
})
export class CloseQueueConfirmationModalComponent implements OnInit {
  @Input() public queue: QueueDetail | null = null;

  public componentState: CloseQueueComponentState = CloseQueueComponentState.DEFAULT;
  public queueStartTime: ISOString | null = null;
  public closeQueueComponentStateOptions = CloseQueueComponentState;

  constructor(
    private _ngbModalService: NgbModal,
    private _queueService: QueueService
  ) {}

  public ngOnInit(): void {
    if(!this.queue) {
      this.componentState = CloseQueueComponentState.ERROR;
      console.error("Queue is null");
      return;
    }

    if(this.queue.status === TournamentQueueStatus.COMPLETE) {
      this.componentState = CloseQueueComponentState.ALREADY_COMPLETE;
    }

    this.queueStartTime = addMinutes(new Date(this.queue.startTime), QUEUE_START_TIME).toISOString() as ISOString;
  }

  public close(): void {
    this._ngbModalService.dismissAll();
  }

  public closeQueue(): void {
    this.componentState = CloseQueueComponentState.LOADING;

    if(this.queue){
      this._queueService.closeQueue(this.queue.id).subscribe(
        () => {
          this.componentState = CloseQueueComponentState.SUCCESS;
        },
        (err) => {
          console.error(err);
          this.componentState = CloseQueueComponentState.ERROR;
        });
      return;
    }

    this.componentState = CloseQueueComponentState.ERROR;
  }
}
