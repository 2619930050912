<div class="c_eventDetails bg-info" [ngClass]="{ noevent: !eventDetails }">
  <ng-template #contentPortal appComponentPortal></ng-template>
  <ng-container *ngIf="!eventDetails">
    <div class="c_noEvent">
      There is no active event selected
      <br />
      📂
    </div>
  </ng-container>
</div>

<ng-container *ngIf="hasErrorLoadingComponent">
  <div class="c_eventDetails bg-danger noevent">
    <div class="c_noEvent">
      <p>There was an error loading the view for this event.</p>
      <ng-container *ngIf="eventDetails === null">
        <p>There is no data for the event</p>
      </ng-container>
      <ng-container *ngIf="!!eventDetails">
        <p>
          The event is a {{ eventDetails.type | enumTitle }} and the ID is
          {{ eventDetails.id }}. Please reach out to the tech team with this
          information.
        </p>
      </ng-container>
    </div>
  </div>
</ng-container>
