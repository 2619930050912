import { Injectable } from "@angular/core";

import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";

import { UserLoginToken } from "../auth/auth.service";
import { UserProfile } from "../user/user.service";

export interface GGLEventConfig {
  color?: string;
}

export type GGLEventConfigMap = Map<string, GGLEventConfig>;
@Injectable({
  providedIn: "root",
})
export class CacheService {
  public static AUTH_KEY = "LMT_AUTH";
  public static CACHE_KEY = "CACHED_CONFIG_MAP";
  public static GAMES_KEY = "CACHED_GAMESLIST";
  public static USER_KEY = "USER_INFO";

  public initializeConfigMap(): GGLEventConfigMap {
    const cachedMap = localStorage.getItem(CacheService.CACHE_KEY);
    if (cachedMap) {
      try {
        return new Map(JSON.parse(cachedMap));
      } catch (e) {
        console.error(e);
        window.alert("Error restoring cache, loading blank config instead.");
      }
    }
    return new Map();
  }

  public initializeGamesList(): GGLEventCardInfo[] {
    const cachedGamesList = localStorage.getItem(CacheService.GAMES_KEY);
    if (cachedGamesList) {
      try {
        return JSON.parse(cachedGamesList);
      } catch (e) {
        console.error(e);
        window.alert("Error restoring cache, loading blank config instead.");
      }
    }
    return [];
  }

  public getCachedUser(): UserProfile | null{
    const cachedUser = localStorage.getItem(CacheService.USER_KEY);
    if (cachedUser) {
      try {
        return JSON.parse(cachedUser);
      } catch (e) {
        console.error(e);
        window.alert("Error restoring cache, loading blank config instead.");
      }
    }
    return null;
  }

  public cacheUser(user: UserProfile): void {
    try {
      const serializedToken = JSON.stringify(user);
      localStorage.setItem(CacheService.USER_KEY, serializedToken);
    } catch (e) {
      console.error(e);
      window.alert("Unable to cache user.  This session may not persist through refresh");
    }
  }

  public cacheGamelist(gamelist: GGLEventCardInfo[]): void {
    try {
      const serializedToken = JSON.stringify(gamelist);
      localStorage.setItem(CacheService.GAMES_KEY, serializedToken);
    } catch (e) {
      console.error(e);
      window.alert("Unable to cache gameslist.  This session may not persist through refresh");
    }
  }

  public removeGameFromCache(id: string):  GGLEventCardInfo[] {
    try {
      const filteredGameList: GGLEventCardInfo[] = JSON.parse(localStorage.getItem(CacheService.GAMES_KEY) ?? "[]")
        .filter((match: GGLEventCardInfo) => match.id !== id);
      this.cacheGamelist(filteredGameList);
      return filteredGameList;
    }
    catch (e) {
      console.error(e);
      window.alert("Unable to update cache gamelist.  This session may not persist through refresh");
      return [];
    }
  }

  public cacheAuth(token: UserLoginToken): void {
    try {
      const serializedToken = JSON.stringify(token);
      localStorage.setItem(CacheService.AUTH_KEY, serializedToken);
    } catch (e) {
      console.error(e);
      window.alert("Unable to cache login credentials.  This session may not persist through refresh");
    }
  }

  public cacheConfig(config: GGLEventConfigMap): void {
    try {
      const serializedConfig = JSON.stringify(Array.from(config.entries()));
      localStorage.setItem(CacheService.CACHE_KEY, serializedConfig);
    } catch (e) {
      console.error(e);
      window.alert("Unable to cache config.  Your config will be lost if you refresh the page");
    }
  }
}
