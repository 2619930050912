import {
  MatchDetailComponent,
  QueueDetailComponent,
  QuickPlayEventDetailComponent
} from "../components/event-detail-content";
import { MatchDetail } from "./games.types";
import { QueueDetail } from "./queue.types";
import { QuickPlayEvent } from "./quickplay.types";

export type GGLEventDetails = QuickPlayEvent | MatchDetail | QueueDetail;
export type GGLEventDetailsComponents = QuickPlayEventDetailComponent | MatchDetailComponent | QueueDetailComponent;

export enum GGLEventTypes {
  QUICKPLAY_EVENT = "quickPlayEvent",
  SERIES_MATCHUP = "seriesMatchup",
  QUEUE = "tournamentQueueSession",
}
