import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { EventImportService } from "@services/event-import/event-import.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";
import { QuickPlayEventCardInfo } from "@apptypes/ggl-event-card.types";

import { DEFAULT_ERROR } from "../../event-import-modal/event-import-modal.component";
import { EventCardImportForm } from "src/app/interfaces/components/ggl-event-card-import-forms.component.interface";
import { EventColors } from "src/app/enums/EventColors.enum";

export interface QuickplayLeagueImportInfo {
  leagueName: string;
}

@Component({
  selector: "app-quickplay-league-import-form",
  templateUrl: "./quickplay-league-import-form.component.html",
  styleUrls: ["./quickplay-league-import-form.component.scss"]
})
export class QuickplayLeagueImportFormComponent implements EventCardImportForm {
  public leagueForm: FormGroup;

  public isLoading = false;
  public showForm = true;
  public hasError = false;
  public errorMsg = DEFAULT_ERROR;

  public importInfo: QuickplayLeagueImportInfo = {
    leagueName: "",
  };

  constructor(
    private _formBuilder: FormBuilder,
    private _eventImportService: EventImportService,
    private _modalService: NgbModal,
    private _eventCardService: EventCardStoreService,
    private _eventConfigService: EventConfigStoreService,
  ) {
    this.leagueForm = this._formBuilder.group({
      leagueId: [null, Validators.required],
      preloadedColor: [EventColors.WHITE],
    });
  }

  public addMoreGames(): void {
    this.leagueForm.enable();
    this.leagueForm.setValue({
      leagueId: "",
      preloadedColor: EventColors.WHITE,
    });
    this.leagueForm.markAsPristine();
    this.isLoading = false;
    this.showForm = true;
  }

  public finishAddingGames(): void {
    this._modalService.dismissAll();
  }

  public saveEvents(leagueForm: FormGroup): void {
    if(leagueForm.invalid) {
      this._setError("Invalid values given, please correct and submit again");
      return;
    }

    this.isLoading = true;
    leagueForm.disable();

    const leagueId = leagueForm.value.leagueId;
    const color = leagueForm.value?.preloadedColor ?? EventColors.WHITE;

    const quickplayFetchSuccessFn = (matchCard: QuickPlayEventCardInfo): void => {
      this.showForm = false;
      this._eventConfigService.setConfigFromEventCard(matchCard, {
        color
      });
      this._eventCardService.addEventCard(matchCard);
      this._setImportInfo(matchCard);
    };

    const quickplayFetchErrorFn = (error: any): void => {
      console.error(error);
      this._setError("The league either does not exist or is not a quickplay league");
      leagueForm.enable();
      this.isLoading = false;
    };

    this._eventImportService.getQuickplayEvent(leagueId).subscribe(quickplayFetchSuccessFn, quickplayFetchErrorFn);
  }

  private _setImportInfo(card: QuickPlayEventCardInfo) {
    this.importInfo = {
      leagueName: card.leagueName,
    };
  }

  private _setError(errMsg: string = DEFAULT_ERROR): void {
    this.hasError = true;
    this.errorMsg = errMsg;
  }
}
