<div class="form-check">
  <input
    type="checkbox"
    [(ngModel)]="usingCustomSelect"
    id="customColors"
    (ngModelChange)="changeCustomSelect()"
    class="form-check-input"
  />
  <label for="customColors" class="form-check-label">
    Use custom colors?
  </label>
</div>
<div class="w-25">
  <app-event-color-form-select
    [formControl]="customColorsFormControl"
    *ngIf="usingCustomSelect"
  ></app-event-color-form-select>
</div>
<div class="form-group w-25" *ngIf="!usingCustomSelect">
  <label for="defaultColors"> Default Colors </label>
  <select
    [formControl]="defaultColorsFormControl"
    class="form-control input-sm"
    aria-label="Select Event Card Color"
    id="defaultColors"
  >
    <option [ngValue]="bootstrapHexes[eventColors.WHITE]">White</option>
    <option [ngValue]="bootstrapHexes[eventColors.BLUE]">Blue</option>
    <option [ngValue]="bootstrapHexes[eventColors.GREEN]">Green</option>
    <option [ngValue]="bootstrapHexes[eventColors.RED]">Red</option>
    <option [ngValue]="bootstrapHexes[eventColors.YELLOW]">Yellow</option>
  </select>
</div>
