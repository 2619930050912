<div class="c_matchDetailContainer">
  <h2>
    <ng-container
      *ngIf="
        match.tournament.schedulingType === schedulingType.BATTLE_ROYALE;
        else twoTeamHeader
      "
    >
      <strong> {{ match.esportType | enumTitle }} Battle Royale </strong>
    </ng-container>
  </h2>
  <h4><strong>Winner:</strong> {{ showWinner() }}</h4>
  <h4>
    <strong>League: </strong>
    <a [href]="getLeagueLink(match.league)" target="_blank">{{
      match.league.title
    }}</a>
  </h4>
  <div class="c_gameButtons">
    <app-event-color-selector [event]="match"></app-event-color-selector>
    <a
      class="btn btn-warning"
      [href]="getGameAppLink(match)"
      target="_blank"
      role="button"
      >Go to Game (App)</a
    >
    <a
      class="btn btn-warning"
      [href]="getGameAdminLink(match)"
      target="_blank"
      role="button"
      >Go to Game (Admin)</a
    >
  </div>
  <div class="c_gameChat">
    <ng-container
      *ngIf="
        match | matchDetailChatroomAdapter as chatroomData;
        else badMapping
      "
    >
      <app-chat-block [chatroomData]="chatroomData"></app-chat-block>
    </ng-container>
    <ng-template #badMapping>
      <p>
        The chat could not be loaded correctly. The data for the chat is
        malformed.
      </p>
    </ng-template>
  </div>
</div>

<ng-template #twoTeamHeader>
  <strong>
    <a
      *ngIf="!!match.teams[0]; else tbdTeam"
      [href]="getTeamLink(match.teams[0])"
      target="_blank"
      >{{ match.teams[0].title }}</a
    >
  </strong>
  vs
  <strong>
    <a
      *ngIf="!!match.teams[1]; else tbdTeam"
      [href]="getTeamLink(match.teams[1])"
      target="_blank"
    >
      {{ match.teams[1].title }}
    </a>
    <ng-template #tbdTeam>
      <a> TBD </a>
    </ng-template>
  </strong>
</ng-template>
