import { EventColors } from "src/app/enums/EventColors.enum";

//Colors source: https://www.w3.org/wiki/CSS/Properties/color/keywords
export enum COLOR_HEXES {
  BLACK = "#000000",
  WHITE = "#FFFFFF",
  SILVER = "#C0C0C0",
  GRAY = "#808080",
  MAROON = "#800000",
  RED = "#FF0000",
  PURPLE = "#800080",
  FUSCHIA = "#FF00FF",
  GREEN = "#008000",
  LIME = "#00FF00",
  OLIVE = "#808000",
  YELLOW = "#FFFF00",
  NAVY = "#000080",
  BLUE = "#0000FF",
  TEAL = "#008080",
  AQUA = "#00FFFF",
}

export const BOOTSTRAP_HEXES: { [key in EventColors]: string } = {
  [EventColors.BLUE]: "#17a2b8",
  [EventColors.GREEN]: "#28a745",
  [EventColors.RED]: "#dc3545",
  [EventColors.WHITE]: COLOR_HEXES.WHITE,
  [EventColors.YELLOW]: "#ffc107",
};
