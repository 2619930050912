import { ChatroomStatus } from "@apiEnums/chatroom-status.enum";
import { QuickplayEventStatus } from "@apiEnums/quickplay-events/quickplay-event-status.enum";
import { TournamentQueueStatus } from "@apiEnums/tournaments/tournament_queue_status.enum";

import { GGLEventCardTypes } from "../enums/GGLEventCardTypes.enum";
import { ISOString } from "./aliases.types";
import { EsportName } from "./assignment.types";
import { Nullish } from "./nullish.types";

export type GGLEventCardInfo =
  QueueSessionEventCardInfo |
  BattleRoyaleEventCardInfo |
  EliminationEventCardInfo  |
  RoundRobinEventCardInfo   |
  QueueMatchEventCardInfo |
  QuickPlayEventCardInfo;

export interface BaseGGLEventCardInfo {
  id: string;
  type: GGLEventCardTypes;
  esport: EsportName;
  leagueID: string;
  leagueName: string;
  importedAt: ISOString;
}

export interface BattleRoyaleEventCardInfo extends BaseGGLEventCardInfo {
  type: GGLEventCardTypes.BATTLE_ROYALE;
  matchChatroomId: string;
  matchupStartTime: ISOString;
  winnerID: Nullish<string>;
  winnerTitle: Nullish<string>;
  teams: EventCardTeam[];
}

export interface TournamentEventCardInfo extends BaseGGLEventCardInfo {
  id: string; //Series Matchup ID
  matchChatroomID: string;
  matchupStartTime: ISOString;
  tournamentID: string;
  tournamentRound: string;
  winnerID: Nullish<string>;
  teams: [EventCardTeam, EventCardTeam];
  importedAt: ISOString;
}

export interface EventCardTeam {
  id: string;
  title: string;
}

export const NullEventCardTeam: EventCardTeam = {
  id: "-1",
  title: "TBD"
};

export interface RoundRobinEventCardInfo extends TournamentEventCardInfo {
  type: GGLEventCardTypes.ROUND_ROBIN;
}

export interface EliminationEventCardInfo extends TournamentEventCardInfo {
  type: GGLEventCardTypes.ELIMINATION;
}

export interface QueueMatchEventCardInfo extends TournamentEventCardInfo {
  queueRound: string;
  type: GGLEventCardTypes.QUEUE_MATCH;
}

export interface QueueSessionEventCardInfo extends BaseGGLEventCardInfo {
  id: string; //Tournament Queue Session ID
  type: GGLEventCardTypes.QUEUE;
  status: TournamentQueueStatus;
  teams: EventCardTeam[];
  queueStartTime: string;
  tournamentID: string;
  tournamentTitle: string;
  autoImportMatches: boolean;
  importedAt: ISOString;
}

export interface QuickPlayEventCardInfo extends BaseGGLEventCardInfo {
  id: string; //Quickplay Event Id
  type: GGLEventCardTypes.QUICKPLAY;
  startTime: ISOString;
  status: QuickplayEventStatus; //Double check
  timeZone: string;
  chatroom: QuickPlayEventChatroom;
}

export interface QuickPlayEventChatroom {
  id: string;
  type: "quickPlayEventChatroom";
  firestoreDocumentId: string;
  status: ChatroomStatus;
};
