import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import {
  CacheService,
  GGLEventConfigMap,
  GGLEventConfig
} from "@services/cache/cache.service";
import { GGLEventDetails, GGLEventTypes } from "@apptypes/card-details.types";
import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";

import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";
import { map } from "rxjs/operators";

const eventDardDetailMap: { [key in GGLEventCardTypes]: GGLEventTypes } = {
  [GGLEventCardTypes.QUEUE]: GGLEventTypes.QUEUE,
  [GGLEventCardTypes.ELIMINATION]: GGLEventTypes.SERIES_MATCHUP,
  [GGLEventCardTypes.BATTLE_ROYALE]: GGLEventTypes.SERIES_MATCHUP,
  [GGLEventCardTypes.ROUND_ROBIN]: GGLEventTypes.SERIES_MATCHUP,
  [GGLEventCardTypes.QUEUE_MATCH]: GGLEventTypes.SERIES_MATCHUP,
  [GGLEventCardTypes.QUICKPLAY]: GGLEventTypes.QUICKPLAY_EVENT,
};

/**
 * A service to manage the state of the application event config map.
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export class EventConfigStoreService {
  public readonly gglEventConfigMap$: Observable<GGLEventConfigMap>;

  private readonly _configMapSource: BehaviorSubject<GGLEventConfigMap>;

  constructor(
    private _cacheService: CacheService
  ) {
    const configMap = this._cacheService.initializeConfigMap();
    this._configMapSource = new BehaviorSubject(configMap);
    this.gglEventConfigMap$ = this._configMapSource.asObservable();
  }

  public static getKeyFromEventCard(eventCard: GGLEventCardInfo): string {
    return `${eventDardDetailMap[eventCard.type]}-${eventCard.id}`;
  }

  public static getKeyFromEventDetails(eventDetails: GGLEventDetails): string {
    return `${eventDetails.type}-${eventDetails.id}`;
  }

  public getConfigFromEventCard(eventCard: GGLEventCardInfo): Observable<GGLEventConfig | undefined> {
    return this.gglEventConfigMap$.pipe(
      map((configMap) => configMap.get(EventConfigStoreService.getKeyFromEventCard(eventCard)))
    );
  }

  public getConfigFromEventDetails(eventDetails: GGLEventDetails): Observable<GGLEventConfig | undefined> {
    return this.gglEventConfigMap$.pipe(
      map((configMap) => configMap.get(EventConfigStoreService.getKeyFromEventDetails(eventDetails)))
    );
  }

  /**
   * Set the config for an event card.
   *
   * @param eventCard
   */
  // eslint-disable-next-line object-curly-newline
  public setConfigFromEventCard(eventCard: GGLEventCardInfo, configValue: GGLEventConfig = {}): void {
    this._setConfig(
      EventConfigStoreService.getKeyFromEventCard(eventCard),
      configValue
    );
  }

  // eslint-disable-next-line object-curly-newline
  public setConfigForMultipleEventCards(eventCards: GGLEventCardInfo[], configValue: GGLEventConfig = {}): void {
    this._setMultipleEventConfigs(eventCards.map((eventCard) => [EventConfigStoreService.getKeyFromEventCard(eventCard), configValue]));
  }

  /**
   * Update an item in the config map
   *
   * @param id
   * @param type
   * @param configValue
   */
  // eslint-disable-next-line object-curly-newline
  public setConfigFromEventDetails(eventDetails: GGLEventDetails, configValue: GGLEventConfig = {}): void {
    this._setConfig(
      EventConfigStoreService.getKeyFromEventDetails(eventDetails),
      configValue
    );
  }

  // eslint-disable-next-line object-curly-newline
  private _setConfig(key: string, value: GGLEventConfig = {}): void {
    const mapValue = this._configMapSource.getValue();
    mapValue.set(key, value);
    this._configMapSource.next(mapValue);
    this._cacheService.cacheConfig(this._configMapSource.getValue());
  }

  private _setMultipleEventConfigs(keyValues: [string, GGLEventConfig][]): void {
    const mapValue = this._configMapSource.getValue();
    keyValues.forEach(([key, value]) => {
      mapValue.set(key, value);
    });
    this._configMapSource.next(mapValue);
    this._cacheService.cacheConfig(this._configMapSource.getValue());
  }
}
