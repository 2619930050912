<div>
  <h5 class="card-title">
    {{ eventCardData.esport | enumTitle }} Battle Royale
  </h5>
  <h6 class="card-subtitle mb-2">
    {{ eventCardData.leagueName }}
  </h6>
</div>
<div class="card-footer pl-0 pr-0">
  <small>{{ eventCardData.matchupStartTime | date: "short" }}</small>
</div>
