import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { BattleRoyaleEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-remove-battle-royale-event",
  templateUrl: "./remove-battle-royale-event.component.html",
  styleUrls: ["./remove-battle-royale-event.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveBattleRoyaleEventComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: BattleRoyaleEventCardInfo;
}
