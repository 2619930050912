import { Provider } from "@angular/core";

import { FirestoreChatroomCollections } from "src/app/enums/FirestoreCollections.enum";
import { CHATROOM_COLLECTION } from "./chatroom-collection.token";

export const SeriesMatchupChatroomProvider: Provider = {
  provide: CHATROOM_COLLECTION,
  useValue: FirestoreChatroomCollections.CHATROOMS
};

