import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from "@angular/core";
import { format, fromUnixTime } from "date-fns";

import { ChatMessage, ChatroomBlockData } from "@apptypes/chat.types";
import { SchedulingType } from "@apiEnums/SchedulingType.enum";

@Component({
  selector: "app-chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit {
  @Input() public message!: ChatMessage;
  @Input() public chatroomData!: ChatroomBlockData;
  public messageBackgroundColor = "";
  public teamTitle = "";
  public postedAt = "";

  public ngOnInit(): void {
    this.messageBackgroundColor = this.getMessageBackgroundColor(this.message);
    this.teamTitle = this.generateTeamString(this.message);
    this.postedAt = this.generateCommentTimestamp(this.message.date);
  }

  public getMessageBackgroundColor(message: ChatMessage): string {
    if (message.isAdmin) {
      return "alert-warning";
    }

    if(this.chatroomData.schedulingType !== SchedulingType.BATTLE_ROYALE){
      if (message.team?.id === this.chatroomData.teams[0].id) {
        return "alert-primary";
      }
      if (message.team?.id === this.chatroomData.teams[1].id) {
        return "alert-success";
      }
    }

    return "alert-secondary";
  }

  public generateTeamString(message: ChatMessage): string {
    //TODO: Figure out how to get the team loaded from queue detail
    if (message.isAdmin) {
      return "Admin";
    }

    const team = this.chatroomData.teams.find(matchTeam => matchTeam.id === message.team?.id);

    if(team) {
      return team.title;
    }

    return "";
  }

  public generateCommentTimestamp(unixTime: number): string {
    try {
      const date = fromUnixTime(unixTime);
      return format(date, "MMMM do, h:mmaaa");
    } catch (e) {
      console.error(e);
      return "";
    }
  }
}
