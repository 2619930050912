import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { QueueSessionEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventImportService } from "@services/event-import/event-import.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";

import { EventCardImportForm } from "src/app/interfaces/components/ggl-event-card-import-forms.component.interface";
import { DEFAULT_ERROR } from "../../event-import-modal/event-import-modal.component";
import { EventColors } from "src/app/enums/EventColors.enum";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";

interface QueueSessionImportInfo {
  tournamentTitle: string;
  leagueName: string;
}

@Component({
  selector: "app-queue-import-form",
  templateUrl: "./queue-import-form.component.html",
  styleUrls: ["./queue-import-form.component.scss"]
})
export class QueueImportFormComponent implements EventCardImportForm {
  public queueForm: FormGroup;

  public isLoading = false;
  public showForm = true;
  public hasError = false;
  public errorMsg = DEFAULT_ERROR;
  public importInfo: QueueSessionImportInfo | null = null;

  constructor(
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _assignmentService: EventImportService,
    private _eventCardService: EventCardStoreService,
    private _eventConfigService: EventConfigStoreService,
  ) {
    this.queueForm = this._formBuilder.group({
      queueId: [null, Validators.required],
      autoImportMatches: [false],
      preloadedColor: [EventColors.WHITE],
    });
  }

  public addMoreGames(): void {
    this.queueForm.enable();
    this.queueForm.setValue({
      queueId: "",
      autoImportMatches: false,
      preloadedColor: EventColors.WHITE,
    });
    this.queueForm.markAsPristine();
    this.isLoading = false;
    this.showForm = true;
  };

  public finishAddingGames(): void {
    this._modalService.dismissAll();
  };

  public saveEvents(queueForm: FormGroup): void {
    if(queueForm.invalid){
      this._setError("You must provide a queue ID");
      return;
    }

    this.isLoading = true;
    queueForm.disable();
    const autoImportMatches: boolean = queueForm.value?.autoImportMatches ?? false;
    const color: EventColors = queueForm.value?.preloadedColor ?? EventColors.WHITE;

    const queueFetchSuccessFn = (queueEventCard: QueueSessionEventCardInfo): void => {
      this.showForm = false;
      this._eventConfigService.setConfigFromEventCard(queueEventCard, {
        color
      });
      this._eventCardService.addEventCard(
        {
          ...queueEventCard,
          autoImportMatches
        }
      );
      this._setImportInfo(queueEventCard);
    };

    const queueFetchErrorFn = (error: any): void => {
      console.error(error);
      this._setError("The queue does not exist");
      queueForm.enable();
      this.isLoading = false;
    };

    this._assignmentService.getQueueInfo(queueForm.value.queueId).subscribe(queueFetchSuccessFn, queueFetchErrorFn);
  }

  private _setImportInfo(card: QueueSessionEventCardInfo): void {
    this.importInfo = {
      leagueName: card.leagueName,
      tournamentTitle: card.tournamentTitle,
    };
  }

  private _setError(errMsg: string = DEFAULT_ERROR): void {
    this.hasError = true;
    this.errorMsg = errMsg;
  }
}
