// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GGLMTEnvironment } from "./environment.interface";

export const environment: GGLMTEnvironment = {
  production: false,
  appBase: "https://staging.ggleagues.com",
  apiBase: "https://staging.api.ggleagues.com",
  cloudFunctionAPIBase: "https://us-central1-staging-ggleagues.cloudfunctions.net",
  useCloudChats: true,
  firebaseConfig: {
    apiKey: "AIzaSyCAjFdAR9OsnRJzdzVVM3hZGrvEHfaEoE4",
    authDomain: "staging-ggleagues.firebaseapp.com",
    databaseURL: "https://staging-ggleagues.firebaseio.com",
    projectId: "staging-ggleagues",
    storageBucket: "staging-ggleagues.appspot.com",
    messagingSenderId: "1030868835572",
    appId: "1:1030868835572:web:c23dd50e9c83ccd21f92a1",
    measurementId: "G-763X2L02S4",
    notificationCollection: "notifications",
    chatroomCollection: "chatrooms",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
