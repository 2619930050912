import { Pipe, PipeTransform } from "@angular/core";
import { SchedulingType } from "@apiEnums/SchedulingType.enum";
import { ChatroomBlockData } from "@apptypes/chat.types";
import { Nullish } from "@apptypes/nullish.types";
import { QuickPlayEvent } from "@apptypes/quickplay.types";

@Pipe({
  name: "quickPlayEventDetailChatroomAdapter"
})
export class QuickPlayEventDetailChatroomAdapterPipe implements PipeTransform {
  public static NoQuickPlayEventError = "No QuickPlayEvent was provided";
  public transform(quickPlayEvent: Nullish<QuickPlayEvent>): ChatroomBlockData | null {
    try {
      if(!quickPlayEvent){
        throw new Error(QuickPlayEventDetailChatroomAdapterPipe.NoQuickPlayEventError);
      }

      return {
        id: quickPlayEvent.id,
        chatroomReferenceId: quickPlayEvent.chatroom.firestoreDocumentId,
        schedulingType: SchedulingType.BATTLE_ROYALE,
        teams: quickPlayEvent.league.teams.map(({ id, title, users }) => ({
          id,
          title,
          userIds: users.map(user => user.id),
        })),
      };
    } catch(e) {
      console.error(e);
      return null;
    }
  }

}
