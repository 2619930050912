<div class="c_leagueDetailContainer">
  <ng-container
    *ngIf="
      GGLEventDetailsData | quickPlayEventDetailChatroomAdapter as chatroomData
    "
  >
    <h3>
      <strong>Quickplay League: </strong>{{ GGLEventDetailsData.league.title }}
    </h3>
    <div class="c_leagueDetailButtons">
      <app-event-color-selector
        [event]="GGLEventDetailsData"
      ></app-event-color-selector>
      <a
        class="btn btn-warning"
        [href]="quickPlayAppLink"
        target="_blank"
        role="button"
        >Go to League (App)</a
      >
      <a
        class="btn btn-warning"
        [href]="quickPlayAdminLink"
        target="_blank"
        role="button"
        >Go to League (Admin)</a
      >
    </div>
    <div class="c_scrollContainer">
      <app-quick-play-event-checkin-list
        [quickPlayEvent]="GGLEventDetailsData"
      ></app-quick-play-event-checkin-list>
    </div>
    <div class="c_scrollContainer">
      <app-chat-block [chatroomData]="chatroomData"></app-chat-block>
    </div>
  </ng-container>
</div>
