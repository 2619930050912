import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import {
  filter,
  map,
  mergeMap,
  share,
  withLatestFrom
} from "rxjs/operators";

import { ISOString } from "@apptypes/aliases.types";
import { isoStringToUnixTime } from "@utils/date.utils";

import { FirestoreChatroomCollections, FirestoreCollections } from "src/app/enums/FirestoreCollections.enum";
import {
  Chatroom,
  FirestoreChatroom,
  MatchCommenter,
  ChatMessage,
  ChatMembers,
  FirestoreChatMessage,
  ChatroomBlockTeam,
} from "src/app/types/chat.types";
import {
  FirestoreChatAPIResponse,
  FireStoreChatRoomAPI,
  FirestoreAPIChatMessage,
  FirestoreAPIDate,
} from "./chat.api.types";
import { UsernameService } from "./username/username.service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  constructor(
    private _firestore: AngularFirestore,
    private _usernameService: UsernameService
  ) { }

  public getChatroomByDocID(docId: string, collection: FirestoreChatroomCollections): Observable<Chatroom | null> {
    const chatroomDocument: AngularFirestoreDocument<FirestoreChatAPIResponse> =
      this._firestore.collection(collection).doc<FirestoreChatAPIResponse>(docId);

    const firestoreMessagesCollection$ = chatroomDocument.collection<FirestoreAPIChatMessage>(
      FirestoreCollections.USER_CHATS, ref => ref.orderBy("date", "asc"),
    ).valueChanges({
      idField: "documentId",
    });

    const chatroomData$ = chatroomDocument.valueChanges({
      idField: "chatroomId",
    });

    return firestoreMessagesCollection$.pipe(
      withLatestFrom(chatroomData$),
      filter(chatroom => !!chatroom && !!chatroom[0] && !!chatroom[1]),
      map(([messagesCollection, chatroom]) => {
        if(!!chatroom && (!chatroom?.messages || chatroom?.messages?.length === 0)){
          chatroom.messages = []; //If the chatroom doesn't have a messages obj add it here
        }
        if (messagesCollection && chatroom && messagesCollection.length > 0) {
          chatroom.messages = messagesCollection; //Replace the messages if the collection exists
        }
        return chatroom;
      }),
      map(chatroom => this._mapFirestoreChat(chatroom as (FirestoreChatAPIResponse & { chatroomId: string }))),
      mergeMap(async (firestoreChatroom) => {
        //I wish I knew how to replicate this with an rxjs method since this is deprecated, but this will do.
        const userIDs = firestoreChatroom.messages.map((message) => message.userId.toString());
        const matchCommenters: MatchCommenter[] = await this._usernameService.getAllMatchCommenters(userIDs).toPromise();
        const chatMembers: ChatMembers = {
          type: "chatMembers",
          chatMembers: matchCommenters,
        };

        return {
          chatroom: firestoreChatroom,
          chatMembers,
          type: "chatroom",
        } as Chatroom;
      }),
      share() //Shares this observable once across subscriptions so this pipe doesn't multi-fire
    );
  }

  public mapChatroomMessages(
    chatroom: FirestoreChatroom,
    commenters: MatchCommenter[],
    teams: ChatroomBlockTeam[],
  ): ChatMessage[] {
    return chatroom.messages.map((message) => {
      const commentUser = commenters.find((commenter) => {
        if (commenter.id === message.userId) {
          return true;
        }
        return false;
      });

      const username = commentUser?.inGameName ?? "GGLeagues Player";

      return {
        documentId: message.documentId,
        isAdmin: message.isAdmin,
        userId: message.userId,
        date: message.date,
        message: message.message,
        isHidden: message.hideChatMessage,
        unhiddenById: message.unhiddenById,
        hiddenById: message.hiddenById,
        username,
        team: this._setTeam(message.userId.toString(), teams)
      };
    });
  }

  private _mapFirestoreChat(apiResponse: FireStoreChatRoomAPI): FirestoreChatroom {
    const messages: FirestoreChatMessage[] = apiResponse.messages.map((message) => ({
      userId: +message.user_id,
      message: message.body,
      date: this._formatDate(message.date),
      isAdmin: message.is_admin,
      documentId: message.documentId,
      unhiddenById: message.unhidden_by_id?.toString(),
      hiddenById: message.hidden_by_id?.toString(),
      hideChatMessage: message.hide_chat_message
    }));
    return {
      gameId: apiResponse.game_id ?? null,
      seriesMatchupId: apiResponse.series_matchup_id ?? null,
      status: apiResponse.status,
      type: "chatroom",
      messages,
      chatroomId: apiResponse.chatroomId,
    };
  }

  private _setTeam(userId: string, teams: ChatroomBlockTeam[]): ChatroomBlockTeam | null {
    const foundTeam = teams.find(team => team.userIds.find(id => id === userId));
    return foundTeam ?? null;
  }

  private _formatDate(date: ISOString | FirestoreAPIDate): number {
    if (typeof (date) === "string") {
      //It's an ISO string
      return isoStringToUnixTime(date);
    }

    return (date as FirestoreAPIDate).seconds;
  }

}

