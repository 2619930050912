import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from "@angular/core";

import { ChatMessage, ChatroomBlockData } from "@apptypes/chat.types";

import { ChatScrollDirective } from "src/app/directives/chat-scroll.directive";

@Component({
  selector: "app-chat-stream",
  templateUrl: "./chat-stream.component.html",
  styleUrls: ["./chat-stream.component.scss"]
})
export class ChatStreamComponent implements OnChanges {
  @Input() public messages: ChatMessage[] = [];
  @Input() public chatroomData!: ChatroomBlockData;
  @Input() public isAdminNotifStream = false;

  @ViewChild(ChatScrollDirective)
  public chatScrollDirective!: ChatScrollDirective;

  public isBannerShowing = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.messages.firstChange) {
      if (this.chatScrollDirective) {
        if (!this.chatScrollDirective.isUserInAutoScrollRange) {
          this.isBannerShowing = true;
        }
        this.chatScrollDirective.scrollToBottom();
      }
    }
  }

  public dismissBanner() {
    this.isBannerShowing = false;
    this.chatScrollDirective.scrollToBottom(true);
  }
}
