import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GGLEventTypes } from "@apptypes/card-details.types";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  MatchDetail,
  MatchDetailLeague,
  MatchDetailTeam,
  MatchDetailTeamMember,
  MatchDetailTournament
} from "src/app/types/games.types";
import { basicAuthHeaderWJSON } from "src/app/utils/auth-header.util";
import { environment } from "src/environments/environment";
import {
  MatchDetailAPILeague,
  MatchDetailAPIResponse,
  MatchDetailAPITeam,
  MatchDetailAPITeamUser,
  MatchDetailAPITournament
} from "./games.types";

@Injectable({
  providedIn: "root",
})
export class GamesService {
  constructor(private _http: HttpClient) {}

  public getMatchById(matchId: string): Observable<MatchDetail> {
    const url = `${environment.apiBase}/api/v2/league_admin/series_matchups/${matchId}`;
    const headers = basicAuthHeaderWJSON();

    return this._http
      .get<MatchDetailAPIResponse>(url, {
      headers
    })
      .pipe(map(this._matchDetailMapper));
  }

  private _matchDetailMapper = (rawMatch: MatchDetailAPIResponse): MatchDetail => {
    const rawMatchData = rawMatch.data;
    const rawMatchAttributes = rawMatchData.attributes;

    const _matchDetailTournamentMapper = ({ id, type, attributes }: MatchDetailAPITournament): MatchDetailTournament => ({
      id,
      type,
      ...attributes,
    });

    const _matchDetailLeagueMapper = ({ id, type, attributes }: MatchDetailAPILeague): MatchDetailLeague => ({
      id,
      type,
      ...attributes,
    });

    const _matchDetailUserMapper = ({ id, type, attributes }: MatchDetailAPITeamUser): MatchDetailTeamMember => ({
      id,
      type,
      ...attributes,
    });

    const _matchDetailTeamMapper = ({ id, type, attributes }: MatchDetailAPITeam): MatchDetailTeam => {
      const { users: { data: apiUsers } } = attributes;
      const users = apiUsers.map(_matchDetailUserMapper);
      return {
        ...attributes,
        id,
        type,
        users,
      };
    };

    const teams = rawMatchData.attributes.teams.data.map(_matchDetailTeamMapper);

    return {
      id: rawMatchData.id,
      type: GGLEventTypes.SERIES_MATCHUP,
      chatroomReferenceId: rawMatchAttributes.chatroomReferenceId,
      winnerId: rawMatchAttributes.winnerId ? rawMatchAttributes.winnerId.toString() : null,
      esportType: rawMatchAttributes.esportName,
      startTime: rawMatchAttributes.matchupStartTime,
      league: _matchDetailLeagueMapper(rawMatchData.attributes.league.data),
      tournament: _matchDetailTournamentMapper(rawMatchData.attributes.tournament.data),
      teams,
    };
  };
}
