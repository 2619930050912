/* eslint-disable max-len */
import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";

import { CloudQuickPlayEventCommentService } from "@services/chat/comment";

import { COMMENT_SERVICE } from "./comment-service.token";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function QuickPlayEventCommentFactory(
  http: HttpClient,
): CloudQuickPlayEventCommentService {
  return new CloudQuickPlayEventCommentService(http);
}

/**
 * Provides a CommentService for QuickPlayEvent Chatrooms
 */
export const QuickPlayEventCommentProvider: Provider = {
  provide: COMMENT_SERVICE,
  useFactory: QuickPlayEventCommentFactory,
  deps: [HttpClient],
};
