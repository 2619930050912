import {
  Component,
  Input,
  TemplateRef
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ChatMessage } from "@apptypes/chat.types";

@Component({
  selector: "app-ban-user-dropdown-item",
  templateUrl: "./ban-user-dropdown-item.component.html",
  styleUrls: ["./ban-user-dropdown-item.component.scss"]
})
export class BanUserDropdownItemComponent {
  @Input() public chatMessage!: ChatMessage;
  @Input() public resourceID!: string;

  constructor(private _modal: NgbModal) {}

  public banUser(content: TemplateRef<any>): void {
    this._modal.open(content);
  }
}
