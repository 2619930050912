import { Component } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { EventImportService } from "@services/event-import/event-import.service";
import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";

import { DEFAULT_ERROR } from "../../event-import-modal/event-import-modal.component";
import { EventCardImportForm } from "src/app/interfaces/components/ggl-event-card-import-forms.component.interface";
import { EventColors } from "src/app/enums/EventColors.enum";
import { BOOTSTRAP_HEXES } from "src/app/services/color/colors.constants";

export interface BattleRoyaleImportInfo {
  seriesMatchupId: string;
  leagueName: string;
}

@Component({
  selector: "app-battle-royale-import-form",
  templateUrl: "./battle-royale-import-form.component.html",
  styleUrls: ["./battle-royale-import-form.component.scss"]
})
export class BattleRoyaleImportFormComponent implements EventCardImportForm {
  public battleRoyaleImportForm: FormGroup = new FormGroup({
    seriesMatchupId: new FormControl("", [Validators.required]),
    preloadedColor: new FormControl(BOOTSTRAP_HEXES[EventColors.WHITE]),
  });

  public errorMsg = DEFAULT_ERROR;
  public isLoading = false;
  public showForm = true;
  public hasError = false;

  public importInfo: BattleRoyaleImportInfo = {
    seriesMatchupId: "",
    leagueName: "",
  };

  constructor(
    private _eventImportService: EventImportService,
    private _modalService: NgbModal,
    private _eventCardService: EventCardStoreService,
    private _eventConfigService: EventConfigStoreService,
  ) {}

  public addMoreGames(): void {
    this.battleRoyaleImportForm.enable();
    this.battleRoyaleImportForm.setValue({
      seriesMatchupId: "",
      preloadedColor: EventColors.WHITE,
    });
    this.battleRoyaleImportForm.markAsPristine();
    this.isLoading = false;
    this.showForm = true;
  }

  public finishAddingGames(): void {
    this._modalService.dismissAll();
  }

  public saveEvents(battleRoyaleImportForm: FormGroup): void {
    if(battleRoyaleImportForm.invalid){
      this._setError("Invalid values given, please correct and submit again");
      this.battleRoyaleImportForm.enable();
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    battleRoyaleImportForm.disable();

    const { seriesMatchupId, preloadedColor: color } = battleRoyaleImportForm.value;

    const battleRoyaleFetchSuccess = (matchCard: GGLEventCardInfo) => {
      this.showForm = false;
      this._eventConfigService.setConfigFromEventCard(matchCard, {
        color
      });
      this._eventCardService.addEventCard(matchCard);
      this._setImportInfo(matchCard);
      return;
    };

    const fetchError = (error: any) => {
      console.error(error);
      this._setError("The series matchup you provided does not exist");
      battleRoyaleImportForm.enable();
      this.isLoading = false;
    };

    this._eventImportService.getBattleRoyale(seriesMatchupId).subscribe(battleRoyaleFetchSuccess, fetchError);
  }

  private _setImportInfo(card: GGLEventCardInfo) {
    this.importInfo = {
      leagueName: card.leagueName,
      seriesMatchupId: this.battleRoyaleImportForm.get("seriesMatchupId")?.value,
    };
  }

  private _setError(errMsg: string = DEFAULT_ERROR): void {
    this.hasError = true;
    this.errorMsg = errMsg;
  }
}
