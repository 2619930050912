<ng-container *ngIf="quickPlayEventCheckins$ | async as teams">
  <div class="row card">
    <header
      class="d-inline-flex flex-row justify-content-between mb-3 mt-3 align-items-center"
    >
      <h5 class="ml-2 mb-0">Teams in QuickPlayEvent</h5>
      <button
        ngbButton
        class="btn btn-primary btn-sm mr-2"
        (click)="collapse.toggle()"
      >
        {{ hasTeamListExpanded ? "Show" : "Hide" }}
      </button>
    </header>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="hasTeamListExpanded">
      <section
        class="c_quickPlayEventCheckinListContainer ml-0 mr-0 p-1"
        *ngIf="teams && teams.length > 0; else noTeams"
      >
        <!-- Teams Checked In -->
        <div
          class="c_quickPlayEventCheckinListColumn"
          *ngIf="checkedInTeams$ | async as checkedInTeams"
        >
          <p class="text-decoration-underline">
            Checked In Teams {{ checkedInTeams.length }} /
            {{ teams.length }}
          </p>
          <ul class="c_quickPlayEventCheckinList list-group">
            <ng-container
              *ngFor="let team of checkedInTeams; trackBy: checkinListTrackBy"
            >
              <ng-container
                [ngTemplateOutlet]="checkedInTeam"
                [ngTemplateOutletContext]="{ $implicit: team }"
              ></ng-container>
            </ng-container>
          </ul>
        </div>

        <!-- Teams Not Checked In -->
        <div
          class="c_quickPlayEventCheckinListColumn"
          *ngIf="notCheckedInTeams$ | async as notCheckedInTeams"
        >
          <p class="text-decoration-underline">
            Teams Not Checked In {{ notCheckedInTeams.length }} /
            {{ teams.length }}
          </p>
          <ul class="c_quickPlayEventCheckinList list-group">
            <ng-container
              *ngFor="
                let team of notCheckedInTeams;
                trackBy: checkinListTrackBy
              "
            >
              <ng-container
                [ngTemplateOutlet]="notCheckedInTeam"
                [ngTemplateOutletContext]="{ $implicit: team }"
              ></ng-container>
            </ng-container>
          </ul>
        </div>
      </section>
    </div>
  </div>
</ng-container>

<ng-template #checkedInTeam let-team>
  <li class="list-group-item list-group-item-success">
    <p class="mb-0">{{ team.teamTitle }}</p>
    <small>Checked In By: {{ team.username }}</small>
  </li>
</ng-template>

<ng-template #notCheckedInTeam let-team>
  <li class="list-group-item list-group-item-danger">
    <p class="mb-0">{{ team.teamTitle }}</p>
  </li>
</ng-template>

<ng-template #noTeams>
  <div class="alert alert-warning ml-2 mr-2">
    <p class="m-0">There are no teams registered for this tournament.</p>
  </div>
</ng-template>
