import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { QueueMatchEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-remove-queue-match-event",
  templateUrl: "./remove-queue-match-event.component.html",
  styleUrls: ["./remove-queue-match-event.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveQueueMatchEventComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: QueueMatchEventCardInfo;
}
