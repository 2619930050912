import {
  ChangeDetectionStrategy,
  Component,
  OnInit
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  combineLatest,
  Observable,
  of
} from "rxjs";
import { map, startWith } from "rxjs/operators";

import { MatchDetailLeague } from "@apptypes/games.types";
import { GGLEventCardInfo } from "@apptypes/ggl-event-card.types";
import { Nullish } from "@apptypes/nullish.types";
import { GGLEventConfigMap, GGLEventConfig } from "@services/cache/cache.service";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { getUniqueLeaguesList } from "@utils/match-array.utils";
import { GglEventFuzzySearchService } from "@services/ggl-event-fuzzy-search/ggl-event-fuzzy-search.service";

type EventCardsWithFormOptions = [GGLEventCardInfo[], { leagueFilterTerm: string | null; teamTitleOrIdFilter: string }];

@Component({
  selector: "app-ggl-event-card-manager",
  templateUrl: "./ggl-event-card-manager.component.html",
  styleUrls: ["./ggl-event-card-manager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GGLEventCardManagerComponent implements OnInit {
  public isInMassRemoval = false;
  public eventsToRemove: Set<GGLEventCardInfo> = new Set<GGLEventCardInfo>();

  public filterForm: FormGroup;

  public eventConfigMap$: Observable<GGLEventConfigMap>;
  public eventCards$: Observable<GGLEventCardInfo[]> = of([]);
  public filteredEventCards$: Observable<GGLEventCardInfo[]> = of([]);
  public leagueList$: Observable<MatchDetailLeague[]> = of([]);

  constructor(
    private _eventConfigService: EventConfigStoreService,
    private _eventCardService: EventCardStoreService,
    private _formBuilder: FormBuilder,
    private _gglEventFuzzySearchService: GglEventFuzzySearchService,
  ) {
    this.filterForm = this._formBuilder.group({
      leagueFilterTerm: [null],
      teamTitleOrIdFilter: [""],
    });

    this.eventConfigMap$ = this._eventConfigService.gglEventConfigMap$;
    this.eventCards$ = this._eventCardService.eventCards$;
  }

  public ngOnInit(): void {
    this.leagueList$ = this.eventCards$.pipe(
      map(getUniqueLeaguesList)
    );

    const filterOptionsForm$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
    );

    this.filteredEventCards$ = combineLatest([this.eventCards$, filterOptionsForm$]).pipe(
      map(([eventCards, { leagueFilterTerm, teamTitleOrIdFilter }]: EventCardsWithFormOptions) =>
        this._gglEventFuzzySearchService.fuzzyFindEventCards(eventCards, teamTitleOrIdFilter, leagueFilterTerm)
      ),
    );
  }

  public startMassRemoval(): void {
    this.isInMassRemoval = true;
  }

  public cancelRemoval(): void {
    this.eventsToRemove.clear();
    this.isInMassRemoval = false;
  }

  public removeMatch(eventCard: GGLEventCardInfo): void {
    //Unset an event for removal
    if (this.eventsToRemove.has(eventCard)) {
      this.eventsToRemove.delete(eventCard);
      return;
    }

    this.eventsToRemove.add(eventCard);
  }

  public submitRemoval(): void {
    if (this.eventsToRemove.size === 0) {
      this.isInMassRemoval = false;
      return;
    }

    this._eventCardService.removeEventCards(Array.from(this.eventsToRemove));
    this.eventsToRemove.clear();
    this.isInMassRemoval = false;
  }

  public getMatchConfig(eventCard: GGLEventCardInfo, eventConfigMap: Nullish<GGLEventConfigMap>): GGLEventConfig | false {
    if(!eventConfigMap){
      return false;
    }

    return eventConfigMap.get(EventConfigStoreService.getKeyFromEventCard(eventCard)) ?? false;
  }
}
