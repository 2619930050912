<form [formGroup]="queueForm" *ngIf="showForm; else showPrompt">
  <div class="form-group">
    <label for="queueId">Queue Session ID</label>
    <div class="input-group">
      <input id="queueId" type="text" formControlName="queueId" />
    </div>
    <div class="form-group">
      <label class="mt-2" for="colorSelect">Choose the Card Color: </label>
      <app-event-color-custom-select-with-defaults
        formControlName="preloadedColor"
      ></app-event-color-custom-select-with-defaults>
    </div>
    <div class="form-check mt-2">
      <input
        id="autoImportGames"
        type="checkbox"
        class="form-check-input"
        formControlName="autoImportMatches"
      />
      <label class="form-check-label" for="autoImportGames">
        Auto-Import Matches
      </label>
    </div>
    <div class="text-danger" *ngIf="hasError">
      {{ errorMsg }}
    </div>
  </div>
</form>

<ng-template #showPrompt>
  <p class="text-success">You have successfully added the queue</p>
  <dl>
    <dt>League Name</dt>
    <dd>{{ importInfo?.leagueName }}</dd>
    <dt>Tournament Name</dt>
    <dd>{{ importInfo?.tournamentTitle }}</dd>
  </dl>
  <div class="confirmButtons">
    <button type="button" (click)="addMoreGames()" class="btn btn-primary">
      Add More Games
    </button>
    <button type="button" (click)="finishAddingGames()" class="btn btn-warning">
      Finish Adding Games
    </button>
  </div>
</ng-template>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="saveEvents(queueForm)"
    [disabled]="isLoading"
  >
    <span *ngIf="!isLoading; else spinner"> Save Queue Session </span>
    <ng-template #spinner>
      <app-lds-spinner></app-lds-spinner>
    </ng-template>
  </button>
</div>
