import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
  SkipSelf
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ChatMessage } from "@apptypes/chat.types";
import { ModerationService } from "@services/chat/moderation/moderation.abstract.service";
import { MODERATION_SERVICE } from "@providers/moderation-service/moderation-service.token";

enum BanUserConfirmationModalStatus {
  DEFAULT = "default",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

@Component({
  selector: "app-ban-user-confirmation-modal",
  templateUrl: "./ban-user-confirmation-modal.component.html",
  styleUrls: ["./ban-user-confirmation-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanUserConfirmationModalComponent {
  @Input() public chatMessage!: ChatMessage;
  @Input() public resourceID!: string;

  public banUserStatus = BanUserConfirmationModalStatus.DEFAULT;
  public statusTypes = BanUserConfirmationModalStatus;

  constructor(
    private _ngbModalService: NgbModal,
    @Inject(MODERATION_SERVICE) @SkipSelf() @Optional() private _moderationService: ModerationService | null,
  ) {}

  public close(): void {
    this._ngbModalService.dismissAll();
  }

  public get showBanUserButton(): boolean {
    return !!this.resourceID && this.banUserStatus === BanUserConfirmationModalStatus.DEFAULT;
  }

  public banUser(): void {
    if(this._moderationService === null){
      alert("Could not ban the user");
      console.error("No moderation service provided");
      return;
    }
    this.banUserStatus = BanUserConfirmationModalStatus.LOADING;

    this._moderationService.banUser(this.resourceID, this.chatMessage.userId.toString()).subscribe(() => {
      this.banUserStatus = BanUserConfirmationModalStatus.SUCCESS;
    }, (err) => {
      console.error(err);
      this.banUserStatus = BanUserConfirmationModalStatus.ERROR;
    });
  }
}
