import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

import { QueueSessionEventCardInfo } from "@apptypes/ggl-event-card.types";

import { IGGLEventCardContentComponent } from "src/app/interfaces/components/ggl-event-card.component.interface";

@Component({
  selector: "app-queue-event-card-content",
  templateUrl: "./queue-event-card-content.component.html",
  styleUrls: ["./queue-event-card-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueEventCardContentComponent implements IGGLEventCardContentComponent {
  @Input() public eventCardData!: QueueSessionEventCardInfo;
}
