import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";

@Component({
  selector: "app-dual-ring-spinner",
  templateUrl: "./dual-ring-spinner.component.html",
  styleUrls: ["./dual-ring-spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DualRingSpinnerComponent  {
  @Input() public isOnDarkBackground = false;
}
