<app-new-messages-banner
  *ngIf="isBannerShowing"
  (click)="dismissBanner()"
></app-new-messages-banner>
<div
  *ngIf="messages.length > 0; else noChats"
  class="c_chatContainer"
  appChatScroll
>
  <div class="text-white text-center">Start of messages</div>
  <app-chat-message
    [message]="message"
    [chatroomData]="chatroomData"
    *ngFor="let message of messages"
  >
  </app-chat-message>
</div>
<ng-template #noChats>
  <div class="text-white">No chats have been recorded for this series yet</div>
</ng-template>
