import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { GGLEventDetails } from "@apptypes/card-details.types";
import { Subscription } from "rxjs";

import { GGLEventCardInfo, QueueSessionEventCardInfo } from "@apptypes/ggl-event-card.types";
import { Nullish } from "@apptypes/nullish.types";
import { QueueService } from "@services/queue/queue.service";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { GamesService } from "@services/games/games.service";

import { GGLEventCardTypes } from "src/app/enums/GGLEventCardTypes.enum";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public activeEvent: Nullish<GGLEventDetails> = null;
  public isLoadingActiveEvent = false;

  private _activeStoreSub: Subscription | null = null;

  constructor(
    private _gameService: GamesService,
    private _quickplayEventService: QuickPlayEventsService,
    private _queueService: QueueService,
    private _eventCardStoreService: EventCardStoreService,
  ) {}

  public ngOnInit(): void {
    this._activeStoreSub = this._eventCardStoreService.activeEventCard$.subscribe((eventCard) => {
      if(eventCard){
        this.setActiveEvent(eventCard);
        return;
      }

      this.activeEvent = null;
    });
  }

  public ngOnDestroy(): void {
    this._activeStoreSub?.unsubscribe();
  }

  public setActiveEvent(match: GGLEventCardInfo): void {
    this.activeEvent = null;
    this.isLoadingActiveEvent = true;

    switch (match.type) {
      case GGLEventCardTypes.BATTLE_ROYALE:
      case GGLEventCardTypes.ELIMINATION:
      case GGLEventCardTypes.ROUND_ROBIN:
      case GGLEventCardTypes.QUEUE_MATCH:
        return this._loadMatchFromEventCard(match);
      case GGLEventCardTypes.QUICKPLAY:
        return this._loadQuickPlayEventFromEventCard(match);
      case GGLEventCardTypes.QUEUE:
        return this._loadQueueFromEventCard(match);
      default:
        console.error("Could not parse match card type");
    }
  }

  private _loadMatchFromEventCard({ id, type }: GGLEventCardInfo): void {
    this._gameService.getMatchById(id).subscribe(
      (data) => {
        this.isLoadingActiveEvent = false;
        this.activeEvent = data;
      },
      (err) => {
        console.error(err);
        this.isLoadingActiveEvent = false;
        window.alert(`ERROR LOADING ${type} Event.  PLEASE TRY AGAIN`);
      }
    );
  }

  private _loadQuickPlayEventFromEventCard({ id, leagueName }: GGLEventCardInfo): void {
    this._quickplayEventService.getQuickPlayEvent(id).subscribe((data) => {
      if (data) {
        this.isLoadingActiveEvent = false;
        this.activeEvent = data;
        return;
      }
      //we already log the error in the service
      window.alert(`ERROR LOADING QUICKPLAY FOR LEAGUE: ${leagueName}. PLEASE TRY AGAIN`);
    }, (err) => {
      console.error(err);
      this.isLoadingActiveEvent = false;
      window.alert(`ERROR LOADING QUICKPLAY FOR LEAGUE: ${leagueName}. PLEASE TRY AGAIN`);
    });
  }

  private _loadQueueFromEventCard({ id, autoImportMatches }: QueueSessionEventCardInfo): void {
    this._queueService.getQueueDetail(id).subscribe((data) => {
      this.isLoadingActiveEvent = false;
      this.activeEvent = {
        ...data,
        autoImportMatches
      };
    }, (err) => {
      console.error(err);
      this.isLoadingActiveEvent = false;
      window.alert(`ERROR LOADING QUEUE: ${id}. PLEASE TRY AGAIN`);
    });
  }
}
