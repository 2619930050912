import { Provider } from "@angular/core";

import { SeriesMatchupModerationService } from "@services/chat/moderation";

import { MODERATION_SERVICE } from "./moderation-service.token";

export const SeriesMatchupModerationProvider: Provider = {
  provide: MODERATION_SERVICE,
  useClass: SeriesMatchupModerationService,
};
