<div class="row card">
  <header
    class="d-inline-flex flex-row justify-content-between mb-3 mt-3 align-items-center"
  >
    <h5 class="ml-2 mb-0">Teams in Tournament</h5>
    <button
      ngbButton
      class="btn btn-primary btn-sm mr-2"
      (click)="collapse.toggle()"
    >
      {{ hasTeamListExpanded ? "Show" : "Hide" }}
    </button>
  </header>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="hasTeamListExpanded">
    <section
      class="c_queueTeamListContainer ml-0 mr-0 p-1"
      *ngIf="teams && teams.length > 0; else noTeams"
    >
      <!-- Teams Checked In -->
      <div class="c_queueTeamListColumn">
        <p class="text-decoration-underline">
          Checked In Teams {{ checkedInTeams.length }} /
          {{ notCheckedInTeams.length + checkedInTeams.length }}
        </p>
        <ul class="c_queueTeamList list-group">
          <ng-container *ngFor="let team of checkedInTeams">
            <ng-container
              [ngTemplateOutlet]="checkedInTeam"
              [ngTemplateOutletContext]="{ $implicit: team }"
            ></ng-container>
          </ng-container>
        </ul>
      </div>

      <!-- Teams Not Checked In -->
      <div class="c_queueTeamListColumn">
        <p class="text-decoration-underline">
          Teams Not Checked In {{ notCheckedInTeams.length }} /
          {{ notCheckedInTeams.length + checkedInTeams.length }}
        </p>
        <ul class="c_queueTeamList list-group">
          <ng-container *ngFor="let team of notCheckedInTeams">
            <ng-container
              [ngTemplateOutlet]="notCheckedInTeam"
              [ngTemplateOutletContext]="{ $implicit: team }"
            ></ng-container>
          </ng-container>
        </ul>
      </div>
    </section>
  </div>
</div>

<ng-template #checkedInTeam let-team>
  <li class="list-group-item list-group-item-success">
    <p class="mb-0">{{ team.title }}</p>
    <small *ngIf="team.checkedIn && team.checkedInAt"
      >Checked In At: {{ team.checkedInAt | date: "short" }}</small
    >
  </li>
</ng-template>

<ng-template #notCheckedInTeam let-team>
  <li class="list-group-item list-group-item-danger">
    <p class="mb-0">{{ team.title }}</p>
  </li>
</ng-template>

<ng-template #noTeams>
  <div class="alert alert-warning ml-2 mr-2">
    <p class="m-0">There are no teams registered for this tournament.</p>
  </div>
</ng-template>
