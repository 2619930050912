import { Component, Input } from "@angular/core";

import { ChatMessage } from "@apptypes/chat.types";

@Component({
  selector: "app-chat-message-actions-dropdown",
  templateUrl: "./chat-message-actions-dropdown.component.html",
  styleUrls: ["./chat-message-actions-dropdown.component.scss"]
})
export class ChatMessageActionsDropdownComponent {
  @Input() public chatMessage!: ChatMessage;
  @Input() public resourceID!: string;

  public isOpen = false;
}
