import {
  compareAsc,
  compareDesc,
  getUnixTime,
  parseISO
} from "date-fns";

import { ISOString } from "@apptypes/aliases.types";

export const isoStringToUnixTime = (date: ISOString): number => {
  try {
    return getUnixTime(parseISO(date));
  }
  catch(e){
    console.error(e);
    return 0;
  }
};

export const compareISODateAsc = (isoDateA: ISOString, isoDateB: ISOString): -1 | 0 | 1 => {
  try {
    const dateA = parseISO(isoDateA);
    const dateB = parseISO(isoDateB);

    return compareAsc(dateA, dateB) as -1 | 0 | 1 ;
  }
  catch(e) {
    console.error(e);
    return 0;
  }
};

export const compareISODateDesc = (isoDateA: ISOString, isoDateB: ISOString): -1 | 0 | 1 => {
  try {
    const dateA = parseISO(isoDateA);
    const dateB = parseISO(isoDateB);

    return compareDesc(dateA, dateB) as -1 | 0 | 1 ;
  }
  catch(e) {
    console.error(e);
    return 0;
  }
};

export const isoNow = (): ISOString => new Date().toISOString() as ISOString;
