import { Component, Input } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { EventImportService } from "@services/event-import/event-import.service";
import { EventCardStoreService } from "@services/stores/event-card-store/event-card-store.service";
import { EliminationEventCardInfo, RoundRobinEventCardInfo } from "@apptypes/ggl-event-card.types";
import { EventConfigStoreService } from "@services/stores/event-config-store/event-config-store.service";

import { EventCardImportForm } from "src/app/interfaces/components/ggl-event-card-import-forms.component.interface";
import { DEFAULT_ERROR } from "../../event-import-modal/event-import-modal.component";
import { EventColors } from "src/app/enums/EventColors.enum";

interface TournamentRoundImportInfo {
  numberOfGames: number;
  leagueName: string;
  round: string;
}

@Component({
  selector: "app-tournament-round-import-form",
  templateUrl: "./tournament-round-import-form.component.html",
  styleUrls: ["./tournament-round-import-form.component.scss"]
})
export class TournamentRoundImportFormComponent implements EventCardImportForm {
  @Input() public isRoundRobin = false;

  public gamesListForm = new FormGroup({
    tournamentId: new FormControl("", [Validators.required, Validators.min(0)]),
    tournamentRound: new FormControl("", [Validators.required, Validators.min(0)]),
    preloadedColor: new FormControl(EventColors.WHITE),
  });

  public errorMsg = DEFAULT_ERROR;

  public showForm = true;
  public isLoading = false;
  public hasError = false;

  public importInfo: TournamentRoundImportInfo = {
    numberOfGames: 0,
    leagueName: "",
    round: "",
  };

  constructor(
    private _eventImportService: EventImportService,
    private _modalService: NgbModal,
    private _eventCardService: EventCardStoreService,
    private _eventConfigService: EventConfigStoreService,
  ) { }

  public addMoreGames(): void {
    this.gamesListForm.enable();
    this.gamesListForm.setValue({
      tournamentId: "",
      tournamentRound: "",
      preloadedColor: EventColors.WHITE
    });
    this.gamesListForm.markAsPristine();
    this.isLoading = false;
    this.showForm = true;
  }

  public finishAddingGames(): void {
    this._modalService.dismissAll();
  }

  public saveEvents(gamesListForm: FormGroup): void {
    if (gamesListForm.invalid) {
      this._setError("Invalid values given, please correct and submit again");
      return;
    }

    this.isLoading = true;
    this.gamesListForm.disable();

    const { tournamentId, tournamentRound } = gamesListForm.value;
    const color = gamesListForm.value?.preloadedColor ?? EventColors.WHITE;

    const tournamentRoundSuccessFn = (matchCards: RoundRobinEventCardInfo[] | EliminationEventCardInfo[]): void => {
      this.showForm = false;
      this._eventConfigService.setConfigForMultipleEventCards(matchCards, {
        color
      });
      this._eventCardService.addEventCards(matchCards);

      if(matchCards?.length && matchCards.length > 0){
        this.importInfo = {
          numberOfGames: matchCards.length,
          leagueName: matchCards[0].leagueName,
          round: tournamentRound,
        };

        return;
      }

      this.importInfo = {
        numberOfGames: 0,
        leagueName: "",
        round: tournamentRound,
      };
    };

    const tournamentRoundErrorFn = (err: any): void => {
      console.error(err);
      if (err.status === 404) {
        this._setError("The tournament or round you provided does not exist");
      } else {
        this._setError("There was an error fetching games, please try again or reach out to an admin");
      }
      gamesListForm.enable();
      this.isLoading = false;
    };

    if(this.isRoundRobin){
      this._eventImportService.getRoundRobinRound(
        tournamentId, tournamentRound.toString()
      ).subscribe(tournamentRoundSuccessFn, tournamentRoundErrorFn);
    }
    else {
      this._eventImportService.getEliminationRound(
        tournamentId, tournamentRound.toString()
      ).subscribe(tournamentRoundSuccessFn, tournamentRoundErrorFn);
    }
  }

  private _setError(errMsg: string = DEFAULT_ERROR): void {
    this.hasError = true;
    this.errorMsg = errMsg;
  }
}
