import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ChatroomBlockData } from "@apptypes/chat.types";
import { basicAuthHeader } from "@utils/auth-header.util";
import { environment } from "@environments/environment";

import { CommentService } from "../comment.abstract.service";
import { CloudComment } from "../comment.api.types";

/**
 * A Comment service to post to Cloud Tournament Chatrooms
 *
 * @author Christian Tweed
 */
@Injectable({
  providedIn: "root"
})
export class CloudTournamentCommentService extends CommentService {

  public postComment(chatroomBlockData: ChatroomBlockData, authorId: string, message: string): Observable<any> {
    const url = `${environment.cloudFunctionAPIBase}/manageTournamentChat`;
    const options = {
      headers: basicAuthHeader(),
    };

    const body = this._formatChatMessage(chatroomBlockData.chatroomReferenceId, authorId, message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(chatroomId: string, userId: string, message: string): CloudComment {
    return {
      chatroomId,
      userId,
      message
    };
  }
}
