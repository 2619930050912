import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from "@angular/core";
import {
  combineLatest,
  Observable,
  of
} from "rxjs";
import {
  first,
  map,
  shareReplay,
  switchMap,
} from "rxjs/operators";

import { QuickPlayEvent, QuickPlayEventStreamCheckin } from "@apptypes/quickplay.types";
import { LeaguesService } from "@services/leagues/leagues.service";
import { QuickPlayEventsService } from "@services/quick-play-events/quick-play-events.service";
import { TeamTypes } from "@apiEnums/teams/team-types.enum";
import { UsernameService } from "@services/chat/username/username.service";
import { MatchCommenter } from "@apptypes/chat.types";

interface QuickPlayEventCheckedInTeam {
  teamID: string;
  userID: string | null;
  username: string | null;
  teamTitle: string;
  teamType: TeamTypes;
  checkedIn: boolean;
}

@Component({
  selector: "app-quick-play-event-checkin-list",
  templateUrl: "./quick-play-event-checkin-list.component.html",
  styleUrls: ["./quick-play-event-checkin-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickPlayEventCheckinListComponent implements OnInit {
  @Input() public quickPlayEvent: QuickPlayEvent | null = null;

  public quickPlayEventCheckins$: Observable<QuickPlayEventCheckedInTeam[]> = of([]);
  public checkedInTeams$: Observable<QuickPlayEventCheckedInTeam[]> = of([]);
  public notCheckedInTeams$: Observable<QuickPlayEventCheckedInTeam[]> = of([]);

  public hasTeamListExpanded = true;

  constructor(
    private _quickPlayEventService: QuickPlayEventsService,
    private _leaguesService: LeaguesService,
    private _usernameService: UsernameService
  ) { }

  public ngOnInit(): void {
    if(this.quickPlayEvent === null){
      return;
    }

    const leagueTeams$ = this._leaguesService.getLeagueTeams(this.quickPlayEvent.league.id).pipe(first());
    const usernames$ = leagueTeams$.pipe(
      switchMap((teams) => {
        const userIDs: string[] = [];
        teams.forEach((team) => userIDs.push(...team.userIds));
        return this._usernameService.getAllMatchCommenters(userIDs);
      })
    );

    const checkins$: Observable<QuickPlayEventStreamCheckin[]> = this._quickPlayEventService.getQuickPlayEventCheckins(
      this.quickPlayEvent.streamReferenceID,
    );

    this.quickPlayEventCheckins$ = combineLatest([
      leagueTeams$,
      checkins$,
      usernames$
    ]).pipe(
      map(([
        leagueTeams,
        checkins,
        users
      ]) => leagueTeams.map((leagueTeam) => {
        const checkedInTeam: QuickPlayEventStreamCheckin | undefined = checkins.find((checkin) => checkin.teamID === leagueTeam.id);
        if(checkedInTeam === undefined){
          return {
            userID: null,
            username: null,
            teamID: leagueTeam.id,
            teamTitle: leagueTeam.title,
            teamType: leagueTeam.teamType,
            checkedIn: false
          };
        }

        const foundUser: MatchCommenter | undefined = users.find((user) => `${user.id}` === checkedInTeam.userID);

        return {
          userID: checkedInTeam.userID,
          username: foundUser !== undefined ? foundUser.inGameName : "NOTFOUND",
          teamID: leagueTeam.id,
          teamTitle: leagueTeam.title,
          teamType: leagueTeam.teamType,
          checkedIn: true
        };
      })),
      shareReplay(1)
    );

    this.checkedInTeams$ = this.quickPlayEventCheckins$.pipe(
      map((teams) => teams.filter(team => team.checkedIn))
    );

    this.notCheckedInTeams$ = this.quickPlayEventCheckins$.pipe(
      map((teams) => teams.filter(team => !team.checkedIn))
    );
  }

  public checkinListTrackBy(_index: number, team: QuickPlayEventCheckedInTeam): string {
    return team.teamID;
  }
}
