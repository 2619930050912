<div
  class="c_queueDetailContainer"
  *ngIf="queueSession$ | async as queueSession"
>
  <header>
    <h3>
      <strong> League: </strong>
      <a [href]="leagueRoute" target="_blank">{{
        queue.league.title | titlecase
      }}</a>
    </h3>
    <h4>
      Time Until Close:
      <span [ngClass]="{ 'text-danger': hasTimeExpired }">
        <app-timer
          [countDownTime]="closeTime"
          (countDownEvent)="closeTimeHandler()"
        ></app-timer>
      </span>
    </h4>
    <h4>Expected Close Time: {{ closeTime | date: "medium" }}</h4>
    <h5 *ngIf="queueSession.status as queueStatus">
      Status: {{ queueStatus | enumTitle }}
    </h5>
  </header>
  <div
    class="c_queueDetailButtons"
    [ngClass]="{
      'c_queueDetailButtons--closed': queueStatus === queueStatuses.COMPLETE
    }"
    *ngIf="queueSession.status as queueStatus"
  >
    <button
      *ngIf="queueStatus !== queueStatuses.COMPLETE"
      class="btn btn-danger c_queueDetailButtons--closeButton"
      (click)="openCloseQueueConfirmationModal()"
    >
      Close Queue
    </button>
    <div
      ngbDropdown
      autoClose="outside"
      class="d-inline-block c_queueDetailButtons--dropdown"
    >
      <button
        class="btn btn-success w-100"
        id="openMatchImportOptions"
        ngbDropdownToggle
      >
        Match Import Options
      </button>
      <div ngbDropdownMenu aria-labelledby="openMatchImportOptions">
        <div class="form-check" ngbDropdownItem>
          <input
            class="form-check-input"
            type="checkbox"
            [formControl]="isAutoImportingMatches"
            id="autoImportMatches"
          />
          <label class="form-check-label" for="autoImportMatches">
            Auto Import Matches
          </label>
        </div>
        <button
          class="btn btn-info"
          ngbDropdownItem
          (click)="loadMatchesFromQueue(queueSession, queue)"
        >
          Load Matches
        </button>
      </div>
    </div>
    <div
      class="c_queueDetailButtons__button c_queueDetailButtons__colorSelector"
    >
      <app-event-color-selector [event]="queue"></app-event-color-selector>
    </div>
    <a
      class="btn btn-warning c_queueDetailButtons__button"
      [href]="queueAppRoute"
      target="_blank"
      role="button"
    >
      Go to Queue (App)
    </a>
    <a
      class="btn btn-warning c_queueDetailButtons__button"
      [href]="queueAdminRoute"
      target="_blank"
      role="button"
    >
      Go to Queue (Admin)
    </a>
  </div>
  <div class="c_scrollContainer">
    <app-queue-team-list
      *ngIf="queueTeams$ | async as queueTeams"
      [teams]="queueTeams"
    ></app-queue-team-list>
  </div>
  <div class="c_scrollContainer">
    <app-chat-block
      *ngIf="queue | queueDetailChatroomAdapter as chatroomData"
      [chatroomData]="chatroomData"
    >
    </app-chat-block>
  </div>
</div>
